/** @jsx jsx */
/** @jsxFrag */
import { jsx } from '@emotion/react';

import { forwardRef } from 'react';
import BaseCardView from './BaseCardView';

export type EmptyCard = {
  id: `empty${string}`;
};
export const EmptyCardView = forwardRef<HTMLDivElement, { card?: EmptyCard }>(
  (props, ref) => {
    return (
      <BaseCardView
        ref={ref}
        cardStyle={{
          border: 'none',
          boxShadow: 'none',
        }}
        bodyStyle={{
          boxShadow: 'none',
        }}
        titleView={null}
      ></BaseCardView>
    );
  },
);

export const EmptyTributeView = forwardRef<
  HTMLDivElement,
  { card?: EmptyCard }
>((props, ref) => {
  return (
    <BaseCardView
      ref={ref}
      landscape={true}
      cardStyle={{
        border: 'none',
        boxShadow: 'none',
      }}
      bodyStyle={{
        boxShadow: 'none',
      }}
      titleView={null}
    ></BaseCardView>
  );
});
