/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';
import { InflatedGame } from '@mythos/game/Game';
import { AGES_PER_GAME } from '@mythos/game/GameConstants';
import { PhaseToBackgroundColor, getPhaseName } from '@mythos/game/Phases';
import { ageString } from '@mythos/game/Utility';
import React from 'react';
import GameTimeView from '../GameTimeView';
import { PhaseToSymbol } from '../Symbols';
import { MColors } from './MColors';
import { MSymbolPrefix } from './MUIComponents';

export default function MGameStateView(props: { game: InflatedGame }) {
  const { game } = props;

  const backgroundColor = PhaseToBackgroundColor[game.phase];

  if (game.gameEndTimestamp) {
    return (
      <div
        css={MGameStateViewStyles.container}
        style={{
          backgroundColor,
        }}
      >
        <div>Game Over!</div>
        <GameTimeView
          extraSplitTime={game.gameEndTimestamp - game.gameStartTimestamp!}
        />
      </div>
    );
  }
  return (
    <div
      css={MGameStateViewStyles.container}
      style={{
        backgroundColor,
      }}
    >
      <MSymbolPrefix
        symbol={PhaseToSymbol[game.phase]}
        symbolCss={MGameStateViewStyles.symbol}
      >
        {getPhaseName(game.phase)}
      </MSymbolPrefix>
      <div>
        Turn {game.turn}/{game.turnsPerAge}
      </div>
      <div>
        Age {ageString(game.age)}/{ageString(AGES_PER_GAME)}
      </div>
      <GameTimeView
        splitStartTimestamp={
          game.gameEndTimestamp ? undefined : game.gameStartTimestamp!
        }
        extraSplitTime={
          game.gameEndTimestamp
            ? game.gameEndTimestamp - game.gameStartTimestamp!
            : undefined
        }
      />
    </div>
  );
}

const MGameStateViewStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: 5,

    color: MColors.whiteText,
  }),
  symbol: css({
    height: 20,
  }),
};
