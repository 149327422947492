/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import Phases, { Phase } from '@mythos/game/Phases';
import { computeBaseCounters } from '@mythos/game/Rules';
import React, { useCallback, useEffect } from 'react';
import Symbols, { SYMBOL_TO_IMAGE, SymbolView } from 'Symbols';
import { match } from 'ts-pattern';
import ActionStore from '../ActionStore';
import { MSymbolPrefix } from './MUIComponents';

export default function MSelfPlayerView(props: {
  actionStore: ActionStore;
  ready: boolean;
  phase: Phase;
}) {
  const { phase, actionStore, ready } = props;
  const onReadyClick = useCallback(() => {
    actionStore.didClickSubmit();
  }, [actionStore]);
  const forceUpdate = React.useReducer((x) => x + 1, 0)[1];
  const actionStoreDidChange = useCallback(() => {
    forceUpdate();
  }, [forceUpdate]);

  useEffect(() => {
    actionStore.addListener(actionStoreDidChange);
    return () => {
      actionStore.removeListener(actionStoreDidChange);
    };
  }, [actionStore, actionStoreDidChange]);

  const canSubmit = actionStore.canSubmit();
  const phaseView = match(phase)
    .when(
      () => ready,
      () => (
        <div css={MSelfPlayerStyles.waitingMessage}>
          Waiting for other players...
        </div>
      ),
    )
    .with(Phases.PLANNING, () => {
      const bid = actionStore.getMilitaryBidOrNan();
      const bidLimits = actionStore.getMilitaryBidLimits();
      const counters = actionStore.getPlayer().counters;
      const baseCounters = computeBaseCounters(actionStore.getContext());
      const selectedCard = actionStore.getSelectedCard();
      const cardCost = selectedCard
        ? Math.max(0, selectedCard.cost - baseCounters.gold)
        : null;

      return (
        <>
          <div css={MSelfPlayerStyles.bidContainer}>
            <div css={MSelfPlayerStyles.goldInfoContainer}>
              <MSymbolPrefix
                symbol={Symbols.GOLD}
                symbolCss={MSelfPlayerStyles.goldInfoSymbol}
              >
                {counters.gold}
              </MSymbolPrefix>
              <MSymbolPrefix
                symbol={Symbols.BASE_GOLD}
                symbolCss={MSelfPlayerStyles.goldInfoSymbol}
              >
                {baseCounters.gold}
              </MSymbolPrefix>
            </div>
            <div css={MSelfPlayerStyles.bidInputLimitContainer}>
              <SymbolView
                symbol={Symbols.BASE_MILITARY}
                css={MSelfPlayerStyles.bidInputLimitSymbol}
              />
              {bidLimits.min}
            </div>

            <input
              css={MSelfPlayerStyles.bidInput}
              name="bid"
              type="range"
              min={bidLimits.min}
              max={bidLimits.max}
              value={isNaN(bid) ? '' : bid}
              onChange={(e) => {
                if (e.target.value === '') {
                  actionStore.didChangeMilitary(NaN);
                } else {
                  actionStore.didChangeMilitary(parseInt(e.target.value));
                }
              }}
            />

            <div css={MSelfPlayerStyles.bidInputLimitContainer}>
              {bidLimits.max}
              <span>
                <SymbolView
                  symbol={Symbols.MILITARY}
                  css={MSelfPlayerStyles.bidInputLimitSymbol}
                />
                <SymbolView
                  symbol={Symbols.BASE_MILITARY}
                  css={MSelfPlayerStyles.bidInputLimitSymbolBase}
                />
              </span>
            </div>
          </div>
          <button
            css={MSelfPlayerStyles.submitButton}
            onClick={onReadyClick}
            disabled={!canSubmit.result}
          >
            <div css={MSelfPlayerStyles.buttonGrid}>
              <div css={MSelfPlayerStyles.bidCostContainer}>
                Cost:{' '}
                {cardCost !== null ? (
                  <MSymbolPrefix
                    symbol={Symbols.GOLD}
                    symbolCss={MSelfPlayerStyles.bidCostSymbol}
                  >
                    {cardCost}
                  </MSymbolPrefix>
                ) : null}
              </div>
              <div css={MSelfPlayerStyles.bidValueContainer}>
                Bid
                <MSymbolPrefix
                  css={MSelfPlayerStyles.bidValue}
                  symbol={Symbols.MILITARY}
                  symbolCss={MSelfPlayerStyles.bidValueSymbol}
                >
                  {bid}
                </MSymbolPrefix>
              </div>
              <div></div> {/* Empty div for the third column */}
            </div>
          </button>
        </>
      );
    })
    .with(Phases.RESOLUTION, () => {
      return (
        <button
          css={MSelfPlayerStyles.submitButton}
          onClick={onReadyClick}
          disabled={!canSubmit.result}
        >
          Submit
        </button>
      );
    })
    .otherwise(() => null);

  if (!phaseView) {
    return null;
  }

  return <div css={MSelfPlayerStyles.container}>{phaseView}</div>;
}

const MSelfPlayerStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    backgroundColor: 'rgb(210, 210, 210)',
    borderRadius: 4,
    padding: 4,
    gap: 4,
  }),
  bidContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'stretch',
    height: 50,
    textAlign: 'center',
  }),
  bidValue: css({
    fontSize: 26,
    marginLeft: 8,
  }),
  bidValueSymbol: css({
    height: 26,
    width: 26,
    marginLeft: 2,
    marginRight: 2,
  }),
  bidCostSymbol: css({
    height: 20,
    width: 20,
    marginLeft: 2,
    marginRight: 2,
  }),

  goldInfoContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 2,

    minWidth: 48,

    backgroundColor: 'rgb(150, 150, 150)',
    borderRadius: 4,
    padding: 2,
    paddingLeft: 4,
    paddingRight: 4,

    fontSize: 20,
    color: 'rgb(50, 50, 50)',
  }),
  goldInfoSymbol: css({
    height: 20,
    width: 20,
  }),

  bidInput: css({
    flexGrow: 1,
    WebkitAppearance: 'none',
    appearance: 'none',

    '&::-webkit-slider-thumb': {
      WebkitAppearance: 'none',
      appearance: 'none',
      background: `url(${SYMBOL_TO_IMAGE[Symbols.MILITARY]})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundColor: 'none',
      height: 30,
      width: 30,
    },
  }),
  bidInputLimitContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,

    marginLeft: 4,
    marginRight: 4,
    fontSize: 20,
  }),
  bidInputLimitSymbol: css({
    height: 20,
    width: 20,
  }),
  bidInputLimitSymbolBase: css({
    height: 14,
    width: 14,
    marginLeft: -9,
  }),

  submitButton: css({
    height: 50,
    padding: 2,
    width: '100%',
    fontSize: 28,
    textAlign: 'center',
    outline: 'none',
    color: 'black',
    borderRadius: 24,
    border: 'none',

    '&:disabled': {
      color: 'rgb(140, 140, 140)',
    },
  }),
  buttonGrid: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    width: '100%',
    alignItems: 'center',
  }),
  bidCostContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gridColumn: '1',
    fontSize: 18,
  }),
  bidValueContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gridColumn: '2',
  }),

  waitingMessage: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 24,
    height: 50,
  }),
};
