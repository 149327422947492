/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import React, { useEffect } from 'react';

import Roll1 from '../assets/dice1.svg';
import Roll2 from '../assets/dice2.svg';
import Roll3 from '../assets/dice3.svg';
import Roll4 from '../assets/dice4.svg';
import Roll5 from '../assets/dice5.svg';
import Roll6 from '../assets/dice6.svg';
import { randomInt } from '../common/utils/utils';

const RollToImage = {
  1: Roll1,
  2: Roll2,
  3: Roll3,
  4: Roll4,
  5: Roll5,
  6: Roll6,
};

const ROLL_DELAYS = [
  25, 25, 25, 25, 25, 25, 25, 25, 50, 50, 50, 50, 75, 75, 100, 100, 150, 200,
  200, 250,
];

export default function DieView(props: {
  roll: number;
  animate?: boolean;
  onAnimateEnd?: () => void;
}) {
  const { animate } = props;
  const [roll, setRoll] = React.useState(
    animate ? randomInt(1, 6) : props.roll,
  );
  const [rolling, setRolling] = React.useState(animate);

  useEffect(() => {
    if (!animate) {
      return;
    }

    setRolling(true);

    let rollIndex = 0;
    const doRoll = () => {
      rollIndex += 1;
      if (rollIndex >= ROLL_DELAYS.length) {
        setRoll(props.roll);
        setRolling(false);
        props.onAnimateEnd?.();
        return;
      }
      setRoll(randomInt(1, 6));
      timeout = setTimeout(doRoll, ROLL_DELAYS[rollIndex]);
    };
    let timeout = setTimeout(doRoll, ROLL_DELAYS[rollIndex]);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        setRoll(props.roll);
        setRolling(false);
      }
    };
  }, [animate, props.roll]);

  var displayDieSrc =
    roll >= 1 && roll <= 6 ? RollToImage[roll as keyof typeof RollToImage] : '';
  return (
    <img
      css={DieStyles.die}
      src={displayDieSrc}
      style={
        rolling
          ? {
              filter: 'blur(1px)',
            }
          : undefined
      }
      height="12px"
      width="12px"
    />
  );
}

const DieStyles = {
  die: css({
    opacity: 0.6,
    verticalAlign: 'middle',

    '& + &': {
      marginLeft: 2,
    },
  }),
};
