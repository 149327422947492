/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import { upgradeConditionMet } from '@mythos/game/CardAbilities';
import { CardSubType, CardType } from '@mythos/game/CardTypes';
import { InflatedGame, InflatedPlayer } from '@mythos/game/Game';
import { CardDef } from '@mythos/game/GameModel';
import { Resource } from '@mythos/game/Resources';
import * as Rules from '@mythos/game/Rules';
import { makeContextFromGame } from '@mythos/game/Rules';
import { addToMapOfArrays } from '@mythos/utils/utils';
import nullthrows from 'nullthrows';
import React, { ReactElement, useCallback } from 'react';
import {
  BasicCardRenderer,
  CardRenderer,
  computeCardEffectRows,
} from './CardView';
import { EmptyCardView } from './EmptyCardView';
import HoverCardStore from './HoverCardStore';
import { MEDIA_QUERY_MOBILE } from './mobile_ui/MStyles';
import { BaseResourceToSymbol, ResourceToSymbol, SymbolView } from './Symbols';
import { UserWithColor } from './User';

const CARD_TYPE_TO_STACK_ORDER: Record<CardType, number> = {
  [CardType.Leader]: 0,
  [CardType.Basic]: 1,
  [CardType.None]: 2,
  [CardType.Resource]: 3,
  [CardType.Conflict]: 4,
  [CardType.Prayer]: 5,
};

export type BoardViewModel = {
  stacks: Map<string, Rules.CardWithID[]>;
};
export function computeBoardViewModel(
  player: InflatedPlayer,
  game: InflatedGame,
): BoardViewModel {
  const stacks = new Map<string, Rules.CardWithID[]>();
  player.cards.forEach((card) => {
    addToMapOfArrays(stacks, card.subType, card);
  });

  stacks.forEach((stack) => {
    stack.sort(
      (a, b) =>
        CARD_TYPE_TO_STACK_ORDER[a.type] - CARD_TYPE_TO_STACK_ORDER[b.type],
    );
  });

  return {
    stacks,
  };
}

type PlayerBoardViewProps = {
  player: InflatedPlayer;
  game: InflatedGame;
  user: UserWithColor;
  className?: string;
  style?: React.CSSProperties;
};
const PlayerBoardView = React.forwardRef<HTMLDivElement, PlayerBoardViewProps>(
  (props, ref) => {
    let { player, game, user } = props;

    const stacks = computeBoardViewModel(player, game).stacks;

    return (
      <div
        css={PlayerBoardStyles.container}
        className={props.className}
        ref={ref}
      >
        <div css={PlayerBoardStyles.header}>
          <div
            css={PlayerBoardStyles.name}
            style={{
              color: user.color,
            }}
          >
            {user.name}
          </div>
        </div>
        <StacksView
          className={props.className}
          stacks={[
            stacks.get(CardSubType.Unit) || [],
            stacks.get(CardSubType.Building) || [],
            stacks.get(CardSubType.Event) || [],
          ]}
          player={player}
          game={game}
          renderer={BasicCardRenderer}
          hoverRenderer={BasicCardRenderer}
        />
      </div>
    );
  },
);
export default PlayerBoardView;

export function PlayerCounter(props: {
  name: Resource;
  count: number;
  baseCount?: number;
}) {
  const { name, count, baseCount } = props;
  if (name === 'favor') {
    return null;
  }
  return (
    <div key={name} css={PlayerCounterStyles.counterContainer}>
      <SymbolView
        symbol={ResourceToSymbol[name as Resource]}
        css={PlayerCounterStyles.resourceSymbol}
      />
      {count}
      {baseCount !== undefined && (
        <>
          <SymbolView
            symbol={nullthrows(BaseResourceToSymbol[name])}
            css={PlayerCounterStyles.baseSymbol}
          />
          {baseCount}
        </>
      )}
    </div>
  );
}

const PlayerCounterStyles = {
  counterContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    fontSize: 16,
  }),
  resourceSymbol: css({
    height: 20,
    width: 20,
    marginRight: 4,
  }),
  baseSymbol: css({
    height: 15,
    width: 15,
    marginLeft: 4,
    marginRight: 2,
  }),
};

function StacksView(props: {
  stacks: Rules.CardWithID[][];
  renderer: CardRenderer;
  hoverRenderer: CardRenderer;
  player: InflatedPlayer;
  game: InflatedGame;
  className?: string;
  style?: React.CSSProperties;
}): ReactElement {
  const onCardEnter = useCallback((card: CardDef) => {
    HoverCardStore.setCard(card, props.hoverRenderer);
    HoverCardStore.setPosition('bottom-right');
  }, []);
  const onCardLeave = useCallback(() => {
    HoverCardStore.setCard(null, null);
  }, []);
  const context = makeContextFromGame(props.player, props.game);

  return (
    <div
      css={PlayerBoardStyles.stacksContainer}
      className={props.className}
      style={{
        ...props.style,
      }}
    >
      {props.stacks.map((stack, i) => {
        return (
          <div key={i} css={PlayerBoardStyles.stackContainer}>
            {stack.length === 0 && <EmptyCardView />}
            {stack.map((c, j) => {
              let marginTop = 0;
              if (j !== stack.length - 1) {
                marginTop = -113;
                const height = getHeight(c, context);
                marginTop += (height - 1) * 18;
              }

              return (
                <div
                  key={c.id}
                  css={PlayerBoardStyles.cardContainer}
                  style={{ marginTop: marginTop }}
                >
                  {props.renderer({
                    card: c,
                    onCardEnter,
                    onCardLeave,
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

function getHeight(card: CardDef, context: Rules.Context): number {
  const { nonupgradedEffectRows, effectRowsByUpgrade, hasEmptyRow } =
    computeCardEffectRows(card);

  let height = nonupgradedEffectRows.size;
  if (hasEmptyRow) {
    height += 1;
  }

  for (const [condition, effects] of effectRowsByUpgrade) {
    if (upgradeConditionMet(condition, context)) {
      height += effects.size;
    }
  }

  return height;
}

const PlayerBoardStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  }),

  stacksContainer: css({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'rgb(85, 85, 85)',
    flexGrow: 1,
    [MEDIA_QUERY_MOBILE]: {
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
  }),
  stackContainer: css({
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-end',
    padding: 4,
  }),
  cardContainer: css({
    // '&:hover': {
    //   zIndex: 2,
    //   transform: 'scale(1.1)',
    // },
  }),

  header: css({
    label: 'player-board-header',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    height: 20,
    padding: 2,
    paddingLeft: 8,

    backgroundColor: 'rgb(35, 35, 35)',
    color: 'rgb(230, 230, 230)',

    [MEDIA_QUERY_MOBILE]: {
      display: 'none',
    },
  }),
  name: css({}),
};
