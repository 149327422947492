/** @jsx jsx */
/** @jsxFrag */
import { css, jsx, SerializedStyles } from '@emotion/react';
import {
  AbilityOutput,
  AbilityOutputMultiplierType,
  GainTargetType,
} from '@mythos/game/CardAbilities';
import {
  CardType,
  getCardSubTypeChar,
  getCardTypeChar,
  isCardSubType,
} from '@mythos/game/CardTypes';
import { Resource, Token } from '@mythos/game/Resources';
import invariant from 'invariant';
import React from 'react';
import { match } from 'ts-pattern';
import { CardTypeToFrameColor } from './CardTypeToFrameColor';
import Symbols, { ResourceToSymbol, SymbolView } from './Symbols';

export function EffectPill(props: {
  output: AbilityOutput;
  className?: string;
  style?: React.CSSProperties;
  hideChrome?: boolean;
}): React.ReactNode | null {
  const output = props.output;

  let prefix: React.ReactNode;
  let extraChromeCSS: SerializedStyles | null = null;
  if (output.multiplier) {
    extraChromeCSS =
      PillChromeByMultiplierType[output.multiplier.resource] ||
      DefaultPillChrome;

    let midfix: React.ReactNode;
    const count = output.multiplier.resourceDivisor;
    const multiplierSymbolChar = match(output.multiplier.resource)
      .with('card_set', () => {
        invariant(count === 1, 'no asset for N card sets');
        return 'S';
      })
      .with(Token.War, () => {
        if (count !== 1) {
          midfix = <span css={PillStyles.resourceDivisor}>/{count}</span>;
        }
        return 'W';
      })
      .with(Token.Age1, () => 'A1')
      .with(Token.Age2, () => 'A2')
      .with(Token.Age3, () => 'A3')
      .otherwise((cardType) => {
        let symbolChar = isCardSubType(cardType)
          ? getCardSubTypeChar(cardType)
          : getCardTypeChar(cardType);

        if (count > 1) {
          invariant(count === 2, 'no asset for N card subtypes');
          symbolChar += symbolChar;
        }
        return symbolChar;
      });
    const multiplierSymbol = MultiplierCharToSymbolImage[multiplierSymbolChar];
    if (!multiplierSymbol) {
      console.warn('no symbol for', multiplierSymbolChar);
      return null;
    }
    prefix = (
      <div css={PillStyles.prefixContainer}>
        <SymbolView
          symbol={multiplierSymbol}
          css={PillStyles.multiplierSymbol}
        />
        {midfix}
        <span css={PillStyles.x}>×</span>
      </div>
    );
  }
  // disable extra chrome for now
  // extraChromeCSS = DefaultPillChrome;
  extraChromeCSS = null;

  return (
    <div
      css={[
        PillStyles.container,
        props.hideChrome
          ? undefined
          : [
              PillChromeByResource[output.outputResource],
              PillStyles.pillChrome,
              extraChromeCSS,
            ],
      ]}
      className={props.className}
      style={{
        ...props.style,
      }}
    >
      {prefix}
      <SymbolWithNumber
        symbol={ResourceToSymbol[output.outputResource]}
        number={output.outputRatio}
      />
    </div>
  );
}

export function SymbolWithNumber(props: {
  symbol: Symbols;
  number: number;
  height?: number;
}) {
  return (
    <div css={PillStyles.symbolWithNumberContainer}>
      <SymbolView
        symbol={props.symbol}
        css={PillStyles.symbol}
        style={{ height: props.height }}
      />
      <span
        css={PillStyles.symbolNumber}
        style={{
          fontSize: props.height ? 0.75 * props.height : undefined,
        }}
      >
        {props.number}
      </span>
    </div>
  );
}

export function EffectTriggerPrefix(props: {
  symbol: Symbols;
  secondSymbol?: Symbols;
}) {
  return (
    <>
      <SymbolView
        symbol={props.symbol}
        css={EffectTriggerStyles.triggerSymbol}
      />
      {props.secondSymbol && (
        <SymbolView
          symbol={props.secondSymbol}
          css={EffectTriggerStyles.triggerSymbol}
        />
      )}
      <span css={EffectTriggerStyles.triggerColon}>:</span>
    </>
  );
}

export function GainOtherTriggerPrefix(props: { targetType: GainTargetType }) {
  return match(props.targetType)
    .with({ target_type: 'tribute' }, () => (
      <EffectTriggerPrefix symbol={Symbols.M3_ACQUIRE_TRIBUTE} />
    ))
    .with({ target_type: 'token' }, (t) => {
      invariant(t.token === Token.War, 'invalid token');
      return <EffectTriggerPrefix symbol={Symbols.ACQUIRE_TOKEN_WAR} />;
    })
    .with({ target_type: 'card_type' }, (t) => {
      return (
        <EffectTriggerPrefix
          symbol={Symbols.M3_ACQUIRE}
          secondSymbol={
            MultiplierCharToSymbolImage[getCardTypeChar(t.card_type)]
          }
        />
      );
    })
    .with({ target_type: 'card_subtype' }, (t) => {
      return (
        <EffectTriggerPrefix
          symbol={Symbols.M3_ACQUIRE}
          secondSymbol={
            MultiplierCharToSymbolImage[getCardSubTypeChar(t.card_subtype)]
          }
        />
      );
    })
    .exhaustive();
}

export function AllResourceEffectPill(props: {
  amount: string;
}): React.ReactNode {
  return (
    <div
      css={PillStyles.container}
      style={{
        background:
          'linear-gradient(90deg, rgba(230,230,230,1) 0%, rgba(230,230,230,1) 13.37%, rgba(207,167,33,1) 42.0%, rgba(224,79,16,1) 69%, rgba(150,65,255,1) 100%)',
      }}
    >
      <span css={PillStyles.text}>{props.amount}</span>
      <SymbolView symbol={Symbols.GOLD} css={PillStyles.symbol} />
      <SymbolView symbol={Symbols.MILITARY} css={PillStyles.symbol} />
      <SymbolView symbol={Symbols.FAVOR} css={PillStyles.symbol} />
    </div>
  );
}

const PillStyles = {
  container: css({
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',

    position: 'relative',

    borderRadius: '12px',
  }),
  pillChrome: css({
    '&::before': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      borderRadius: '12px',
    },
  }),
  prefixContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 3,
  }),
  text: css({
    marginLeft: 2.5,
    marginRight: 0.5,
    marginBottom: -1,

    fontSize: 10,
    fontWeight: 300,
  }),
  symbol: css({
    height: 12,
    width: 'auto',
    margin: 0,
  }),
  multiplierSymbol: css({
    height: 8,
    width: 'auto',
  }),
  resourceDivisor: css({
    fontSize: 4,
    fontWeight: 'bold',
    marginTop: 3,
    marginLeft: -1,
  }),
  x: css({
    fontSize: 6,
    marginTop: 1,
    color: 'black',
  }),
  outputRatio: css({
    fontSize: 8,
    marginTop: 1,
  }),
  symbolWithNumberContainer: css({
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  }),
  symbolNumber: css({
    fontSize: 9,
    fontWeight: 600,
    position: 'absolute',
    right: '50%',
    top: '50%',
    transform: 'translate(50%, -50%)',
    color: 'white',
    textShadow: '0px 0px 2px rgba(0, 0, 0, 1)',
  }),
} as const;
const PillChromeByResource: Record<Resource, SerializedStyles> = {
  gold: css({
    backgroundColor: 'rgb(207, 167, 33)',
  }),
  military: css({
    backgroundColor: 'rgb(224, 79, 16)',

    '&::before': {
      border: '1px solid rgb(117, 35, 3)',
    },
  }),
  favor: css({
    backgroundColor: 'rgb(150, 65, 255)',
  }),
  warTokens: css({
    backgroundColor: 'pink',
  }),
  rerollTokens: css({
    backgroundColor: 'pink',
  }),
};
const EffectTriggerStyles = {
  triggerSymbol: css({
    height: 8.5,
    width: 'auto',
    marginRight: 0,
    marginLeft: 1,
  }),
  triggerColon: css({
    fontSize: 8,
    marginRight: 1,
    verticalAlign: 'middle',
  }),
};
const PillChromeByMultiplierType: Partial<
  Record<AbilityOutputMultiplierType, SerializedStyles>
> = {
  [CardType.Resource]: css({
    backgroundColor: CardTypeToFrameColor[CardType.Resource],
  }),
  [CardType.Conflict]: css({
    backgroundColor: CardTypeToFrameColor[CardType.Conflict],
  }),
  [CardType.Prayer]: css({
    backgroundColor: CardTypeToFrameColor[CardType.Prayer],
  }),
};
const DefaultPillChrome = css({
  backgroundColor: 'rgb(180, 180, 180)',
});

const MultiplierCharToSymbolImage: Record<string, Symbols> = {
  R: Symbols.CARD_TYPE_RESOURCE,
  RR: Symbols.CARD_TYPE_RESOURCE_DOUBLE,
  C: Symbols.CARD_TYPE_CONFLICT,
  CC: Symbols.CARD_TYPE_CONFLICT_DOUBLE,
  P: Symbols.CARD_TYPE_PRAYER,
  PP: Symbols.CARD_TYPE_PRAYER_DOUBLE,

  S: Symbols.CARD_SET,

  D: Symbols.CARD_SUBTYPE_BUILDING,
  DD: Symbols.CARD_SUBTYPE_BUILDING_DOUBLE,
  U: Symbols.CARD_SUBTYPE_UNIT,
  UU: Symbols.CARD_SUBTYPE_UNIT_DOUBLE,
  E: Symbols.CARD_SUBTYPE_EVENT,
  EE: Symbols.CARD_SUBTYPE_EVENT_DOUBLE,

  W: Symbols.TOKEN_WAR,
  A1: Symbols.TOKEN_AGE1,
  A2: Symbols.TOKEN_AGE2,
  A3: Symbols.TOKEN_AGE3,
};
