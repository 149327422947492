/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import React from 'react';

import * as CardTypes from '@mythos/game/CardTypes';

import { isCardSubType } from '@mythos/game/CardTypes';
import { assertResource } from '@mythos/game/Resources';
import { ageString } from '@mythos/game/Utility';
import invariant from 'invariant';
import nullthrows from 'nullthrows';
import { match } from 'ts-pattern';
import {
  BaseResourceToSymbol,
  CardSubtypeToSymbol,
  CardTypeToSymbol,
  ResourceToSymbol,
  SymbolView,
} from './Symbols';

/*
const color_by_resource = {
  gold: 'rgb(207, 167, 33)',
  military: 'rgb(224, 79, 16)',
  favor: 'rgb(150, 65, 255)',
} as const;

const PillStyles = {
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'flex-end',

    borderRadius: '12px',
    marginLeft: 1,
    boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.5)',
  },
  text: {
    marginLeft: 3,
    marginRight: 1,

    fontSize: 14,
    fontWeight: 300,
  },
  symbol: {
    height: 14,
    width: 14,
    margin: 0,
  },
} as const;
const InlinePillStyles = {
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'flex-end',

    borderRadius: '7px',
  },
  text: {
    marginLeft: 2,
    color: 'black',
  },
  symbol: {
    height: 6,
    width: 6,
    margin: 0,
  },
} as const;

var pill_div = function (
  resource_character: string,
  amount: number,
  key: number,
  inline: boolean,
) {
  let style = inline ? InlinePillStyles : PillStyles;
  let resource_name = Resources.CharacterToResourceName[resource_character];
  let symbol = (
    <SymbolView symbol={ResourceToSymbol[resource_name]} style={style.symbol} />
  );
  let container_style = clone(style.container);
  container_style = extend(container_style, {
    backgroundColor: color_by_resource[resource_name],
  });
  return (
    <div style={container_style} key={key}>
      <span style={style.text}>{amount}</span>
      {symbol}
    </div>
  );
};
*/

function TypeWithSymbol(props: { type: string }) {
  const { type } = props;
  return (
    <span css={CardTypeSymbolStyles.container}>
      <SymbolView
        symbol={
          isCardSubType(type)
            ? CardSubtypeToSymbol[type]
            : CardTypeToSymbol[type as CardTypes.CardType]
        }
        css={[CardTypeSymbolStyles.symbol, CardTypeSymbolStyles.symbolInverted]}
      />
      {/* {type} */}
    </span>
  );
}
function TokenSymbol(props: { type: string }) {
  const { type } = props;
  const split = type.split(' ');
  const symbolKey = split[0].toLowerCase();
  const symbolTokenType = split[1].toLowerCase();
  return (
    <span css={CardTypeSymbolStyles.container}>
      <SymbolView
        symbol={
          symbolTokenType === 'tokens'
            ? ResourceToSymbol[assertResource(symbolKey)]
            : nullthrows(BaseResourceToSymbol[assertResource(symbolKey)])
        }
        css={[
          CardTypeSymbolStyles.symbol,
          // CardTypeSymbolStyles.symbolGrayScale,
        ]}
      />
      {/* {type} */}
    </span>
  );
}
const CardTypeSymbolStyles = {
  symbol: css({
    height: 10,
    width: 'auto',
    // marginRight: 1,
    verticalAlign: 'end',
    marginBottom: -1,
  }),
  symbolInverted: css({
    filter: 'invert(100%)',
  }),
  symbolGrayScale: css({
    filter: 'brightness(1.5) grayscale(100%)',
  }),
  container: css({
    whiteSpace: 'nowrap',
  }),
};

function PerAgePill(props: {
  values: [number, number, number];
  currentAge?: number;
}) {
  return (
    <div css={PerAgePillStyles.container}>
      {props.values.map((value, index) => {
        const isHighlighted = props.currentAge === index + 1;
        const isDimmed = props.currentAge !== undefined && !isHighlighted;
        return (
          <div
            key={index}
            css={[
              PerAgePillStyles.column,
              isHighlighted ? PerAgePillStyles.highlightedColumn : undefined,
              isDimmed ? PerAgePillStyles.dimmedColumn : undefined,
            ]}
          >
            <span css={PerAgePillStyles.columnAge}>{ageString(index + 1)}</span>
            <span css={PerAgePillStyles.columnValue}>{value}</span>
          </div>
        );
      })}
    </div>
  );
}
const PerAgePillStyles = {
  container: css({
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',

    borderRadius: 4,
    backgroundColor: 'rgb(142, 142, 142)',
    background:
      'linear-gradient(110deg, rgba(142,142,142,1) 0%, rgba(142,142,142,1) 13.5px, rgba(116,116,116,1) 13.5px, rgba(116,116,116,1) 67%, rgba(85,85,85,1) 67%, rgba(87,87,87,1) 100%);',
    height: 14,
    width: 36,

    verticalAlign: 'bottom',
    marginBottom: -0,
  }),
  column: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 12,
  }),
  columnAge: css({
    fontSize: 4,
    textShadow: '0px 0px 1px black',
    marginBottom: -1.5,
  }),
  columnValue: css({
    fontSize: 8,
    fontWeight: 700,
    textShadow: '0px 0px 1px black',
  }),
  highlightedColumn: css({
    color: 'rgb(227, 187, 53)',
  }),
  dimmedColumn: css({
    // color: 'rgb(181, 181, 181)',
  }),
};

export type PerAgePillOptions =
  | {
      display: 'all-values';
      values: [number, number, number];
      currentAge?: number;
    }
  | {
      display: 'single-value';
      values: [number, number, number];
      currentAge: number;
    };

var convert_signatures_to_symbols = function (
  content_string: string,
  agePillOptions?: PerAgePillOptions,
) {
  var content: React.ReactNode[] = [];
  var last = 0;
  const typeStrings = CardTypes.CardSetTypes.map((cardtype) =>
    CardTypes.getCardTypeString(cardtype),
  ).concat(
    CardTypes.CardSubTypes.map((cardtype) =>
      CardTypes.getCardSubTypeString(cardtype),
    ),
  );
  const typeStringsRegex = typeStrings.join('|');
  const regex = new RegExp(
    `\\b(${typeStringsRegex})\\b|\\b(Gold|Military|Favor) (Tokens|Base)\\b|\\b(X)\\b|(\\n)`,
    'g',
  );
  content_string.replace(
    regex,
    (matchStr, p1, p2, p3, p4, p5, offset, string) => {
      if (offset !== last) {
        content.push(string.slice(last, offset));
      }
      last = offset + matchStr.length;

      if (p1) {
        content.push(<TypeWithSymbol type={matchStr} key={content.length} />);
      } else if (p2 && p3) {
        content.push(<TokenSymbol type={matchStr} key={content.length} />);
      } else if (p4) {
        invariant(agePillOptions, 'missing age pill options');
        content.push(
          match(agePillOptions)
            .with({ display: 'all-values' }, (x) => {
              return <PerAgePill key={content.length} {...x} />;
            })
            .with({ display: 'single-value' }, (x) => {
              return (
                <span
                  css={PerAgePillStyles.highlightedColumn}
                  key={content.length}
                >
                  {x.values[x.currentAge - 1]}
                </span>
              );
            })
            .exhaustive(),
        );
      } else if (p5) {
        content.push(<br key={content.length} />);
      } else {
        invariant(false, 'unhandled match: %s', matchStr);
      }

      return '';
    },
  );
  // TODO: fix when we re-introduce pills into tributes
  // content_string.replace(/\[([MGV]\d+)\]/g, (match, p1, offset, string) => {
  //   if (offset !== last) {
  //     content.push(string.slice(last, offset));
  //   }
  //   last = offset + match.length;

  //   if (p1.startsWith('X')) {
  //     invariant(p1.length === 2, 'invalid resource counts string: %s', p1);
  //     let resource_character = p1[1];
  //     content.push(pill_div(resource_character, 'X', content.length, inline));
  //     return '';
  //   }

  //   content.push(
  //     pill_div(p1[0], parseInt(p1.slice(1)), content.length, inline),
  //   );

  //   // var character_counts = _.countBy(p1, _.identity);
  //   // _.each(character_counts, function (num, character) {
  //   //   content.push(pill_div(character, num, content.length, inline));
  //   // });

  //   return '';
  // });

  if (content_string.length !== last) {
    content.push(content_string.slice(last));
  }
  return content;
};

interface Props {
  content: string;
  perAgePillOptions?: PerAgePillOptions;

  style?: React.CSSProperties;
  className?: string;
}

export default function TextWithSymbols(props: Props) {
  var converted_content = convert_signatures_to_symbols(
    props.content,
    props.perAgePillOptions,
  );
  return (
    <div className={props.className} style={props.style}>
      {converted_content}
    </div>
  );
}
