
// generated/CardArt.ts
// @generated

import ArtAcademy from '../assets/card_images/ArtAcademy.jpg';
import ArtAcolyteOfZeona from '../assets/card_images/ArtAcolyteOfZeona.jpg';
import ArtAltarOfBounty from '../assets/card_images/ArtAltarOfBounty.jpg';
import ArtAltarOfStars from '../assets/card_images/ArtAltarOfStars.jpg';
import ArtAltarOfWar from '../assets/card_images/ArtAltarOfWar.jpg';
import ArtAmbush from '../assets/card_images/ArtAmbush.jpg';
import ArtAmphitheater from '../assets/card_images/ArtAmphitheater.jpg';
import ArtAqueduct from '../assets/card_images/ArtAqueduct.jpg';
import ArtArcher from '../assets/card_images/ArtArcher.jpg';
import ArtArchitect from '../assets/card_images/ArtArchitect.jpg';
import ArtArena from '../assets/card_images/ArtArena.jpg';
import ArtArmoury from '../assets/card_images/ArtArmoury.jpg';
import ArtAscension from '../assets/card_images/ArtAscension.jpg';
import ArtAutocraticOath from '../assets/card_images/ArtAutocraticOath.jpg';
import ArtAxeman from '../assets/card_images/ArtAxeman.jpg';
import ArtBarracks from '../assets/card_images/ArtBarracks.jpg';
import ArtFortifications from '../assets/card_images/ArtFortifications.jpg';
import ArtTempleDerion from '../assets/card_images/ArtTempleDerion.jpg';
import ArtBattleHymn from '../assets/card_images/ArtBattleHymn.jpg';
import ArtWarPriest from '../assets/card_images/ArtWarPriest.jpg';
import ArtBazaarTrader from '../assets/card_images/ArtBazaarTrader.jpg';
import ArtBellicoseLeader from '../assets/card_images/ArtBellicoseLeader.jpg';
import ArtBlessedBounty from '../assets/card_images/ArtBlessedBounty.jpg';
import ArtBlessedMight from '../assets/card_images/ArtBlessedMight.jpg';
import ArtBurialRites from '../assets/card_images/ArtBurialRites.jpg';
import ArtCaptain from '../assets/card_images/ArtCaptain.jpg';
import ArtCaravan from '../assets/card_images/ArtCaravan.jpg';
import ArtCharioteer from '../assets/card_images/ArtCharioteer.jpg';
import ArtChosenHero from '../assets/card_images/ArtChosenHero.jpg';
import ArtCoercedNegotiation from '../assets/card_images/ArtCoercedNegotiation.jpg';
import ArtCommander from '../assets/card_images/ArtCommander.jpg';
import ArtConscription from '../assets/card_images/ArtConscription.jpg';
import ArtContestedLand from '../assets/card_images/ArtContestedLand.jpg';
import ArtContestedMine from '../assets/card_images/ArtContestedMine.jpg';
import ArtFestivalGrounds from '../assets/card_images/ArtFestivalGrounds.jpg';
import ArtCraftsman from '../assets/card_images/ArtCraftsman.jpg';
import ArtCultist from '../assets/card_images/ArtCultist.jpg';
import ArtDemocraticOath from '../assets/card_images/ArtDemocraticOath.jpg';
import ArtDespoticLeader from '../assets/card_images/ArtDespoticLeader.jpg';
import ArtVillager from '../assets/card_images/ArtVillager.jpg';
import ArtPiousLeader from '../assets/card_images/ArtPiousLeader.jpg';
import ArtDiversion from '../assets/card_images/ArtDiversion.jpg';
import ArtDivineBlessing from '../assets/card_images/ArtDivineBlessing.jpg';
import ArtDruid from '../assets/card_images/ArtDruid.jpg';
import ArtExploration from '../assets/card_images/ArtExploration.jpg';
import ArtFarm from '../assets/card_images/ArtFarm.jpg';
import ArtFavoredRains from '../assets/card_images/ArtFavoredRains.jpg';
import ArtFestivities from '../assets/card_images/ArtFestivities.jpg';
import ArtForge from '../assets/card_images/ArtForge.jpg';
import ArtFuneralPyre from '../assets/card_images/ArtFuneralPyre.jpg';
import ArtCeremonyOfHarvest from '../assets/card_images/ArtCeremonyOfHarvest.jpg';
import ArtCeremonyOfPower from '../assets/card_images/ArtCeremonyOfPower.jpg';
import ArtGladiator from '../assets/card_images/ArtGladiator.jpg';
import ArtGlory from '../assets/card_images/ArtGlory.jpg';
import ArtGoliath from '../assets/card_images/ArtGoliath.jpg';
import ArtGranary from '../assets/card_images/ArtGranary.jpg';
import ArtGrandSiege from '../assets/card_images/ArtGrandSiege.jpg';
import ArtGravesite from '../assets/card_images/ArtGravesite.jpg';
import ArtGreatOliveTree from '../assets/card_images/ArtGreatOliveTree.jpg';
import ArtGuardHound from '../assets/card_images/ArtGuardHound.jpg';
import ArtGuardsman from '../assets/card_images/ArtGuardsman.jpg';
import ArtGuidance from '../assets/card_images/ArtGuidance.jpg';
import ArtTownHall from '../assets/card_images/ArtTownHall.jpg';
import ArtMemorial from '../assets/card_images/ArtMemorial.jpg';
import ArtHarvestFestival from '../assets/card_images/ArtHarvestFestival.jpg';
import ArtHarvestSeason from '../assets/card_images/ArtHarvestSeason.jpg';
import ArtHeroesFeast from '../assets/card_images/ArtHeroesFeast.jpg';
import ArtHighPriest from '../assets/card_images/ArtHighPriest.jpg';
import ArtHonorTheFallen from '../assets/card_images/ArtHonorTheFallen.jpg';
import ArtHorseman from '../assets/card_images/ArtHorseman.jpg';
import ArtHunter from '../assets/card_images/ArtHunter.jpg';
import ArtInspiringBattle from '../assets/card_images/ArtInspiringBattle.jpg';
import ArtIronMine from '../assets/card_images/ArtIronMine.jpg';
import ArtTempleGuard from '../assets/card_images/ArtTempleGuard.jpg';
import ArtLeader from '../assets/card_images/ArtLeader.jpg';
import ArtLegionnaire from '../assets/card_images/ArtLegionnaire.jpg';
import ArtLibrary from '../assets/card_images/ArtLibrary.jpg';
import ArtLighthouse from '../assets/card_images/ArtLighthouse.jpg';
import ArtCamp from '../assets/card_images/ArtCamp.jpg';
import ArtMarchOnward from '../assets/card_images/ArtMarchOnward.jpg';
import ArtMarketplace from '../assets/card_images/ArtMarketplace.jpg';
import ArtMausoleum from '../assets/card_images/ArtMausoleum.jpg';
import ArtMercantileLeader from '../assets/card_images/ArtMercantileLeader.jpg';
import ArtMercenary from '../assets/card_images/ArtMercenary.jpg';
import ArtMercenary2 from '../assets/card_images/ArtMercenary2.jpg';
import ArtMercenary3 from '../assets/card_images/ArtMercenary3.jpg';
import ArtMerchant from '../assets/card_images/ArtMerchant.jpg';
import ArtConqueringLeader from '../assets/card_images/ArtConqueringLeader.jpg';
import ArtMilitia from '../assets/card_images/ArtMilitia.jpg';
import ArtMillOfDerion from '../assets/card_images/ArtMillOfDerion.jpg';
import ArtMine from '../assets/card_images/ArtMine.jpg';
import ArtMonolithOfMight from '../assets/card_images/ArtMonolithOfMight.jpg';
import ArtStatue from '../assets/card_images/ArtStatue.jpg';
import ArtNewRoads from '../assets/card_images/ArtNewRoads.jpg';
import ArtTheocraticOath from '../assets/card_images/ArtTheocraticOath.jpg';
import ArtOppressor from '../assets/card_images/ArtOppressor.jpg';
import ArtOracle from '../assets/card_images/ArtOracle.jpg';
import ArtOutpost from '../assets/card_images/ArtOutpost.jpg';
import ArtPalisades from '../assets/card_images/ArtPalisades.jpg';
import ArtPantheon from '../assets/card_images/ArtPantheon.jpg';
import ArtPilgrimage from '../assets/card_images/ArtPilgrimage.jpg';
import ArtPillage from '../assets/card_images/ArtPillage.jpg';
import ArtPlunderer from '../assets/card_images/ArtPlunderer.jpg';
import ArtWarsinger from '../assets/card_images/ArtWarsinger.jpg';
import ArtDivineOrator from '../assets/card_images/ArtDivineOrator.jpg';
import ArtPriestOfPassage from '../assets/card_images/ArtPriestOfPassage.jpg';
import ArtPrizedOx from '../assets/card_images/ArtPrizedOx.jpg';
import ArtProfiteer from '../assets/card_images/ArtProfiteer.jpg';
import ArtProphet from '../assets/card_images/ArtProphet.jpg';
import ArtQuarry from '../assets/card_images/ArtQuarry.jpg';
import ArtWalls from '../assets/card_images/ArtWalls.jpg';
import ArtRanger from '../assets/card_images/ArtRanger.jpg';
import ArtRavagesOfWar from '../assets/card_images/ArtRavagesOfWar.jpg';
import ArtRelicTrade from '../assets/card_images/ArtRelicTrade.jpg';
import ArtRichesTrade from '../assets/card_images/ArtRichesTrade.jpg';
import ArtRitualOfPolis from '../assets/card_images/ArtRitualOfPolis.jpg';
import ArtRitualOfHeroes from '../assets/card_images/ArtRitualOfHeroes.jpg';
import ArtRitual from '../assets/card_images/ArtRitual.jpg';
import ArtSacrifice from '../assets/card_images/ArtSacrifice.jpg';
import ArtScavenge from '../assets/card_images/ArtScavenge.jpg';
import ArtScavenge2 from '../assets/card_images/ArtScavenge2.jpg';
import ArtScavenge3 from '../assets/card_images/ArtScavenge3.jpg';
import ArtScavenger from '../assets/card_images/ArtScavenger.jpg';
import ArtScout from '../assets/card_images/ArtScout.jpg';
import ArtScribe from '../assets/card_images/ArtScribe.jpg';
import ArtSeizeTheSpoils from '../assets/card_images/ArtSeizeTheSpoils.jpg';
import ArtLaborCamp from '../assets/card_images/ArtLaborCamp.jpg';
import ArtLaborCamp2 from '../assets/card_images/ArtLaborCamp2.jpg';
import ArtLaborCamp3 from '../assets/card_images/ArtLaborCamp3.jpg';
import ArtShrineOfBounty from '../assets/card_images/ArtShrineOfBounty.jpg';
import ArtShrineOfTribute from '../assets/card_images/ArtShrineOfTribute.jpg';
import ArtShrineOfWar from '../assets/card_images/ArtShrineOfWar.jpg';
import ArtSlinger from '../assets/card_images/ArtSlinger.jpg';
import ArtSoldierOfTyros from '../assets/card_images/ArtSoldierOfTyros.jpg';
import ArtSpearman from '../assets/card_images/ArtSpearman.jpg';
import ArtStable from '../assets/card_images/ArtStable.jpg';
import ArtStonemason from '../assets/card_images/ArtStonemason.jpg';
import ArtStrategist from '../assets/card_images/ArtStrategist.jpg';
import ArtStrikeGold from '../assets/card_images/ArtStrikeGold.jpg';
import ArtSwordsman from '../assets/card_images/ArtSwordsman.jpg';
import ArtTavern from '../assets/card_images/ArtTavern.jpg';
import ArtTempestOfWar from '../assets/card_images/ArtTempestOfWar.jpg';
import ArtTempleBaeren from '../assets/card_images/ArtTempleBaeren.jpg';
import ArtTerritorialDispute from '../assets/card_images/ArtTerritorialDispute.jpg';
import ArtTracker from '../assets/card_images/ArtTracker.jpg';
import ArtTradeDeal from '../assets/card_images/ArtTradeDeal.jpg';
import ArtTradingPost from '../assets/card_images/ArtTradingPost.jpg';
import ArtTrainingCamp from '../assets/card_images/ArtTrainingCamp.jpg';
import ArtTreasureVault from '../assets/card_images/ArtTreasureVault.jpg';
import ArtVeteran from '../assets/card_images/ArtVeteran.jpg';
import ArtVineyard from '../assets/card_images/ArtVineyard.jpg';
import ArtVisions from '../assets/card_images/ArtVisions.jpg';
import ArtWanderingLeader from '../assets/card_images/ArtWanderingLeader.jpg';
import ArtWarHistorian from '../assets/card_images/ArtWarHistorian.jpg';
import ArtWarlord from '../assets/card_images/ArtWarlord.jpg';
import ArtConscriptionCamp from '../assets/card_images/ArtConscriptionCamp.jpg';
import ArtWatchtower from '../assets/card_images/ArtWatchtower.jpg';
import ArtArmsTrade from '../assets/card_images/ArtArmsTrade.jpg';
import GenericPrayer from '../assets/card_images/GenericPrayer.jpg';

export const CardArt = {
  "ArtAcademy.jpg": ArtAcademy,
  "ArtAcolyteOfZeona.jpg": ArtAcolyteOfZeona,
  "ArtAltarOfBounty.jpg": ArtAltarOfBounty,
  "ArtAltarOfStars.jpg": ArtAltarOfStars,
  "ArtAltarOfWar.jpg": ArtAltarOfWar,
  "ArtAmbush.jpg": ArtAmbush,
  "ArtAmphitheater.jpg": ArtAmphitheater,
  "ArtAqueduct.jpg": ArtAqueduct,
  "ArtArcher.jpg": ArtArcher,
  "ArtArchitect.jpg": ArtArchitect,
  "ArtArena.jpg": ArtArena,
  "ArtArmoury.jpg": ArtArmoury,
  "ArtAscension.jpg": ArtAscension,
  "ArtAutocraticOath.jpg": ArtAutocraticOath,
  "ArtAxeman.jpg": ArtAxeman,
  "ArtBarracks.jpg": ArtBarracks,
  "ArtFortifications.jpg": ArtFortifications,
  "ArtTempleDerion.jpg": ArtTempleDerion,
  "ArtBattleHymn.jpg": ArtBattleHymn,
  "ArtWarPriest.jpg": ArtWarPriest,
  "ArtBazaarTrader.jpg": ArtBazaarTrader,
  "ArtBellicoseLeader.jpg": ArtBellicoseLeader,
  "ArtBlessedBounty.jpg": ArtBlessedBounty,
  "ArtBlessedMight.jpg": ArtBlessedMight,
  "ArtBurialRites.jpg": ArtBurialRites,
  "ArtCaptain.jpg": ArtCaptain,
  "ArtCaravan.jpg": ArtCaravan,
  "ArtCharioteer.jpg": ArtCharioteer,
  "ArtChosenHero.jpg": ArtChosenHero,
  "ArtCoercedNegotiation.jpg": ArtCoercedNegotiation,
  "ArtCommander.jpg": ArtCommander,
  "ArtConscription.jpg": ArtConscription,
  "ArtContestedLand.jpg": ArtContestedLand,
  "ArtContestedMine.jpg": ArtContestedMine,
  "ArtFestivalGrounds.jpg": ArtFestivalGrounds,
  "ArtCraftsman.jpg": ArtCraftsman,
  "ArtCultist.jpg": ArtCultist,
  "ArtDemocraticOath.jpg": ArtDemocraticOath,
  "ArtDespoticLeader.jpg": ArtDespoticLeader,
  "ArtVillager.jpg": ArtVillager,
  "ArtPiousLeader.jpg": ArtPiousLeader,
  "ArtDiversion.jpg": ArtDiversion,
  "ArtDivineBlessing.jpg": ArtDivineBlessing,
  "ArtDruid.jpg": ArtDruid,
  "ArtExploration.jpg": ArtExploration,
  "ArtFarm.jpg": ArtFarm,
  "ArtFavoredRains.jpg": ArtFavoredRains,
  "ArtFestivities.jpg": ArtFestivities,
  "ArtForge.jpg": ArtForge,
  "ArtFuneralPyre.jpg": ArtFuneralPyre,
  "ArtCeremonyOfHarvest.jpg": ArtCeremonyOfHarvest,
  "ArtCeremonyOfPower.jpg": ArtCeremonyOfPower,
  "ArtGladiator.jpg": ArtGladiator,
  "ArtGlory.jpg": ArtGlory,
  "ArtGoliath.jpg": ArtGoliath,
  "ArtGranary.jpg": ArtGranary,
  "ArtGrandSiege.jpg": ArtGrandSiege,
  "ArtGravesite.jpg": ArtGravesite,
  "ArtGreatOliveTree.jpg": ArtGreatOliveTree,
  "ArtGuardHound.jpg": ArtGuardHound,
  "ArtGuardsman.jpg": ArtGuardsman,
  "ArtGuidance.jpg": ArtGuidance,
  "ArtTownHall.jpg": ArtTownHall,
  "ArtMemorial.jpg": ArtMemorial,
  "ArtHarvestFestival.jpg": ArtHarvestFestival,
  "ArtHarvestSeason.jpg": ArtHarvestSeason,
  "ArtHeroesFeast.jpg": ArtHeroesFeast,
  "ArtHighPriest.jpg": ArtHighPriest,
  "ArtHonorTheFallen.jpg": ArtHonorTheFallen,
  "ArtHorseman.jpg": ArtHorseman,
  "ArtHunter.jpg": ArtHunter,
  "ArtInspiringBattle.jpg": ArtInspiringBattle,
  "ArtIronMine.jpg": ArtIronMine,
  "ArtTempleGuard.jpg": ArtTempleGuard,
  "ArtLeader.jpg": ArtLeader,
  "ArtLegionnaire.jpg": ArtLegionnaire,
  "ArtLibrary.jpg": ArtLibrary,
  "ArtLighthouse.jpg": ArtLighthouse,
  "ArtCamp.jpg": ArtCamp,
  "ArtMarchOnward.jpg": ArtMarchOnward,
  "ArtMarketplace.jpg": ArtMarketplace,
  "ArtMausoleum.jpg": ArtMausoleum,
  "ArtMercantileLeader.jpg": ArtMercantileLeader,
  "ArtMercenary.jpg": ArtMercenary,
  "ArtMercenary2.jpg": ArtMercenary2,
  "ArtMercenary3.jpg": ArtMercenary3,
  "ArtMerchant.jpg": ArtMerchant,
  "ArtConqueringLeader.jpg": ArtConqueringLeader,
  "ArtMilitia.jpg": ArtMilitia,
  "ArtMillOfDerion.jpg": ArtMillOfDerion,
  "ArtMine.jpg": ArtMine,
  "ArtMonolithOfMight.jpg": ArtMonolithOfMight,
  "ArtStatue.jpg": ArtStatue,
  "ArtNewRoads.jpg": ArtNewRoads,
  "ArtTheocraticOath.jpg": ArtTheocraticOath,
  "ArtOppressor.jpg": ArtOppressor,
  "ArtOracle.jpg": ArtOracle,
  "ArtOutpost.jpg": ArtOutpost,
  "ArtPalisades.jpg": ArtPalisades,
  "ArtPantheon.jpg": ArtPantheon,
  "ArtPilgrimage.jpg": ArtPilgrimage,
  "ArtPillage.jpg": ArtPillage,
  "ArtPlunderer.jpg": ArtPlunderer,
  "ArtWarsinger.jpg": ArtWarsinger,
  "ArtDivineOrator.jpg": ArtDivineOrator,
  "ArtPriestOfPassage.jpg": ArtPriestOfPassage,
  "ArtPrizedOx.jpg": ArtPrizedOx,
  "ArtProfiteer.jpg": ArtProfiteer,
  "ArtProphet.jpg": ArtProphet,
  "ArtQuarry.jpg": ArtQuarry,
  "ArtWalls.jpg": ArtWalls,
  "ArtRanger.jpg": ArtRanger,
  "ArtRavagesOfWar.jpg": ArtRavagesOfWar,
  "ArtRelicTrade.jpg": ArtRelicTrade,
  "ArtRichesTrade.jpg": ArtRichesTrade,
  "ArtRitualOfPolis.jpg": ArtRitualOfPolis,
  "ArtRitualOfHeroes.jpg": ArtRitualOfHeroes,
  "ArtRitual.jpg": ArtRitual,
  "ArtSacrifice.jpg": ArtSacrifice,
  "ArtScavenge.jpg": ArtScavenge,
  "ArtScavenge2.jpg": ArtScavenge2,
  "ArtScavenge3.jpg": ArtScavenge3,
  "ArtScavenger.jpg": ArtScavenger,
  "ArtScout.jpg": ArtScout,
  "ArtScribe.jpg": ArtScribe,
  "ArtSeizeTheSpoils.jpg": ArtSeizeTheSpoils,
  "ArtLaborCamp.jpg": ArtLaborCamp,
  "ArtLaborCamp2.jpg": ArtLaborCamp2,
  "ArtLaborCamp3.jpg": ArtLaborCamp3,
  "ArtShrineOfBounty.jpg": ArtShrineOfBounty,
  "ArtShrineOfTribute.jpg": ArtShrineOfTribute,
  "ArtShrineOfWar.jpg": ArtShrineOfWar,
  "ArtSlinger.jpg": ArtSlinger,
  "ArtSoldierOfTyros.jpg": ArtSoldierOfTyros,
  "ArtSpearman.jpg": ArtSpearman,
  "ArtStable.jpg": ArtStable,
  "ArtStonemason.jpg": ArtStonemason,
  "ArtStrategist.jpg": ArtStrategist,
  "ArtStrikeGold.jpg": ArtStrikeGold,
  "ArtSwordsman.jpg": ArtSwordsman,
  "ArtTavern.jpg": ArtTavern,
  "ArtTempestOfWar.jpg": ArtTempestOfWar,
  "ArtTempleBaeren.jpg": ArtTempleBaeren,
  "ArtTerritorialDispute.jpg": ArtTerritorialDispute,
  "ArtTracker.jpg": ArtTracker,
  "ArtTradeDeal.jpg": ArtTradeDeal,
  "ArtTradingPost.jpg": ArtTradingPost,
  "ArtTrainingCamp.jpg": ArtTrainingCamp,
  "ArtTreasureVault.jpg": ArtTreasureVault,
  "ArtVeteran.jpg": ArtVeteran,
  "ArtVineyard.jpg": ArtVineyard,
  "ArtVisions.jpg": ArtVisions,
  "ArtWanderingLeader.jpg": ArtWanderingLeader,
  "ArtWarHistorian.jpg": ArtWarHistorian,
  "ArtWarlord.jpg": ArtWarlord,
  "ArtConscriptionCamp.jpg": ArtConscriptionCamp,
  "ArtWatchtower.jpg": ArtWatchtower,
  "ArtArmsTrade.jpg": ArtArmsTrade,
  "GenericPrayer.jpg": GenericPrayer,
};
