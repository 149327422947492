import _, { min } from 'underscore';

import {
  affordableCards,
  BidPreferences,
  calculateCardValues,
  CardValueData,
  CardValuePreferences,
} from '@mythos/game/AIUtilities';
import Bot from '@mythos/game/Bot';
import { CardType } from '@mythos/game/CardTypes';
import { InflatedGame } from '@mythos/game/Game';
import { probabilityWinningMultiBattle } from '@mythos/game/Probability';
import { Resource } from '@mythos/game/Resources';
import * as Rules from '@mythos/game/Rules';
import { addCounters, CounterDelta, debug_log } from '@mythos/game/Utility';
import { randomSelect } from '@mythos/utils/utils';

//TODO: keep track players not spending their military to weight their effective military for consideration
//TODO: compute amount of military to spend based on 1. how much more military you have over opponent, 2. how much to spend based on EV
//TODO: consider a 'confidence' in the selection and whether to spend no military
//TODO: factor in synergy cards and their desirability to opponents
//TODO: check, i think the basic card selection is inflating value of mercanary
//TODO: clean up code, move into helper functions, consider perf improvments

export default class BasicBot extends Bot {
  // card prefences
  cardValuePreferences_: CardValuePreferences;
  bidPreferences_: BidPreferences;

  constructor(
    preferences?: CardValuePreferences,
    bidPreferences?: BidPreferences,
  ) {
    super();
    this.cardValuePreferences_ = preferences || {
      tribute_value: 1,
      card_output_values_by_age: {
        // TODO: revisit -- factor in turn/age etc and current production
        gold: [0.5, 0.5, 0.5],
        military: [0.5, 0.5, 0.25],
      } as Record<Resource, number[]>,

      random_value_range: [0, 0.001],
      target_gold_output_by_age: [4, 7, 8],
      military_supremacy_target: 1,
      military_supremacy_preference: 0.5,
      card_flat_favor_preference_by_age: [1, 1.5, 2],
      target_card_type_by_age: {
        [CardType.Leader]: [1, 1, 1],
        [CardType.Basic]: [0, 0, 0],
        [CardType.Resource]: [2, 4, 5],
        [CardType.Conflict]: [2, 3, 5],
        [CardType.Prayer]: [1, 3, 5],
        [CardType.None]: [0, 0, 0],
      },
      unaffordable_value_penalty: 10,

      // Basic Bot 2 preferences - keeping dumb for default
      card_cost_value_reduction_by_age: [0, 0, 0],
    };
    this.bidPreferences_ = bidPreferences || {
      low_value_threshold_by_age: [1, 2, 3],
      high_value_threshold_by_age: [8, 16, 24],
      conserve_military_factor_by_age: [0, 0, 0],
      bid_nothing_threshold_by_age: [0, 0, 0],
      bid_nothing_probability_by_age: [0, 0, 0],
    };
  }

  getPreferences(): CardValuePreferences {
    return this.cardValuePreferences_;
  }

  setPreferences(preferences: CardValuePreferences) {
    this.cardValuePreferences_ = preferences;
  }

  computeBid(game_state: InflatedGame, user_id: string) {
    const player = this.getPlayer(game_state, user_id);
    const context = Rules.makeContextFromGame(player, game_state);

    let purchaseable_cards = affordableCards(context, game_state.table);

    // Note: this could be smarter to avoid giving away clash tributes, denying players etc
    if (purchaseable_cards.affordable.length === 0) {
      debug_log(`<!> ${user_id}: cant afford anything, picking randomly`);
      let allIndices = game_state.table.map((_, index) => index);
      return {
        military: 0,
        tradeRowIndex: randomSelect(allIndices),
      };
    }

    let best_card_by_playerID = this.bestCardToSelectByPlayerId(game_state);

    // this.debug_log( best_card_by_playerID[user_id]);
    let card_to_draft = best_card_by_playerID[user_id];
    let card = card_to_draft.card;

    const baseCounters = Rules.computeBaseCounters(context);

    return {
      military: player.counters.military + baseCounters.military, // TODO, make informed decision about military bid
      tradeRowIndex: game_state.table.indexOf(card),
    };
  }

  override computeResolutionSelection(
    game_state: InflatedGame,
    user_id: string,
  ): Rules.ResolutionSelection {
    const player = this.getPlayer(game_state, user_id);
    const context = Rules.makeContextFromGame(player, game_state);
    let selectedCardID = player.selectedCard?.id;
    let affordableBasicCards = game_state.basicPile.filter(
      (card) => Rules.canAffordCard(context, card).canAfford,
    );
    const card_descending_values = calculateCardValues(
      game_state,
      user_id,
      affordableBasicCards,
      this.cardValuePreferences_,
    );
    let basicCardID = card_descending_values[0].card.id;

    let isAffordable =
      selectedCardID &&
      Rules.canAffordCard(context, game_state.cardsByID[selectedCardID])
        .canAfford;

    return {
      cardIDToGain:
        isAffordable && selectedCardID ? selectedCardID : basicCardID,
    };
  }

  bestCardToSelectByPlayerId(game_state: InflatedGame) {
    let players = game_state.players;
    // jumble players array to throw off bots ordering logic
    players = _.shuffle(players);
    // card, cost, value, bid
    let card_descending_values_by_playerID: {
      [user_id: string]: CardValueData[];
    } = {};
    let totalcounters_by_playerID: {
      [user_id: string]: CounterDelta;
    } = {};
    let card_index_by_playerID: {
      [user_id: string]: number;
    } = {};
    let max_opponent_military_counters_by_playerID: {
      [user_id: string]: number;
    } = {};
    let best_card_values_by_playerID: {
      [user_id: string]: CardValueData;
    } = {};

    // setup for each players
    players.forEach((player) => {
      let user_id = player.userID;
      const context = Rules.makeContextFromGame(player, game_state);
      totalcounters_by_playerID[user_id] = addCounters(
        player.counters,
        Rules.computeBaseCounters(context),
      );
      card_descending_values_by_playerID[user_id] = calculateCardValues(
        game_state,
        user_id,
        game_state.table,
        this.cardValuePreferences_,
      );
      card_index_by_playerID[user_id] = 0;
      best_card_values_by_playerID[user_id] =
        card_descending_values_by_playerID[user_id][
          card_index_by_playerID[user_id]
        ];
    });

    // find max opponent military counters
    players.forEach((player) => {
      let user_id = player.userID;
      max_opponent_military_counters_by_playerID[user_id] = _.max(
        _.map(players, (opponent) => {
          if (opponent.userID != user_id) {
            return totalcounters_by_playerID[opponent.userID]?.military || 0;
          }
        }) as number[],
      );
    });

    // iterate for each player to settle on a selection and bid until there are no changes
    // TODO: consider also resetting if bid changes, although this could cause infinite loop
    let index_changed = true;
    while (index_changed) {
      index_changed = false;
      debug_log(
        `--- calculating all players --- age${game_state.age} turn ${game_state.turn} ---`,
      );
      players.forEach((player) => {
        const user_id = player.userID;
        debug_log(`- ${user_id} -`);
        const military = totalcounters_by_playerID[user_id].military;
        let desired_card_index = card_index_by_playerID[user_id];

        const player_best_card_value_data =
          best_card_values_by_playerID[user_id];
        const player_best_card = player_best_card_value_data.card;
        const player_best_card_value = player_best_card_value_data.value;

        players.forEach((opponent) => {
          const opponent_id = opponent.userID;
          if (opponent_id == user_id) {
            return;
          }

          const opponent_military =
            totalcounters_by_playerID[opponent_id].military;

          const opponent_best_card_value_data =
            best_card_values_by_playerID[opponent_id];
          const opponent_best_card = opponent_best_card_value_data.card;
          const opponent_best_card_value = opponent_best_card_value_data.value;

          let next_best_card = null;
          let next_best_card_value = 0;
          if (desired_card_index < game_state.table.length - 1) {
            const next_best_card_value_data =
              card_descending_values_by_playerID[user_id][
                desired_card_index + 1
              ];
            next_best_card = next_best_card_value_data.card;
            next_best_card_value = next_best_card_value_data.value;
          }

          // if there is a presumed conflict, consider the probability of winning
          if (opponent_best_card.id === player_best_card.id) {
            // need special logic for when no card is safe to draft?
            const prob_of_winning = probabilityWinningMultiBattle(
              game_state.age,
              military,
              [[game_state.age, opponent_military]],
            );
            debug_log(
              `${user_id}: [${player_best_card.name}] also desired by ${opponent_id}. their military ${opponent_military} vs our ${military} (${prob_of_winning}%)`,
            );

            let pick_next_card = false;
            let new_value = 0;
            if (next_best_card && next_best_card_value > 0) {
              // if not other options, stick with card; TODO: still consider different bids) {
              new_value = prob_of_winning * player_best_card_value;
              debug_log(
                `${user_id}: [${player_best_card.name}] value reduced to ${new_value} due to conflict. next card [${next_best_card.name}] value ${next_best_card_value}`,
              );

              // if next card is now more valuable, pick it
              if (new_value < next_best_card_value) {
                debug_log(
                  `${user_id}: picking next card [${next_best_card.name}]`,
                );
                desired_card_index++;
                card_index_by_playerID[user_id] = desired_card_index;
                best_card_values_by_playerID[user_id] =
                  card_descending_values_by_playerID[user_id][
                    desired_card_index
                  ];
                pick_next_card = true;
                index_changed = true;
                // continue/break?
              }
            }

            if (!pick_next_card) {
              let to_bid = min([
                military,
                opponent_military + 5 * game_state.age,
              ]);
              // if (
              //   this.preferences_.conserve_military_factor_by_age[
              //     game_state.age - 1
              //   ] > 0
              // ) {
              //   to_bid -=
              //     (new_value - next_card_value) *
              //     this.preferences_.conserve_military_factor_by_age[
              //       game_state.age - 1
              //     ];
              // }
              // if sticking with same card, only outbid to 100%
              best_card_values_by_playerID[user_id].bid = to_bid;
              card_index_by_playerID[user_id] = desired_card_index;
              best_card_values_by_playerID[user_id] =
                card_descending_values_by_playerID[user_id][desired_card_index];
              debug_log(
                `${user_id}: sticking with [${player_best_card.name}] bidding ${best_card_values_by_playerID[user_id].bid}`,
              );
            }
          } else {
            //TODO: if no presumed conflict, only bid based on EV of card
            let to_bid = min([
              military,
              opponent_military + 5 * game_state.age,
            ]);
            // random change to bid nothing
            if (
              // value less than threshold
              player_best_card_value <
                this.bidPreferences_.bid_nothing_threshold_by_age[
                  game_state.age - 1
                ] &&
              Math.random() <
                this.bidPreferences_.bid_nothing_probability_by_age[
                  game_state.age - 1
                ]
            ) {
              to_bid = 0;
              debug_log(user_id + ': bidding nothing ****');
            }

            best_card_values_by_playerID[user_id].bid = to_bid;
            debug_log(
              `${user_id}: picking [${player_best_card.name}] no conflict, bidding ${best_card_values_by_playerID[user_id].bid} (opponent desired card: [${opponent_best_card.name}])`,
            );
          }
        });
      });
    }

    return best_card_values_by_playerID;
  }
}
