/** @jsx jsx */
/** @jsxFrag */
import { css, Global, jsx } from '@emotion/react';
import { CardType } from '@mythos/game/CardTypes';
import {
  CardDef,
  GameDefinitions,
  mergeModuleCards,
  TributeDef,
} from '@mythos/game/GameModel';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { CardBack, CardBackView } from './CardBackView';
import CardView from './CardView';
import TributeCardView from './TributeCardView';
import { fetchGameDefinitions } from './actions';

const BASIC_CARD_COUNT = 6;
const LEADER_CARD_COUNT = 5;
const ALT_LEADER_CARD_COUNT = 0;
const TRIBUTE_CARD_COUNT = 1;
const BASE_CARD_COUNT = 1;

function matchGlob(pattern: string, str: string): boolean {
  const regexPattern = pattern
    .split('*')
    .map((s) => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
    .join('.*');
  return new RegExp(`^${regexPattern}$`, 'i').test(str);
}

function PrintCardsPage(props: {
  gameDefinitions: GameDefinitions | null;
  dispatch: React.Dispatch<any>;
}) {
  const { gameDefinitions, dispatch } = props;
  const [searchParams] = useSearchParams();
  const moduleName = searchParams.get('module');
  const include = searchParams.getAll('include');
  const includeTributes = searchParams.get('includeTributes');
  const excludeTributes = searchParams.get('excludeTributes');

  const shouldIncludeCard = (card: CardDef | TributeDef) => {
    if (excludeTributes && !('cost' in card)) {
      return false;
    }

    let hasIncludeCheck = false;
    if (includeTributes) {
      if (!('cost' in card)) {
        return true;
      }
      hasIncludeCheck = true;
    }
    if (include.length > 0) {
      const matches = include.some((pattern) => matchGlob(pattern, card.name));
      if (matches) {
        return true;
      }
      hasIncludeCheck = true;
    }

    return !hasIncludeCheck;
  };

  const modifiedCardCount = (card: CardDef | TributeDef, count: number) => {
    if (!shouldIncludeCard(card)) {
      return 0;
    }
    return count;
  };

  useEffect(() => {
    dispatch(fetchGameDefinitions());
  }, []);

  if (!gameDefinitions) {
    return <div>Loading...</div>;
  }

  let { cardDefs, tributeCardDefs } = gameDefinitions;
  const { cardDefinitions, tributeCardDefinitions } = mergeModuleCards(
    cardDefs,
    tributeCardDefs,
    [],
  );
  cardDefs = cardDefinitions;
  tributeCardDefs = tributeCardDefinitions;

  if (moduleName) {
    const module = gameDefinitions.modules.find(
      (module) => module.key === moduleName,
    );
    if (!module) {
      throw new Error(`Module ${moduleName} not found`);
    }
    cardDefs = module.cardDefs;
    tributeCardDefs = module.tributeCardDefs;
  }

  const basiccards = cardDefs.filter((card) => card.type === CardType.Basic);
  const leadercards = cardDefs.filter((card) => card.type === CardType.Leader);
  const age1cards = cardDefs.filter(
    (card) => card.type !== CardType.Basic && card.age === 1,
  );
  const age2cards = cardDefs.filter(
    (card) => card.type !== CardType.Basic && card.age === 2,
  );
  const age3cards = cardDefs.filter(
    (card) => card.type !== CardType.Basic && card.age === 3,
  );

  const cardBackByType: { [key: string]: CardBack } = {
    age1: { id: 'age1back', type: 'age1' },
    age2: { id: 'age2back', type: 'age2' },
    age3: { id: 'age3back', type: 'age3' },
    tribute: { id: 'tributeback', type: 'tribute' },
    basic1: { id: 'basic1back', type: 'basic1' },
    basic2: { id: 'basic2back', type: 'basic2' },
    basic3: { id: 'basic3back', type: 'basic3' },
  };

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // flexWrap: 'wrap',
    // breakAfter: 'page',
  };

  return (
    <div className="App">
      <Global styles={PrintStyles.global} />
      <div id="age1cards" style={containerStyle}>
        {age1cards.map((card, i) => (
          <PrintCardContainer
            name={cardName(card, i)}
            backName={cardBackName(cardBackByType['age1'])}
            count={modifiedCardCount(card, BASE_CARD_COUNT)}
          >
            <CardView key={cardName(card, i)} card={card} />
          </PrintCardContainer>
        ))}
      </div>
      <div id="age2cards" style={containerStyle}>
        {age2cards.map((card, i) => (
          <PrintCardContainer
            name={cardName(card, i)}
            backName={cardBackName(cardBackByType['age2'])}
            count={modifiedCardCount(card, BASE_CARD_COUNT)}
          >
            <CardView key={cardName(card, i)} card={card} />
          </PrintCardContainer>
        ))}
      </div>
      <div id="age3cards" style={containerStyle}>
        {age3cards.map((card, i) => (
          <PrintCardContainer
            name={cardName(card, i)}
            backName={cardBackName(cardBackByType['age3'])}
            count={modifiedCardCount(card, BASE_CARD_COUNT)}
          >
            <CardView key={cardName(card, i)} card={card} />
          </PrintCardContainer>
        ))}
      </div>
      <div id="basiccards" style={containerStyle}>
        {basiccards.map((card, i) => (
          <PrintCardContainer
            name={cardName(card, i)}
            backName={cardBackName(cardBackByType[`basic${card.age}`])}
            count={modifiedCardCount(card, BASIC_CARD_COUNT)}
          >
            <CardView key={cardName(card, i)} card={card} />
          </PrintCardContainer>
        ))}
      </div>
      <div id="tributecards" style={containerStyle}>
        {tributeCardDefs.map((card, i) => (
          <PrintCardContainer
            name={tributeName(card, i)}
            rotation={CardRotation.LandscapeFront}
            backName={cardBackName(cardBackByType.tribute)}
            count={modifiedCardCount(card, TRIBUTE_CARD_COUNT)}
          >
            <TributeCardView key={tributeName(card, i)} card={card} />
          </PrintCardContainer>
        ))}
      </div>
      <div id="leadercards" style={containerStyle}>
        {leadercards.map((card, i) => (
          <PrintCardContainer
            name={cardName(card, i)}
            count={modifiedCardCount(
              card,
              card.name === 'Leader'
                ? LEADER_CARD_COUNT
                : ALT_LEADER_CARD_COUNT,
            )}
          >
            <CardView key={cardName(card, i)} card={card} />
          </PrintCardContainer>
        ))}
      </div>
      <div id="cardbacks" style={containerStyle}>
        {Object.values(cardBackByType).map((cardBack, i) => (
          <PrintCardContainer
            name={cardBackName(cardBack)}
            count={0}
            rotation={
              cardBack.type === 'tribute'
                ? CardRotation.LandscapeBack
                : CardRotation.Vertical
            }
          >
            <CardBackView key={cardBackName(cardBack)} card={cardBack} />
          </PrintCardContainer>
        ))}
      </div>
    </div>
  );
}
function mapStateToProps(state: any) {
  return {
    gameDefinitions: state.gameDefinitions,
  };
}
export default connect(mapStateToProps)(PrintCardsPage);

function cardName(card: CardDef, index: number): string {
  let prefix = `age${card.age}`;
  if (card.type === CardType.Leader) {
    prefix = 'leader';
  } else if (card.type === CardType.Basic) {
    prefix = `basic${card.age}`;
  }
  return cardNameHelper(prefix, index, card.name);
}
function tributeName(card: TributeDef, index: number): string {
  return cardNameHelper('tribute', index, card.name);
}
function cardBackName(card: CardBack): string {
  return `${card.type}-back`;
}
function cardNameHelper(prefix: string, index: number, name: string): string {
  return `${prefix}-${index + 1}-${name.toLowerCase().replace(/ /g, '_')}`;
}

enum CardRotation {
  Vertical = 'Vertical',
  LandscapeFront = 'LandscapeFront',
  LandscapeBack = 'LandscapeBack',
}

const PrintCardContainer = (props: {
  name: string;
  backName?: string;
  count?: number;
  rotation?: CardRotation;
  children: React.ReactNode;
}): React.ReactNode => {
  return (
    <div
      css={PrintStyles.cardContainer}
      className="print-card-container"
      data-name={props.name}
      data-back-name={props.backName}
      data-count={props.count ?? 1}
    >
      <div
        css={
          props.rotation === CardRotation.LandscapeFront
            ? PrintStyles.cardSubContainerLandscapeFront
            : props.rotation === CardRotation.LandscapeBack
              ? PrintStyles.cardSubContainerLandscapeBack
              : PrintStyles.cardSubContainer
        }
      >
        {props.children}
      </div>
    </div>
  );
};

const PrintStyles = {
  global: css({
    '@media print': {
      body: {
        backgroundImage: 'none',
        backgroundColor: 'inherit',
      },
      '@page': {
        size: 'letter portrait',
        margin: 0,
      },
    },
  }),
  cardContainer: css({
    position: 'relative',

    // 300 dpi
    width: 816,
    height: 1110,

    backgroundColor: 'black',
  }),
  cardSubContainer: css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(7.44)',
  }),
  cardSubContainerLandscapeFront: css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(-90deg) scale(7.44)',
  }),
  cardSubContainerLandscapeBack: css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(90deg) scale(7.44)',
  }),
};
