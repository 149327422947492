/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import * as AIUtilities from '@mythos/game/AIUtilities';
import { InflatedGame, InflatedPlayer } from '@mythos/game/Game';
import { Resource, Token, TokenToDisplayName } from '@mythos/game/Resources';
import { computeBaseCounters, makeContextFromGame } from '@mythos/game/Rules';
import { makeMultiCardRenderer } from 'CardView';
import HoverCardStore from 'HoverCardStore';
import React from 'react';
import { AnimationRefSetter, PlayerAnimationRefs } from './AnimationHooks';
import { CardCountsView } from './CardCountsView';
import GameTimeView from './GameTimeView';
import Symbols, {
  BaseResourceToSymbol,
  ResourceToSymbol,
  SymbolView,
  TokenToSymbol,
} from './Symbols';
import { UserWithColor } from './User';

export type PlayerSummaryCountMode = 'counters' | 'total';
interface PlayerSummaryViewProps {
  player: InflatedPlayer;
  game: InflatedGame;
  user: UserWithColor;
  isWinning: boolean;
  isReady: boolean;

  countMode: PlayerSummaryCountMode;
  toggleCountMode: () => void;

  animationRefSetter?: AnimationRefSetter;
}
export default function PlayerSummaryView(props: PlayerSummaryViewProps) {
  let { player, game, user, isWinning, isReady, animationRefSetter } = props;

  const context = makeContextFromGame(player, game);
  const production_counts = AIUtilities.totalProductionCounters(context);

  const base_counts = computeBaseCounters(context);
  const eog_favor_details = game.gameEndTimestamp
    ? null
    : AIUtilities.endOfGameEffectsFavor(context);
  const hide_favor = game.options.hideFavor && !game.gameEndTimestamp;

  let container_style: React.CSSProperties = {
    backgroundColor: isWinning ? 'rgb(255, 242, 204)' : 'rgb(247, 247, 247)',
  };

  let ready_style = {
    display: 'inline-block',
    width: 9,
    backgroundColor: isReady ? 'green' : 'red',
    height: '100%',
  };

  let profile_picture = (
    <img src={user.profilePictureURL} css={PlayerSummaryStyles.profileSymbol} />
  );

  return (
    <div
      ref={(node) => {
        animationRefSetter?.(player.userID, PlayerAnimationRefs.summary, node);
      }}
      css={PlayerSummaryStyles.container}
      style={container_style}
    >
      <div style={ready_style} />
      <div css={PlayerSummaryStyles.topRow}>
        <div css={PlayerSummaryStyles.verticalStackItem}>
          <div css={PlayerSummaryStyles.inlineRow}>
            {profile_picture}
            <span
              css={PlayerSummaryStyles.name}
              style={{
                color: user.color,
              }}
            >
              {user.name}
            </span>
          </div>
          <PlayerGameTimeView
            css={PlayerSummaryStyles.time}
            game={game}
            player={player}
            isReady={isReady}
          />
        </div>
        <div css={PlayerSummaryStyles.verticalStackItem}>
          <div css={PlayerSummaryStyles.resourceWrapper}>
            {!hide_favor && (
              <PlayerFavorDisplay
                ref={(node) =>
                  animationRefSetter?.(
                    player.userID,
                    PlayerAnimationRefs.favor,
                    node,
                  )
                }
                favor={player.counters.favor}
                eogFavor={eog_favor_details?.favor || 0}
                onEnterAdditionalFavor={() => {
                  HoverCardStore.setCard(
                    eog_favor_details?.triggeredCardIDs,
                    makeMultiCardRenderer(game.cardsByID),
                  );
                  HoverCardStore.setPosition('top-right-offset');
                }}
                onLeaveAdditionalFavor={() => {
                  HoverCardStore.setCard(null, null);
                }}
              />
            )}
            {hide_favor && (
              <>
                <PlayerTokenDisplay
                  token={Token.Age1}
                  count={player.counters.age1tokens}
                />
                <PlayerTokenDisplay
                  token={Token.Age2}
                  count={player.counters.age2tokens}
                />
                <PlayerTokenDisplay
                  token={Token.Age3}
                  count={player.counters.age3tokens}
                />
              </>
            )}
            {game.options.warTokens && (
              <PlayerTokenDisplay
                token={Token.War}
                count={player.counters.warTokens}
              />
            )}

            <div css={PlayerSummaryStyles.productionWrapper}>
              <PlayerResourceCounter
                resource="gold"
                tooltip="Gold"
                count={
                  props.countMode === 'counters'
                    ? player.counters.gold
                    : player.counters.gold + base_counts.gold
                }
                generateCount={production_counts.gold}
                baseCount={base_counts.gold}
                style={{ marginBottom: 4 }}
                countMode={props.countMode}
                toggleCountMode={props.toggleCountMode}
                ref={(node) =>
                  animationRefSetter?.(
                    player.userID,
                    PlayerAnimationRefs.gold,
                    node,
                  )
                }
              />
              <PlayerResourceCounter
                resource="military"
                tooltip="Military"
                count={
                  props.countMode === 'counters'
                    ? player.counters.military
                    : player.counters.military + base_counts.military
                }
                generateCount={production_counts.military}
                baseCount={base_counts.military}
                countMode={props.countMode}
                toggleCountMode={props.toggleCountMode}
                ref={(node) =>
                  animationRefSetter?.(
                    player.userID,
                    PlayerAnimationRefs.military,
                    node,
                  )
                }
              />
            </div>
          </div>
          <CardCountsView player={player} game={game} />
        </div>
      </div>
    </div>
  );
}

const PlayerSummaryStyles = {
  container: css({
    display: 'flex',
    height: 65,
    padding: 3,
  }),
  topRow: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  verticalStack: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-center',
  }),
  verticalStackItem: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }),
  inlineRow: css({
    display: 'inline-flex',
  }),
  resourceWrapper: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '65%',
    paddingLeft: 4,
  }),
  mobileResourceWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 6,
    flexGrow: 1,
  }),
  productionWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  }),
  name: css({
    display: 'inline-block',
    fontSize: 20,
    fontWeight: 'bold',
    maxWidth: 180,
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  time: css({
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: 16,
  }),
  profileSymbol: css({
    marginLeft: 5,
    marginRight: 5,
    height: 16,
    width: 16,
  }),
  favorSymbol: css({
    height: 23,
    width: 23,
    marginRight: 4,
  }),
  favorContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,

    fontSize: 23,
    fontWeight: 'bold',
  }),
  favorText: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }),
  eogFavor: css({
    fontSize: 16,
    fontWeight: 'normal',
    opacity: 0.6,
  }),
  eogFavorSymbol: css({
    height: 14,
    marginRight: 2,
  }),
  tokenSymbol: css({
    height: 15,
    width: 15,
  }),
  tokenContainer: css({
    display: 'flex',
    fontSize: 17,
    fontWeight: 'bold',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
} as const;

export const PlayerFavorDisplay = React.forwardRef<
  HTMLDivElement,
  {
    favor: number;
    eogFavor: number;
    onEnterAdditionalFavor?: () => void;
    onLeaveAdditionalFavor?: () => void;
  }
>((props, ref) => {
  return (
    <div title="Favor" css={PlayerSummaryStyles.favorContainer} ref={ref}>
      <span css={PlayerSummaryStyles.favorText}>
        <SymbolView
          symbol={Symbols.FAVOR}
          css={PlayerSummaryStyles.favorSymbol}
        />
        {props.favor}
      </span>
      {props.eogFavor > 0 && (
        <span
          css={PlayerSummaryStyles.eogFavor}
          onMouseEnter={props.onEnterAdditionalFavor}
          onMouseLeave={props.onLeaveAdditionalFavor}
        >
          <SymbolView
            symbol={Symbols.EOG}
            css={PlayerSummaryStyles.eogFavorSymbol}
          />
          {props.eogFavor}
        </span>
      )}
    </div>
  );
});

export const PlayerTokenDisplay = React.forwardRef<
  HTMLDivElement,
  { token: Token; count: number }
>((props, ref) => {
  return (
    <div
      title={TokenToDisplayName[props.token]}
      css={PlayerSummaryStyles.tokenContainer}
      ref={ref}
    >
      <SymbolView
        symbol={TokenToSymbol[props.token]}
        css={PlayerSummaryStyles.tokenSymbol}
      />
      <span>{props.count}</span>
    </div>
  );
});

type PlayerResourceCounterProps = {
  resource: Resource;
  tooltip: string;
  count: number;
  generateCount: number;
  baseCount: number;
  countMode: PlayerSummaryCountMode;
  toggleCountMode: () => void;
  className?: string;
  style?: React.CSSProperties;
};
export const PlayerResourceCounter = React.forwardRef<
  HTMLDivElement,
  PlayerResourceCounterProps
>((props, ref) => {
  return (
    <div
      ref={ref}
      className={props.className}
      css={PlayerResourceStyles.container}
      style={props.style}
      onClick={props.toggleCountMode}
    >
      <SymbolView
        symbol={ResourceToSymbol[props.resource]}
        css={PlayerResourceStyles.symbol}
        title={props.tooltip}
      />
      {props.countMode === 'total' && (
        <SymbolView
          symbol={BaseResourceToSymbol[props.resource]!}
          css={PlayerResourceStyles.symbolBase}
        />
      )}
      <span css={PlayerResourceStyles.countText} title="Counters">
        {props.count}
      </span>
      <SymbolView
        symbol={BaseResourceToSymbol[props.resource]!}
        css={[PlayerResourceStyles.symbolSmall]}
        title="Base"
      />
      <span
        css={
          props.countMode === 'counters'
            ? PlayerResourceStyles.baseText
            : PlayerResourceStyles.baseTextLight
        }
        title="Base"
      >
        {props.baseCount}
      </span>
      <SymbolView
        symbol={Symbols.EFFECT_PRODUCTION}
        css={PlayerResourceStyles.symbolSmall}
        title="Production"
      />
      <span css={PlayerResourceStyles.generateText} title="Production">
        {props.generateCount}
      </span>
    </div>
  );
});

const PlayerResourceStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  }),
  symbol: css({
    minWidth: 15,
    minHeight: 15,
    width: 15,
    height: 15,
    marginRight: 2,
  }),
  symbolSmall: css({
    minWidth: 12,
    minHeight: 12,
    width: 12,
    height: 12,
    marginRight: 2,
    // filter: 'grayscale(1) brightness(0)',
    // opacity: 0.7,
    filter: 'saturate(0%)',
  }),
  symbolBase: css({
    position: 'absolute',
    height: 12,
    width: 12,
    top: 6,
    left: 8,
  }),
  countContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }),
  countText: css({
    minWidth: 20,
    textAlign: 'right',
    marginRight: 2,
  }),
  baseText: css({
    minWidth: 17,
  }),
  baseTextLight: css({
    minWidth: 17,
    color: 'rgba(0, 0, 0, 0.5)',
  }),
  generateText: css({
    minWidth: 17,
  }),
};

export function PlayerGameTimeView(props: {
  className?: string;
  game: InflatedGame;
  player: InflatedPlayer;
  isReady: boolean;
  condensed?: boolean;
}) {
  const { game, player, isReady, condensed } = props;

  let game_running = !game.gameEndTimestamp;
  let split_start_timestamp =
    !isReady && game_running ? game.phaseStartTimestamp : undefined;
  let extra_split_time =
    game_running && !condensed
      ? game.phaseThinkingMillisByPlayerID[player.userID]
      : game.totalThinkingMillisByPlayerID[player.userID];
  let extra_pre_split_time =
    game_running && !condensed
      ? game.totalThinkingMillisByPlayerID[player.userID]
      : undefined;

  return (
    <GameTimeView
      css={PlayerSummaryStyles.time}
      className={props.className}
      splitStartTimestamp={split_start_timestamp}
      extraSplitTime={extra_split_time}
      extraPreSplitTime={extra_pre_split_time}
    />
  );
}
