import _, { times } from 'underscore';

import * as AIUtilities from './AIUtilities';
import * as CardAbilities from './CardAbilities';
import { InflatedGame, InflatedPlayer } from './Game';
import { AGES_PER_GAME } from './GameConstants';
import { EventTypes, GameEventOfType } from './GameEvents';
import Phases, { getPhaseOrder } from './Phases';
import { probabilityWinningMultiBattle } from './Probability';
import * as Rules from './Rules';
import { CardWithID } from './Rules';
import * as Utility from './Utility';

// var total_turns(game) = game.turnsPerAge * AGES_PER_GAME;
function total_turns(game: InflatedGame) {
  return game.turnsPerAge * AGES_PER_GAME;
}

export type MilitaryStats = {
  text: string;
  winningPlayerID: string;
  playerIDToProbability: { [k: string]: number };
  playerIDToBonus: { [k: string]: number };
  age: number;
  turn: number;
  cardID: string;
};
var Stats = {
  computeMilitaryStats: (game: InflatedGame) => {
    const allPlayerIDs = game.players.map((player) => player.userID);

    const military_items = game.events.filter((item) => {
      return item.type === EventTypes.CONFLICT_RESULTS;
    });
    const grouped_items = _.groupBy(military_items, (item) => {
      return item.turn + (item.age - 1) * game.turnsPerAge;
    });

    const results = _.times(total_turns(game), (i) => {
      const outcome_by_player = Object.fromEntries(
        allPlayerIDs.map((id) => [id, null as MilitaryStats | null]),
      );

      const items = grouped_items[i + 1];
      items.forEach((item) => {
        const first_round = item.payload.rounds![0];
        const playerIDs = Object.keys(first_round);
        if (playerIDs.length < 2) {
          return;
        }
        const winningPlayerID = item.payload.winningPlayerID!;
        const age = item.age;
        const playerIDToBonus = Object.fromEntries(
          playerIDs.map((id) => [id, first_round[id].bonus]),
        );
        const playerIDToProbability = Object.fromEntries(
          playerIDs.map((id) => [
            id,
            probabilityWinningMultiBattle(
              age,
              playerIDToBonus[id],
              playerIDs
                .filter((x) => x !== id)
                .map((x) => [age, playerIDToBonus[x]]),
            ),
          ]),
        );

        _.each(playerIDToBonus, (bonus, id) => {
          let modifier = '';
          if (
            _.all(playerIDToBonus, (b, i) => {
              return id === i || bonus > b;
            })
          ) {
            modifier = '+';
          } else if (
            _.any(playerIDToBonus, (b, i) => {
              if (id === i) {
                return false;
              }
              return bonus < b;
            })
          ) {
            modifier = '-';
          }
          const outcome = id === winningPlayerID ? 'W' : 'L';
          outcome_by_player[id] = {
            cardID: item.payload.cardID!,
            winningPlayerID,
            text: outcome + modifier,
            playerIDToProbability,
            playerIDToBonus,
            age,
            turn: (i % game.turnsPerAge) + 1,
          };
        });
      });

      return outcome_by_player;
    });

    return results;
  },
  computeGainedCard: (game: InflatedGame) => {
    var items = game.events.filter((item) => {
      return item.type === EventTypes.GAIN_CARD;
    });

    var grouped_items = _.groupBy(items, (item) => {
      return item.turn + Math.max(item.age - 1, 0) * game.turnsPerAge;
    });

    var cardsByID = game.cardsByID;

    return _.times(total_turns(game) + 1, (i) => {
      var turn_items = grouped_items[i];

      var cardByPlayerID: { [k: string]: CardWithID } = {};
      turn_items.forEach((item) => {
        var payload = item.payload;
        var card = <CardWithID>cardsByID[payload.cardID!];
        cardByPlayerID[payload.userID!] = card;
      });

      return cardByPlayerID;
    });
  },
  computeGainedTributes: (game: InflatedGame) => {
    const items = game.events.filter((item) => {
      return item.type === EventTypes.GAIN_TRIBUTES;
    });

    const grouped_items = _.groupBy(items, (item) => {
      return item.turn + Math.max(item.age - 1, 0) * game.turnsPerAge;
    });

    const playerIDs = game.players.map((player) => player.userID);
    return times(total_turns(game), (i) => {
      const turn_items = grouped_items[i + 1] || [];

      const tributesByPlayerID = Object.fromEntries(
        playerIDs.map((id) => [id, [] as string[]]),
      );
      turn_items.forEach((item) => {
        const payload = item.payload;
        tributesByPlayerID[payload.userID] = payload.tributeIDs;
      });

      return tributesByPlayerID;
    });
  },
  computeGainedFavorStats: function (game: InflatedGame) {
    var items = game.events.filter((item) => {
      return (
        item.type === EventTypes.PRODUCTION ||
        item.type === EventTypes.GAIN_CARD ||
        item.type === EventTypes.GAIN_TRIBUTES ||
        item.type === EventTypes.END_GAME_SCORING ||
        item.type === EventTypes.EARN_TOKEN ||
        item.type === EventTypes.END_GAME_EFFECTS
      );
    });

    var grouped_items = _.groupBy(items, (item) => {
      return Math.max(0, item.turn + (item.age - 1) * game.turnsPerAge);
    });

    var player_ids = game.players.map((player) => {
      return player.userID;
    });
    var totals_by_player = Object.fromEntries(
      player_ids.map((id) => [
        id,
        {
          total_favor: 0,
          total_card_favor: 0,
          total_triggered_favor: 0,
          total_tribute_favor: 0,
          total_prophecy_favor: 0,
        },
      ]),
    );

    // used to rebuild the player cards turn by turn
    const players_reconstructed = game.players.map((player) => {
      return {
        ...player,
        cards: [] as CardWithID[],
      };
    });

    return _.map(_.range(total_turns(game) + 1), (i) => {
      var turn_items = grouped_items[i] || [];

      var stats_by_player = _.mapObject(totals_by_player, _.clone);
      turn_items.forEach((item) => {
        var playerID = item.payload.userID!;
        var favor = 'favor' in item.payload ? item.payload.favor : 0;

        var stats: any = stats_by_player[playerID];
        if (item.type === EventTypes.GAIN_CARD) {
          stats.delta_card_favor = favor;
          stats.total_card_favor += favor;

          const player_reconstructed = players_reconstructed.find(
            (p) => p.userID === playerID,
          );
          if (player_reconstructed) {
            player_reconstructed.cards.push(
              game.cardsByID[item.payload.cardID!] as CardWithID,
            );
            const context = Rules.makeContextFromGame(
              player_reconstructed,
              game,
            );
            stats.total_prophecy_favor =
              AIUtilities.endOfGameEffectsFavor(context).favor;
          }
        } else if (item.type === EventTypes.EARN_TOKEN) {
          var triggered_favor = item.payload.triggeredCards?.favor || 0;
          stats.delta_triggered_favor = triggered_favor;
          stats.total_triggered_favor += triggered_favor;
          stats.total_favor += triggered_favor;
        } else if (item.type === EventTypes.GAIN_TRIBUTES) {
          var triggered_favor = item.payload.triggeredCards?.favor;
          favor! += triggered_favor!;
          stats.delta_tribute_favor = favor;
          stats.total_tribute_favor += favor;
          stats.delta_triggered_favor = triggered_favor;
          stats.total_triggered_favor += triggered_favor;
        } else if (item.type === EventTypes.END_GAME_EFFECTS) {
          var end_of_game_favor = item.payload.triggeredCards?.favor || 0;
          stats.total_favor += end_of_game_favor;
        }
        stats.total_favor += favor;

        var totals = totals_by_player[playerID];
        totals.total_favor = stats.total_favor;
        totals.total_card_favor = stats.total_card_favor;
        totals.total_triggered_favor = stats.total_triggered_favor;
        totals.total_tribute_favor = stats.total_tribute_favor;
        totals.total_prophecy_favor = stats.total_prophecy_favor;
      });
      return stats_by_player;
    });
  },

  // returns an array of turns, each turn  is an object of the form:
  // { phase1: phase1_time_by_player, ..., phaseN: phaseN_time_by_player }
  getTimingDataByTurn: function (game: InflatedGame) {
    var phase_end_events = game.events.filter((event) => {
      return event.type === EventTypes.END_OF_PHASE;
    });
    var phase_end_items = phase_end_events.map((event) => {
      return {
        age: event.age,
        turn: event.turn,
        phase: event.phase,
        times: event.payload.elapsed_phase_time_by_player_id,
      };
    });
    var data_by_turn = _.groupBy(phase_end_items, (event) => {
      return (event.age - 1) * game.turnsPerAge + event.turn;
    });

    let zero_time_by_player = Object.fromEntries(
      game.players.map((player) => {
        return [player.userID, 0];
      }),
    );

    return times(total_turns(game) + 1, (i) => {
      let turn_data = data_by_turn[i];
      return Object.fromEntries(
        getPhaseOrder().map((phase) => {
          let phase_data = turn_data?.find((event) => event.phase === phase);
          return [
            phase,
            (phase_data && phase_data.times) || _.clone(zero_time_by_player),
          ];
        }),
      );
    });
  },

  // returns a map of player to total time in ms
  computeTotalTimeByPlayer: function (game: InflatedGame) {
    return sum_timing_data_by_player(Stats.getTimingDataByTurn(game));
  },

  // applies operator to the timing data generated by getTimingDataByTurn
  computeTimingEvents: function (
    game: InflatedGame,
    operator: (phase_times: { [userid: string]: number }) => {
      [userid: string]: number;
    },
  ) {
    var timing_data = Stats.getTimingDataByTurn(game);
    return _.map(timing_data, (turn) => {
      return _.mapObject(turn, operator);
    });
  },

  // transforms timing data from getTimingDataByTurn to blame time
  computeBlameTimingDataByTurn: function (game: InflatedGame) {
    return Stats.computeTimingEvents(game, (phase_times) => {
      var sorted_times = sort_phase_timing_data(phase_times);
      var last_time = 0;
      var num_blame_players = game.players.length;
      var total_blame_time = 0;
      var blame_times = sorted_times.map((time_data) => {
        var current_time = time_data.time;
        total_blame_time += (current_time - last_time) / num_blame_players;
        last_time = current_time;
        --num_blame_players;
        return [time_data.user_id, total_blame_time];
      });
      return Object.fromEntries(blame_times);
    });
  },
  computeTotalBlameTimeByPlayer: function (game: InflatedGame) {
    return sum_timing_data_by_player(Stats.computeBlameTimingDataByTurn(game));
  },

  // transforms timing data from getTimingDataByTurn to solo thinking time
  computeSoloThinkingTimingDataByTurn: function (game: InflatedGame) {
    return Stats.computeTimingEvents(game, (phase_times) => {
      var sorted_times = sort_phase_timing_data(phase_times);
      var solo_times = _.map(sorted_times, (time_data) => {
        var user_id = time_data.user_id;
        var time = time_data.time;
        if (game.players.length === 1) {
          return [user_id, time];
        }
        var longest_time = _.last(sorted_times)!.time;
        if (longest_time !== time) return [user_id, 0];
        var second_longest_time = sorted_times[game.players.length - 2].time;
        return [user_id, longest_time - (second_longest_time || 0)];
      });
      return Object.fromEntries(solo_times);
    });
  },
  computeTotalSoloThinkingTimeByPlayer: function (game: InflatedGame) {
    return sum_timing_data_by_player(
      Stats.computeSoloThinkingTimingDataByTurn(game),
    );
  },

  computeTotalTimeByPhase: function (game: InflatedGame) {
    const phase_end_events = game.events.filter(
      (event): event is GameEventOfType<EventTypes.END_OF_PHASE> => {
        return event.type === EventTypes.END_OF_PHASE;
      },
    );
    return Object.fromEntries(
      Object.values(Phases).map((phase) => {
        return [
          phase,
          Utility.sum(
            phase_end_events.filter((event) => {
              return event.payload.phase === phase;
            }),
            (event) => {
              return event.payload.elapsed_phase_time;
            },
          ),
        ];
      }),
    );
  },

  computeProductionData: function (game: InflatedGame) {
    let items = game.events.filter((item) => {
      return (
        item.type === EventTypes.PRODUCTION ||
        item.type === EventTypes.GAIN_CARD ||
        item.type === EventTypes.BID ||
        item.type === EventTypes.GAIN_TRIBUTES ||
        item.type === EventTypes.EARN_TOKEN
      );
    });

    let grouped_items = _.groupBy(items, (item) => {
      return Math.max(0, item.turn + (item.age - 1) * game.turnsPerAge);
    });

    let playerByID: { [k: string]: InflatedPlayer } = Object.fromEntries(
      game.players.map((serialized_player) => {
        return [serialized_player.userID, serialized_player];
      }),
    );

    let counters_by_player = _.mapObject(playerByID, (player, id) => {
      return {
        gold: 0,
        military: 0,
        gold_income: 0,
        military_income: 0,
        gold_base: 0,
        military_base: 0,
      };
    });

    let results = times(total_turns(game) + 1, (i) => {
      let index = i;

      let items = grouped_items[index] || [];

      // saves a snapshot of player tokens at the production step
      let production_step_resources_by_player = _.mapObject(
        playerByID,
        (player, id) => {
          return {
            gold: counters_by_player[id].gold,
            military: counters_by_player[id].military,
          };
        },
      );
      items.forEach((item) => {
        let player_id = item.payload.userID;

        if (item.type === EventTypes.PRODUCTION) {
          // this cap math could be incorrect if order of events is wrong
          // e.g. if gold is subtracted from a GAIN_CARD event before the production event
          counters_by_player[player_id].gold =
            counters_by_player[player_id].gold + item.payload?.gold!;
          counters_by_player[player_id].military =
            counters_by_player[player_id].military + item.payload?.military!;
          production_step_resources_by_player[player_id].gold =
            counters_by_player[player_id].gold;
          production_step_resources_by_player[player_id].military =
            counters_by_player[player_id].military;
          counters_by_player[player_id].gold_income = item.payload?.gold!;
          counters_by_player[player_id].military_income =
            item.payload?.military!;
        } else if (item.type === EventTypes.GAIN_CARD) {
          let gold_spent = item.payload.cost?.gold!;
          counters_by_player[player_id].gold -= gold_spent;
          let card = game.cardsByID[item.payload.cardID!!] as CardWithID;
          if (card) {
            // currently looks at end-game snapshot, which would be inaccurate if any
            // scaling base values are added in the future
            const context = Rules.makeContextFromGame(
              playerByID[player_id],
              game,
            );
            const baseAbility = CardAbilities.getBaseCountersAbility(card);
            if (baseAbility) {
              let delta = Utility.makeCounterDelta();
              Utility.applyCounterDelta(
                delta,
                baseAbility.getBaseCounters(context),
              );
              counters_by_player[player_id].gold_base += delta.gold;
              counters_by_player[player_id].military_base += delta.military;
            }
          }
          counters_by_player[player_id].gold += item.payload?.gold!;
          counters_by_player[player_id].military += item.payload?.military!;
        } else if (item.type === EventTypes.BID) {
          let military_spent =
            item.payload.spentMilitary ?? item.payload.military;
          counters_by_player[player_id].military -= military_spent;
        } else if (item.type === EventTypes.GAIN_TRIBUTES) {
          counters_by_player[player_id].gold +=
            item.payload.triggeredCards?.gold!;
          counters_by_player[player_id].military +=
            item.payload.triggeredCards?.military!;
        } else if (item.type === EventTypes.EARN_TOKEN) {
          counters_by_player[player_id].gold +=
            item.payload.triggeredCards?.gold!;
          counters_by_player[player_id].military +=
            item.payload.triggeredCards?.military!;
        }
      });

      let ret = _.mapObject(playerByID, (player, id) => {
        return {
          gold:
            production_step_resources_by_player[id].gold +
            counters_by_player[id].gold_base,
          military:
            production_step_resources_by_player[id].military +
            counters_by_player[id].military_base,
          gold_income: counters_by_player[id].gold_income,
          military_income: counters_by_player[id].military_income,
          gold_base: counters_by_player[id].gold_base,
          military_base: counters_by_player[id].military_base,
        };
      });
      return ret;
    });

    return results;
  },

  getWinningFavor: function (game: InflatedGame) {
    let highest_favor = Math.max(
      ...game.players.map((player) => player.counters.favor),
    );
    return highest_favor;
  },
  getWinningUserIDs: function (game: InflatedGame) {
    let winning_favor = Stats.getWinningFavor(game);
    return game.players
      .filter((user) => {
        return user.counters.favor === winning_favor;
      })
      .map((player) => player.userID);
  },
};

// takes in timing data (or modified timing data) and returns the total time for each player
function sum_timing_data_by_player(timing_data: any) {
  const time_by_player: { [userid: string]: number } = {};
  _.each(timing_data, (turn: any) => {
    _.each(turn, (phase_times: any) => {
      _.each(phase_times, (time: number, user_id: string) => {
        time_by_player[user_id] = (time_by_player[user_id] || 0) + time;
      });
    });
  });
  return time_by_player;
}

// takes in phase timing data of the form {user1: time1, ..., userN: timeN}
// and returns it in a sorted order of the form [..., {user_id: user1, time: time1}, ...]
function sort_phase_timing_data(phase_times: {
  [userid: string]: number;
}): { user_id: string; time: number }[] {
  return _.sortBy(
    _.map(phase_times, (time, user_id) => {
      return { user_id, time };
    }),
    (timing_data) => {
      return timing_data.time;
    },
  );
}

export default Stats;
