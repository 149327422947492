/** @jsx jsx */
import { jsx } from '@emotion/react';
import { CardSubType, CardType } from '@mythos/game/CardTypes';
import { Resource, Token } from '@mythos/game/Resources';
import invariant from 'invariant';
import React from 'react';

enum Symbols {
  ACQUIRE_TOKEN_WAR = 'acquire_token_war',
  ARROW_COLLAPSE = 'arrow_collapse',
  ARROW_EXPAND = 'arrow_expand',
  BASE_GOLD = 'base_gold',
  BASE_MILITARY = 'base_military',
  CARD_BACK = 'card_back',
  CARD_TYPE_PRAYER = 'card_type_prayer',
  CARD_TYPE_RESOURCE = 'card_type_resource',
  CARD_TYPE_CONFLICT = 'card_type_conflict',
  CARD_TYPE_BASIC = 'card_type_basic',
  CARD_TYPE_LEADER = 'card_type_leader',
  CARD_SET = 'card_type_set',
  CARD_SUBTYPE_BUILDING = 'card_building',
  CARD_SUBTYPE_UNIT = 'card_unit',
  CARD_SUBTYPE_EVENT = 'card_event',
  CARD_TYPE_PRAYER_DOUBLE = 'card_type_prayer_double',
  CARD_TYPE_RESOURCE_DOUBLE = 'card_type_resource_double',
  CARD_TYPE_CONFLICT_DOUBLE = 'card_type_conflict_double',
  CARD_SUBTYPE_BUILDING_DOUBLE = 'card_building_double',
  CARD_SUBTYPE_UNIT_DOUBLE = 'card_unit_double',
  CARD_SUBTYPE_EVENT_DOUBLE = 'card_event_double',
  CHAT = 'chat',
  EFFECT_PRODUCTION = 'effect_production',
  EFFECT_BASE = 'effect_base',
  FAVOR = 'favor',
  GOLD = 'gold',
  M3_ACQUIRE = 'm3_acquire',
  M3_ACQUIRE_TRIBUTE = 'm3_acquire_tribute',
  M3_DRAFT_CONFLICT = 'm3_draft_conflict',
  M3_DRAFT_RESOURCE = 'm3_draft_resource',
  M3_DRAFT_PRAYER = 'm3_draft_prayer',
  M3_DRAFT_LEFT = 'm3_draft_left',
  M3_DRAFT_RIGHT = 'm3_draft_right',
  M3_DRAFT = 'm3_draft',
  MILITARY = 'military',
  PLANNING = 'planning',
  RESOLUTION = 'resolution',
  TOKEN_TRIBUTE = 'token_tribute',
  TRIBUTE_FAVOR = 'tribute_favor',
  TRIBUTE_X = 'tribute_x',
  TOKEN_AGE1 = 'token_age1',
  TOKEN_AGE2 = 'token_age2',
  TOKEN_AGE3 = 'token_age3',
  TOKEN_WAR = 'token_war',
  UPGRADE = 'upgrade',
  REROLL = 'reroll',
  EOG = 'eog',
}
export default Symbols;

import Phases, { Phase } from '@mythos/game/Phases';
import SymbolAcquireTokenWar from '../assets/symbols/acquire_token_clash.svg';
import SymbolArrowCollapse from '../assets/symbols/arrow_collapse.svg';
import SymbolArrowExpand from '../assets/symbols/arrow_expand.svg';
import SymbolBaseGold from '../assets/symbols/base_gold.svg';
import SymbolBaseMilitary from '../assets/symbols/base_military.svg';
import SymbolCardBack from '../assets/symbols/card_back.svg';
import SymbolCardSet from '../assets/symbols/card_set.svg';
import SymbolCardSubtypeBuilding from '../assets/symbols/card_subtype_building.svg';
import SymbolCardSubtypeBuildingDouble from '../assets/symbols/card_subtype_building_two.svg';
import SymbolCardSubtypeEvent from '../assets/symbols/card_subtype_event.svg';
import SymbolCardSubtypeEventDouble from '../assets/symbols/card_subtype_event_two.svg';
import SymbolCardSubtypeUnit from '../assets/symbols/card_subtype_unit.svg';
import SymbolCardSubtypeUnitDouble from '../assets/symbols/card_subtype_unit_two.svg';
import SymbolCardTypeBasic from '../assets/symbols/card_type_basic.svg';
import SymbolCardTypeConflict from '../assets/symbols/card_type_conflict.svg';
import SymbolCardTypeConflictDouble from '../assets/symbols/card_type_conflict_two.svg';
import SymbolCardTypeLeader from '../assets/symbols/card_type_leader.svg';
import SymbolCardTypePrayer from '../assets/symbols/card_type_prayer.svg';
import SymbolCardTypePrayerDouble from '../assets/symbols/card_type_prayer_two.svg';
import SymbolCardTypeResource from '../assets/symbols/card_type_resource.svg';
import SymbolCardTypeResourceDouble from '../assets/symbols/card_type_resource_two.svg';
import SymbolChat from '../assets/symbols/chat.svg';
import SymbolEffectBase from '../assets/symbols/effect_base.svg';
import SymbolEffectProduction from '../assets/symbols/effect_production.svg';
import SymbolEOG from '../assets/symbols/eog.svg';
import SymbolFavor from '../assets/symbols/favor.svg';
import SymbolGold from '../assets/symbols/gold.svg';
import SymbolM3Acquire from '../assets/symbols/m3_acquire.svg';
import SymbolM3AcquireTribute from '../assets/symbols/m3_acquire_tribute.svg';
import SymbolM3Draft from '../assets/symbols/m3_draft.svg';
import SymbolM3DraftConflict from '../assets/symbols/m3_draft_conflict.svg';
import SymbolM3DraftLeft from '../assets/symbols/m3_draft_left.svg';
import SymbolM3DraftPrayer from '../assets/symbols/m3_draft_prayer.svg';
import SymbolM3DraftResource from '../assets/symbols/m3_draft_resource.svg';
import SymbolM3DraftRight from '../assets/symbols/m3_draft_right.svg';
import SymbolMilitary from '../assets/symbols/military.svg';
import SymbolPlanning from '../assets/symbols/planning.svg';
import SymbolReroll from '../assets/symbols/reroll.svg';
import SymbolResolution from '../assets/symbols/resolution.svg';
import SymbolTokenAge1 from '../assets/symbols/token_age1.svg';
import SymbolTokenAge2 from '../assets/symbols/token_age2.svg';
import SymbolTokenAge3 from '../assets/symbols/token_age3.svg';
import SymbolTokenWar from '../assets/symbols/token_clash.svg';
import SymbolTokenTribute from '../assets/symbols/token_tribute.svg';
import SymbolTributeFavor from '../assets/symbols/tribute_favor.svg';
import SymbolTributeX from '../assets/symbols/tribute_x.svg';
import SymbolUpgrade from '../assets/symbols/upgrade.svg';

export const SYMBOL_TO_IMAGE: Record<Symbols, string> = {
  [Symbols.ARROW_COLLAPSE]: SymbolArrowCollapse,
  [Symbols.ARROW_EXPAND]: SymbolArrowExpand,
  [Symbols.BASE_GOLD]: SymbolBaseGold,
  [Symbols.BASE_MILITARY]: SymbolBaseMilitary,
  [Symbols.CARD_BACK]: SymbolCardBack,
  [Symbols.CHAT]: SymbolChat,
  [Symbols.CARD_SUBTYPE_BUILDING]: SymbolCardSubtypeBuilding,
  [Symbols.CARD_SUBTYPE_UNIT]: SymbolCardSubtypeUnit,
  [Symbols.CARD_SUBTYPE_EVENT]: SymbolCardSubtypeEvent,
  [Symbols.CARD_TYPE_PRAYER]: SymbolCardTypePrayer,
  [Symbols.CARD_TYPE_RESOURCE]: SymbolCardTypeResource,
  [Symbols.CARD_TYPE_CONFLICT]: SymbolCardTypeConflict,
  [Symbols.CARD_TYPE_BASIC]: SymbolCardTypeBasic,
  [Symbols.CARD_TYPE_LEADER]: SymbolCardTypeLeader,
  [Symbols.CARD_SET]: SymbolCardSet,
  [Symbols.CARD_TYPE_PRAYER_DOUBLE]: SymbolCardTypePrayerDouble,
  [Symbols.CARD_TYPE_RESOURCE_DOUBLE]: SymbolCardTypeResourceDouble,
  [Symbols.CARD_TYPE_CONFLICT_DOUBLE]: SymbolCardTypeConflictDouble,
  [Symbols.CARD_SUBTYPE_BUILDING_DOUBLE]: SymbolCardSubtypeBuildingDouble,
  [Symbols.CARD_SUBTYPE_UNIT_DOUBLE]: SymbolCardSubtypeUnitDouble,
  [Symbols.CARD_SUBTYPE_EVENT_DOUBLE]: SymbolCardSubtypeEventDouble,
  [Symbols.EFFECT_PRODUCTION]: SymbolEffectProduction,
  [Symbols.EFFECT_BASE]: SymbolEffectBase,
  [Symbols.FAVOR]: SymbolFavor,
  [Symbols.GOLD]: SymbolGold,
  [Symbols.TOKEN_TRIBUTE]: SymbolTokenTribute,
  [Symbols.M3_ACQUIRE]: SymbolM3Acquire,
  [Symbols.M3_ACQUIRE_TRIBUTE]: SymbolM3AcquireTribute,
  [Symbols.M3_DRAFT_CONFLICT]: SymbolM3DraftConflict,
  [Symbols.M3_DRAFT_RESOURCE]: SymbolM3DraftResource,
  [Symbols.M3_DRAFT_PRAYER]: SymbolM3DraftPrayer,
  [Symbols.M3_DRAFT_LEFT]: SymbolM3DraftLeft,
  [Symbols.M3_DRAFT_RIGHT]: SymbolM3DraftRight,
  [Symbols.M3_DRAFT]: SymbolM3Draft,
  [Symbols.MILITARY]: SymbolMilitary,
  [Symbols.PLANNING]: SymbolPlanning,
  [Symbols.RESOLUTION]: SymbolResolution,
  [Symbols.TRIBUTE_FAVOR]: SymbolTributeFavor,
  [Symbols.TRIBUTE_X]: SymbolTributeX,
  [Symbols.TOKEN_WAR]: SymbolTokenWar,
  [Symbols.TOKEN_AGE1]: SymbolTokenAge1,
  [Symbols.TOKEN_AGE2]: SymbolTokenAge2,
  [Symbols.TOKEN_AGE3]: SymbolTokenAge3,
  [Symbols.ACQUIRE_TOKEN_WAR]: SymbolAcquireTokenWar,
  [Symbols.UPGRADE]: SymbolUpgrade,
  [Symbols.REROLL]: SymbolReroll,
  [Symbols.EOG]: SymbolEOG,
};

export function SymbolView(props: {
  symbol: Symbols;
  style?: React.CSSProperties;
  className?: string;
  title?: string;
}): React.ReactElement {
  invariant(SYMBOL_TO_IMAGE[props.symbol], 'invalid symbol: %s', props.symbol);
  return (
    <img
      src={`${SYMBOL_TO_IMAGE[props.symbol]}`}
      className={props.className}
      style={props.style}
      title={props.title}
    />
  );
}

export const CardTypeToSymbol: Record<CardType, Symbols> = {
  [CardType.Basic]: Symbols.CARD_TYPE_BASIC,
  [CardType.Resource]: Symbols.CARD_TYPE_RESOURCE,
  [CardType.Conflict]: Symbols.CARD_TYPE_CONFLICT,
  [CardType.Prayer]: Symbols.CARD_TYPE_PRAYER,
  [CardType.Leader]: Symbols.CARD_TYPE_LEADER,
  [CardType.None]: Symbols.CARD_TYPE_BASIC,
};
export const CardSubtypeToSymbol: Record<CardSubType, Symbols> = {
  [CardSubType.Unit]: Symbols.CARD_SUBTYPE_UNIT,
  [CardSubType.Building]: Symbols.CARD_SUBTYPE_BUILDING,
  [CardSubType.Event]: Symbols.CARD_SUBTYPE_EVENT,
};

export const ResourceToSymbol: Record<Resource, Symbols> = {
  gold: Symbols.GOLD,
  military: Symbols.MILITARY,
  favor: Symbols.FAVOR,
  warTokens: Symbols.TOKEN_WAR,
  rerollTokens: Symbols.REROLL,
};

export const BaseResourceToSymbol: Record<Resource, Symbols | null> = {
  gold: Symbols.BASE_GOLD,
  military: Symbols.BASE_MILITARY,
  favor: null,
  warTokens: null,
  rerollTokens: null,
};

export const PhaseToSymbol: Record<Phase, Symbols> = {
  [Phases.SETUP]: Symbols.PLANNING,
  [Phases.PLANNING]: Symbols.PLANNING,
  [Phases.RESOLUTION]: Symbols.RESOLUTION,
  [Phases.TRIBUTE]: Symbols.RESOLUTION,
  [Phases.WAR]: Symbols.PLANNING,
  [Phases.PRODUCTION]: Symbols.PLANNING,
};

export const TokenToSymbol: Record<Token, Symbols> = {
  [Token.War]: Symbols.TOKEN_WAR,
  [Token.Age1]: Symbols.TOKEN_AGE1,
  [Token.Age2]: Symbols.TOKEN_AGE2,
  [Token.Age3]: Symbols.TOKEN_AGE3,
};
