/** @jsx jsx */
/** @jsxFrag */
import { css, jsx, SerializedStyles } from '@emotion/react';
import Symbols, { SymbolView } from 'Symbols';
import { MStyles } from './MStyles';

export function MSymbolPrefix(props: {
  className?: string;
  symbol: Symbols;
  symbolCss?: SerializedStyles;
  symbolClassName?: string;
  symbolStyle?: React.CSSProperties;
  children?: React.ReactNode;
}) {
  return (
    <div css={MSymbolWithTextStyles.container} className={props.className}>
      <SymbolView
        symbol={props.symbol}
        css={props.symbolCss}
        className={props.symbolClassName}
        style={props.symbolStyle}
      />
      {props.children}
    </div>
  );
}

const MSymbolWithTextStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
  }),
};

export function MModal(props: {
  show: boolean;
  onClose: () => void;
  title: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}) {
  if (!props.show) {
    return null;
  }
  return (
    <div css={MModalStyles.container} className={props.className}>
      <div css={MModalStyles.modalBackground} onClick={props.onClose} />
      <div css={MModalStyles.headerContainer}>
        <div css={MModalStyles.headerText}>{props.title}</div>
        <button css={MModalStyles.closeButton} onClick={props.onClose}>
          Close
        </button>
      </div>
      {props.children}
    </div>
  );
}

const MModalStyles = {
  container: css({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1000,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  }),
  modalBackground: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: -1,
  }),
  headerContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    backgroundColor: 'white',
    height: 40,
    flexShrink: 0,
    padding: 4,
  }),
  headerText: css({
    display: 'flex',
    alignItems: 'center',
    fontSize: 28,
    fontWeight: 'bold',
  }),
  closeButton: css({}, MStyles.noSelect),
};
