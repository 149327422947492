/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { MEDIA_QUERY_MOBILE } from './mobile_ui/MStyles';

export default function PilesContainerView(props: {
  title: string;
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div css={PileContainerStyles.container} className={props.className}>
      <div css={PileContainerStyles.title}>{props.title}</div>
      {props.children}
    </div>
  );
}

const PileContainerStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    padding: 5,
    paddingRight: 10,
    paddingLeft: 10,
    margin: 5,

    [MEDIA_QUERY_MOBILE]: {
      position: 'relative',
      flexDirection: 'row',
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 0,
      paddingBottom: 0,
      margin: 0,
      // marginLeft: -12,

      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius: 10,

      boxSizing: 'border-box',
    },
  }),
  title: css({
    textAlign: 'center',
    fontSize: 12,
    padding: 1,
    color: 'rgb(210, 210, 210)',

    [MEDIA_QUERY_MOBILE]: {
      position: 'absolute',
      left: 14,

      writingMode: 'vertical-rl',
      transform: 'translatex(-50%) rotate(180deg)',
      transformOrigin: 'center',
    },
  }),
};
