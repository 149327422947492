/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/react';
import React from 'react';
import { useLongPress } from './mobile_ui/MButton';
import { MEDIA_QUERY_MOBILE } from './mobile_ui/MStyles';

export interface BaseCardViewProps {
  onMouseEnter?: React.EventHandler<React.MouseEvent>;
  onMouseLeave?: React.EventHandler<React.MouseEvent>;
  onLeftClick?: React.EventHandler<React.MouseEvent>;
  onRightClick?: React.EventHandler<React.MouseEvent>;

  bodyStyle?: React.CSSProperties;
  cardStyle?: React.CSSProperties;

  overlayStyle?: React.CSSProperties;
  overlayCSS?: SerializedStyles;
  overlayChildren?: React.ReactNode;

  titleView: React.ReactNode;

  className?: string;

  landscape?: boolean;
  mini?: boolean;
}
const BaseCardView = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<BaseCardViewProps>
>((props, ref) => {
  const {
    bodyStyle,
    cardStyle,
    overlayStyle,
    overlayCSS,
    overlayChildren,
    titleView,
    className,
    onLeftClick,
    onRightClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const longPressProps = useLongPress({
    onShortPress: (e) => {
      onLeftClick && onLeftClick(e as any);
    },
    onLongPress: (e) => {
      onMouseEnter && onMouseEnter(e as any);
    },
    onLongPressEnd: (e) => {
      onMouseLeave && onMouseLeave(e as any);
    },
    ignoreMovementTouches: true,
  });

  const sizeCSS = props.mini
    ? CardStyle.container_mini
    : props.landscape
      ? CardStyle.container_landscape
      : CardStyle.container_portrait;

  return (
    <div
      className={'base-card ' + (props.mini ? 'mini ' : '') + (className || '')}
      css={[CardStyle.container, sizeCSS]}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onContextMenu={onRightClick}
      {...longPressProps}
    >
      <div css={CardStyle.card} style={cardStyle}>
        <div css={CardStyle.body} style={bodyStyle}>
          {props.children}
        </div>
        {titleView}
      </div>
      {(overlayChildren || overlayStyle || overlayCSS) && (
        <div
          css={overlayCSS ? [CardStyle.overlay, overlayCSS] : CardStyle.overlay}
          style={overlayStyle}
        >
          {overlayChildren}
        </div>
      )}
    </div>
  );
});
export default BaseCardView;

export const BaseCardBoxShadow = 'inset 0px -15px 30px -15px #000000';
export const CARD_BORDER_RADIUS = 5;
const CardStyle = {
  container: css({
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',

    borderRadius: CARD_BORDER_RADIUS,
  }),
  container_portrait: css({
    // 2.48in x 3.48in standard poker size
    width: 100,
    height: '139.51612903225px',
  }),
  container_landscape: css({
    width: '139.51612903225px', //'1.453293in',
    height: 100,
  }),
  container_mini: css({
    // 1.61 x 2.48 mini size
    width: 100,
    height: 100,
  }),
  card: css({
    display: 'flex',
    flexDirection: 'column-reverse',

    border: 'solid black 4.5px',
    borderRadius: CARD_BORDER_RADIUS,

    flexGrow: 1,

    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',

    backgroundSize: '100% 100%',

    boxShadow: BaseCardBoxShadow,
  }),
  body: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',

    flexGrow: 1,
    margin: 3,
    marginTop: 0,

    backgroundSize: '100% 100%',
    boxShadow: 'inset 0px 0px 4px 0px rgba(0, 0, 0, 0.5)',

    [MEDIA_QUERY_MOBILE]: {
      backgroundPosition: 'center',
    },
  }),
  overlay: css({
    position: 'absolute',

    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    borderRadius: CARD_BORDER_RADIUS,
  }),
} as const;
