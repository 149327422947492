/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { MStyles } from './MStyles';

export function useLongPress(props: {
  onShortPress?: (e: React.SyntheticEvent) => void;
  onLongPress?: (e: React.SyntheticEvent) => void;
  onLongPressEnd?: (e: React.SyntheticEvent) => void;

  // TODO: mouseEnter/mouseLeave equivalents?

  longPressDuration?: number;
  ignoreMovementTouches?: boolean;
}) {
  const [pressState, setPressState] = React.useState<PressState | null>(null);

  const onTouchStart = (e: React.TouchEvent) => {
    const state: PressState = {
      longPressTimeout: setTimeout(() => {
        state.longPressElapsed = true;

        if (state.moved) {
          return;
        }
        props.onLongPress?.(e);
      }, props.longPressDuration ?? 500),
      longPressElapsed: false,
      moved: false,
    };
    setPressState(state);
  };

  const onTouchMove = (e: React.TouchEvent) => {
    if (!pressState) {
      return;
    }
    if (props.ignoreMovementTouches) {
      if (pressState.longPressTimeout) {
        pressState.moved = true;
      }
    }
  };

  const onTouchEnd = (e: React.TouchEvent) => {
    if (!pressState) {
      return;
    }
    if (pressState.longPressTimeout) {
      clearTimeout(pressState.longPressTimeout);
      pressState.longPressTimeout = null;
    }

    if (pressState.longPressElapsed) {
      props.onLongPressEnd?.(e);
      if (pressState.moved) {
        return;
      }
    } else {
      props.onShortPress?.(e);
    }

    if (e.cancelable) {
      e.preventDefault();
    }
    setPressState(null);
  };

  const onClick = (e: React.MouseEvent) => {
    if (e.cancelable) {
      e.preventDefault();
    }
    props.onShortPress?.(e);
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onClick,
  };
}
type PressState = {
  longPressTimeout: NodeJS.Timeout | null;
  longPressElapsed: boolean;
  moved: boolean;
};

export const MLongPressableButton = (props: {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  longPressDuration?: number;
  onShortPress?: (e: React.SyntheticEvent) => void;
  onLongPress?: (e: React.SyntheticEvent) => void;
  onLongPressEnd?: (e: React.SyntheticEvent) => void;
}) => {
  const longPressProps = useLongPress(props);

  return (
    <button className={props.className} style={props.style} {...longPressProps}>
      {props.children}
    </button>
  );
};

export const MHoverButton = (props: {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;

  onShortPress?: (e: React.SyntheticEvent) => void;
  onLongPress?: (e: React.SyntheticEvent) => void;
  onLongPressEnd?: (e: React.SyntheticEvent) => void;
}) => {
  return (
    <MLongPressableButton
      css={MHoverButtonStyle}
      className={props.className}
      style={props.style}
      onShortPress={props.onShortPress}
      onLongPress={props.onLongPress}
      onLongPressEnd={props.onLongPressEnd}
    >
      {props.children}
    </MLongPressableButton>
  );
};
const MHoverButtonStyle = css(MStyles.touchable, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  backgroundColor: 'white',
  opacity: 0.8,
  border: 'none',

  padding: 10,
  borderRadius: 30,
  height: 50,
  width: 50,
});
