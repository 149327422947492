import invariant from 'invariant';

export enum CardType {
  Leader = 'Leader',
  Basic = 'Basic',
  Resource = 'Resource',
  Conflict = 'Conflict',
  Prayer = 'Prayer',
  None = 'None',
}

export enum CardSubType {
  Building = 'Building',
  Unit = 'Unit',
  Event = 'Event',
}

export const CardSetTypes = [
  CardType.Resource,
  CardType.Conflict,
  CardType.Prayer,
] as const;

export const CardSubTypes = [
  CardSubType.Building,
  CardSubType.Unit,
  CardSubType.Event,
] as const;

export function getCardTypeString(cardType: CardType): string {
  return cardType;
}

export function getCardSubTypeString(cardSubType: CardSubType): string {
  return cardSubType;
}

export const CARD_CHAR_TO_CARD_TYPE: Record<string, CardType> = {
  B: CardType.Basic,
  R: CardType.Resource,
  C: CardType.Conflict,
  P: CardType.Prayer,
};
export const CHAR_TO_CARD_SUB_TYPE: Record<string, CardSubType> = {
  D: CardSubType.Building,
  U: CardSubType.Unit,
  E: CardSubType.Event,
};
export function cardCharToCardType(cardChar: string): CardType {
  const ret = CARD_CHAR_TO_CARD_TYPE[cardChar];
  invariant(ret, 'invalid card char: %s', cardChar);
  return ret;
}

export function getCardTypeChar(cardType: CardType): string {
  return cardType[0];
}

export function isCardType(char: string): char is CardType {
  return CardType.hasOwnProperty(char);
}

export function assertCardType(cardType: string): CardType {
  invariant(
    CardType.hasOwnProperty(cardType),
    'invalid card type: "%s"',
    cardType,
  );
  return cardType as CardType;
}

export function subtypeCharToCardSubType(char: string): CardSubType {
  const ret = CHAR_TO_CARD_SUB_TYPE[char];
  invariant(ret, 'invalid card sub type: %s', char);
  return ret;
}

export function maybeCharToCardSubType(char: string): CardSubType | null {
  return CHAR_TO_CARD_SUB_TYPE[char] || null;
}

export function getCardSubTypeChar(cardSubType: CardSubType): string {
  if (cardSubType === CardSubType.Building) {
    return 'D';
  }
  return cardSubType[0];
}

export function isCardSubType(char: string): char is CardSubType {
  return CardSubType.hasOwnProperty(char);
}

export function assertCardSubType(cardSubType: string): CardSubType {
  invariant(
    isCardSubType(cardSubType),
    'invalid card sub type: %s',
    cardSubType,
  );
  return cardSubType as CardSubType;
}
