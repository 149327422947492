/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import { MEDIA_QUERY_MOBILE, MStyles } from 'mobile_ui/MStyles';

export default function LobbyButton(props: {
  className?: string;
  children: React.ReactNode;
  onClick?: React.DOMAttributes<HTMLButtonElement>[`onClick`];
  secondary?: boolean;
}) {
  return (
    <button
      className={props.className}
      css={[
        LobbyButtonStyles.button,
        props.secondary ? LobbyButtonStyles.secondary : null,
      ]}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

export const LobbyButtonStyles = {
  button: css(
    {
      backgroundColor: 'rgb(227, 198, 101)',
      paddingLeft: 50,
      paddingRight: 50,
      minWidth: 160,
      marginLeft: 10,
      marginRight: 10,
      borderRadius: 2,
      border: 'none',
      borderBottom: '2px solid rgb(177, 148, 51)',
      outline: 'none',
      '&:hover': {
        backgroundColor: 'rgb(217, 188, 91)',
        borderColor: 'rgb(167, 138, 41)',
      },
      '&:active': {
        backgroundColor: 'rgb(207, 178, 71)',
        borderColor: 'rgb(157, 128, 31)',
      },
      color: 'rgb(0, 0, 0)',

      [MEDIA_QUERY_MOBILE]: {
        padding: 4,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 0,
        border: 'none',
      },

      textDecoration: 'none',
    },
    MStyles.touchableMatch,
  ),
  secondary: css({
    backgroundColor: 'rgb(195, 195, 195)',
    borderColor: 'rgb(150, 150, 150)',
    '&:hover': {
      backgroundColor: 'rgb(185, 185, 185)',
      borderColor: 'rgb(140, 140, 140)',
    },
    '&:active': {
      backgroundColor: 'rgb(175, 175, 175)',
      borderColor: 'rgb(130, 130, 130)',
    },
  }),
};
