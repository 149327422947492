import invariant from 'invariant';

import Bot from './Bot';
import RandomCardBot from './bots/RandomCardBot';
// import TutorialBot from '../bots/TutorialBot';
import BasicBot from './bots/BasicBot';
// import TutorialBot from '../bots/TutorialBot';
import { BidPreferences, CardValuePreferences } from './AIUtilities';
import EasyBot from './bots/EasyBot';
import { CardType } from './CardTypes';
import Game from './Game';
import { Resource } from './Resources';

export type BotDisplayMode = 'devOnly' | 'hidden' | 'visible';
export type BotDisplayData = {
  bot: Bot;
  name: string;
  displayMode: BotDisplayMode;
};
export const PlayableBots: { [key: string]: BotDisplayData } = {
  easy_bot: {
    bot: new EasyBot(),
    name: 'Easy Bot',
    displayMode: 'visible',
  },
  basic_bot: {
    bot: new BasicBot(),
    name: 'Advanced Bot',
    displayMode: 'visible',
  },
  basic_bot2: {
    bot: new BasicBot(
      {
        tribute_value: 1,
        card_output_values_by_age: {
          gold: [0.5, 0.5, 0.5],
          military: [0.5, 0.5, 0.25],
        } as Record<Resource, number[]>,
        random_value_range: [0, 0.001],
        target_gold_output_by_age: [4, 7, 8],
        military_supremacy_target: 1,
        military_supremacy_preference: 0.5,
        card_flat_favor_preference_by_age: [1, 1.5, 2],
        target_card_type_by_age: {
          [CardType.Leader]: [1, 1, 1],
          [CardType.Basic]: [0, 0, 0],
          [CardType.Resource]: [2, 4, 5],
          [CardType.Conflict]: [2, 3, 5],
          [CardType.Prayer]: [1, 3, 5],
        },
        card_cost_value_reduction_by_age: [0.01, 0.01, 0.01],
      } as CardValuePreferences,
      {
        conserve_military_factor_by_age: [0.005, 0.005, 0.005],
        bid_nothing_probability_by_age: [0.3, 0.3, 0.3],
        bid_nothing_threshold_by_age: [5, 5, 5],
      } as BidPreferences,
    ),
    name: 'Advanced Bot 2',
    displayMode: 'devOnly',
  },
  valid_move: {
    bot: new Bot(),
    name: 'Valid Move',
    displayMode: 'devOnly',
  },
  random_card: {
    bot: new RandomCardBot(),
    name: 'Random Card',
    displayMode: 'hidden',
  },
};

// returns true if any bot actions were added
export function addBotActions(game: Game): boolean {
  return game.getPlayers().some((player) => {
    let bot_type = player.getBotType();
    if (!bot_type) {
      return false;
    }

    let bot = PlayableBots[bot_type as keyof typeof PlayableBots].bot;
    invariant(bot, 'bot type "%s" not found', bot_type);

    let user_id = player.getID();
    let renderedGame = game.render(user_id);
    const inflatedGame = Game.inflateGame(renderedGame);
    let action = bot.actionForGameState(inflatedGame, user_id);

    if (action) {
      game.addAction(user_id, action);
      return true;
    }
    return false;
  });
}
