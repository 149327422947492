/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import React, { useCallback, useEffect } from 'react';

import Phases, { Phase } from '@mythos/game/Phases';
import { computeBaseCounters } from '@mythos/game/Rules';
import ActionStore, { ActionStoreCanSubmitResult } from './ActionStore';
import DieView from './DieView';
import { PlayerCounter } from './PlayerBoardView';
import Symbols, { SymbolView } from './Symbols';

import LoadingSpinner from '../assets/loading spinner icons8.com.svg';

export default function SelfPlayerView(props: {
  actionStore: ActionStore;
  ready: boolean;
  phase: Phase;
  rolls?: number[];
  waitingForAnimation?: boolean;
  onSkipAnimation?: () => void;
}) {
  const { rolls, phase, actionStore, ready } = props;
  const onReadyClick = useCallback(() => {
    actionStore.didClickSubmit();
  }, [actionStore]);
  const onRerollClick = useCallback(() => {
    actionStore.didClickSubmitReroll();
  }, [actionStore]);
  const onDeclineRerollClick = useCallback(() => {
    actionStore.didClickDeclineReroll();
  }, [actionStore]);
  const forceUpdate = React.useReducer((x) => x + 1, 0)[1];
  const actionStoreDidChange = useCallback(() => {
    forceUpdate();
  }, [forceUpdate]);

  useEffect(() => {
    actionStore.addListener(actionStoreDidChange);
    return () => {
      actionStore.removeListener(actionStoreDidChange);
    };
  }, [actionStore, actionStoreDidChange]);

  let dice_view: React.ReactNode = null;
  if (rolls && phase === Phases.PLANNING) {
    let die_view = rolls.map((roll, i) => <DieView key={i} roll={roll} />);
    dice_view = <div css={SelfPlayerStyles.diceContainer}>{die_view}</div>;
  }

  let phaseView: React.ReactNode = null;
  let buttonText = 'READY';
  if (phase === Phases.PLANNING) {
    const bid = actionStore.getMilitaryBidOrNan();
    const baseCounters = computeBaseCounters(actionStore.getContext());
    const bidLimits = actionStore.getMilitaryBidLimits();
    phaseView = (
      <div css={SelfPlayerStyles.bidContainer}>
        <PlayerCounter
          name="gold"
          count={actionStore.getPlayer().counters.gold}
          baseCount={baseCounters.gold}
        />
        <PlayerCounter
          name="military"
          count={actionStore.getPlayer().counters.military}
          baseCount={baseCounters.military}
        />
        Bid:
        <>
          <input
            css={SelfPlayerStyles.bidInput}
            name="bid"
            type="number"
            min={bidLimits.min}
            max={bidLimits.max}
            value={isNaN(bid) ? '' : bid}
            onChange={(e) => {
              if (e.target.value === '') {
                actionStore.didChangeMilitary(NaN);
              } else {
                actionStore.didChangeMilitary(parseInt(e.target.value));
              }
            }}
          />
          <SymbolView
            symbol={Symbols.MILITARY}
            css={SelfPlayerStyles.bidSymbol}
          />
        </>
      </div>
    );
  } else if (phase === Phases.WAR) {
    const warInfo = actionStore.getWarInfo();
    switch (warInfo?.rerollType) {
      case 'decline':
        buttonText = 'Submit Keep';
        break;
      case 'reroll':
        buttonText = 'Submit Reroll';
        break;
      default:
        buttonText = 'Submit';
    }
    phaseView = !props.waitingForAnimation && !ready && (
      <>
        <PlayerRerollButton
          style={{
            opacity: warInfo?.rerollType === 'decline' ? 0.5 : 1,
            backgroundColor: warInfo?.rerollType === 'reroll' ? 'red' : '',
          }}
          onClick={onRerollClick}
        >
          Reroll
        </PlayerRerollButton>
        <PlayerRerollButton
          style={{
            opacity: warInfo?.rerollType === 'reroll' ? 0.5 : 1,
            backgroundColor: warInfo?.rerollType === 'decline' ? 'red' : '',
          }}
          onClick={onDeclineRerollClick}
        >
          Keep
        </PlayerRerollButton>
      </>
    );
  }

  const skipButton = props.waitingForAnimation && (
    <SkipAnimationButton onClick={props.onSkipAnimation} />
  );
  const readyButton = !props.waitingForAnimation &&
    (phase === Phases.PLANNING ||
      phase === Phases.WAR ||
      phase === Phases.RESOLUTION) && (
      <PlayerStatusButton
        ready={ready}
        canSubmit={actionStore.canSubmit()}
        onReadyClick={onReadyClick}
      >
        {buttonText}
      </PlayerStatusButton>
    );
  return (
    <div css={SelfPlayerStyles.container}>
      {skipButton}
      {phaseView}
      {dice_view}
      {readyButton}
    </div>
  );
}

const SelfPlayerStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',

    margin: 5,
    marginBottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
    flex: '0 0 auto',

    borderRadius: 4,
    height: 35,
    minWidth: 200,
    backgroundColor: 'rgb(210, 210, 210)',
    // transition: 'height 0.5s',
    // overflow: 'hidden',
  }),
  diceContainer: css({
    height: 12,
    minWidth: 44,
    marginLeft: 4,
  }),
  bidContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  bidInput: css({
    marginLeft: 2,
    width: 40,
  }),
  bidSymbol: css({
    height: 20,
    width: 20,
    marginLeft: 2,
  }),
};

function PlayerStatusButton(props: {
  ready: boolean;
  canSubmit: ActionStoreCanSubmitResult;
  onReadyClick: () => void;
  children?: React.ReactNode;
}) {
  return props.ready ? (
    <div css={PlayerStatusButtonStyles.ready}>{props.children}</div>
  ) : (
    <div>
      <button
        css={PlayerStatusButtonStyles.root}
        onClick={props.onReadyClick}
        disabled={!props.canSubmit.result}
      >
        {props.children}
        {props.canSubmit.message && (
          <div
            css={PlayerStatusButtonStyles.cantSubmitMessage}
            className="cant-submit-message"
          >
            {props.canSubmit.message}
          </div>
        )}
      </button>
    </div>
  );
}
function PlayerRerollButton(props: {
  className?: string;
  style?: React.CSSProperties;
  onClick: () => void;
  children: React.ReactNode;
}) {
  return (
    <button
      className={props.className}
      style={props.style}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
const BUTTON_HEIGHT = 19;
const BUTTON_WIDTH = 113;
const PlayerStatusButtonStyles = {
  root: css({
    fontSize: '10px',
    padding: '2px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    width: BUTTON_WIDTH,
    height: BUTTON_HEIGHT,
    margin: 4,
    textAlign: 'center',
    outline: 'none',
    color: 'black',
    backgroundColor: 'red',
    border: '2px solid rgb(255, 100, 100)',
    borderBottom: '2px solid rgb(200, 45, 45)',
    borderRight: '2px solid rgb(200, 45, 45)',
    position: 'relative',
    '&:disabled': {
      backgroundColor: 'rgb(140, 140, 140)',
      border: '2px solid rgb(170, 170, 170)',
      borderBottom: '2px solid rgb(110, 110, 110)',
      borderRight: '2px solid rgb(110, 110, 110)',
    },
    '&:hover .cant-submit-message': {
      visibility: 'visible',
    },
  }),
  active: css({
    border: '2px solid rgb(200, 45, 45)',
    borderBottom: '2px solid rgb(255, 100, 100)',
    borderRight: '2px solid rgb(255, 100, 100)',
  }),
  ready: css({
    padding: 2,
    fontSize: 10,
    marginLeft: 10,
    backgroundColor: 'rgb(100, 170, 100)',
    border: 'none',
    height: '15px',
    lineHeight: '15px',
    width: '110px',
    color: 'black',
    textAlign: 'center',
    // boxShadow: 'inset 0px 0px 3px 2px black',
    borderRadius: 4,
  }),
  cantSubmitMessage: css({
    visibility: 'hidden',
    padding: 4,
    fontSize: 14,
    marginTop: 8,
    color: 'rgb(135, 135, 135)',
    backgroundColor: 'rgba(0, 0, 0, 1)',
  }),
};

function SkipAnimationButton(props: { onClick?: () => void }) {
  return (
    <button css={SkipAnimationButtonStyles.root} onClick={props.onClick}>
      <img src={LoadingSpinner} css={SkipAnimationButtonStyles.spinner} /> SKIP
    </button>
  );
}
const SkipAnimationButtonStyles = {
  root: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    width: BUTTON_WIDTH,
    height: BUTTON_HEIGHT,

    margin: 4,
    textAlign: 'center',
    outline: 'none',
    color: 'black',
  }),
  spinner: css({
    height: 16,
    width: 'auto',
    marginRight: 4,
  }),
};
