/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';
import { CardWithID, TributeCardWithID } from '@mythos/game/Rules';
import { CardBack, CardBackView } from 'CardBackView';
import CardView from 'CardView';
import { EmptyCard, EmptyCardView } from 'EmptyCardView';
import invariant from 'invariant';
import React, { Ref, useCallback } from 'react';
import TributeCardView from 'TributeCardView';
import HoverCardStore from './HoverCardStore';

type CardEventFunc<TCard> = (card: TCard, e: any) => void;
type BaseCard = { id: string };
type CardRenderer<TCard> = (props: {
  card: TCard;
  ref?: Ref<HTMLDivElement>;
}) => React.ReactElement;
interface Props<TCard extends BaseCard> {
  cards: TCard[];
  renderer: CardRenderer<TCard>;
  hoverRenderer: CardRenderer<TCard>;
  className?: string;
  highlightColorByCardID?: { [k: string]: string };
  onCardClick?: CardEventFunc<TCard>;
  onCardContextMenu?: CardEventFunc<TCard>;
  cardIDToAnimation?: Map<string, HTMLDivElement>;
  cardIndexToAnimation?: Map<number, HTMLDivElement>;
}

export type DeckCard = CardBack & {
  id: `deck${string}`;
};

export default function PileView<TCard extends BaseCard>(props: Props<TCard>) {
  let { cards, highlightColorByCardID } = props;
  highlightColorByCardID = highlightColorByCardID || {};

  const onCardEnter = useCallback(
    (card: TCard, _e: any) => {
      const cardToHover = { ...card };

      HoverCardStore.setPosition('bottom-right');
      HoverCardStore.setCard(cardToHover, props.hoverRenderer);
    },
    [props.hoverRenderer],
  );
  const onCardLeave = useCallback((_card: TCard, _e: any) => {
    HoverCardStore.setCard(null, null);
  }, []);

  let rendered_cards = cards.map((card, i) => {
    invariant(!!card, 'falsey card in pile %s', card);
    let highlight_color = highlightColorByCardID[card.id];
    let overlay_style = {
      boxShadow: highlight_color ? `0px 0px 12px 4px ${highlight_color}` : null,
    };
    let card_props = {
      card: card,
      key: card.id,
      onLeftClick: props.onCardClick,
      onRightClick: props.onCardContextMenu,
      onCardEnter: onCardEnter,
      onCardLeave: onCardLeave,
      overlayStyle: overlay_style,
      overlayCSS: CardOverlayStyle,
      ref: (node: HTMLDivElement) => {
        if (node) {
          // console.log('setting card node', card.id);
          props.cardIDToAnimation?.set(card.id, node);
          props.cardIndexToAnimation?.set(i, node);
        } else {
          // console.log('removing card node', card.id);
          props.cardIDToAnimation?.delete(card.id);
          props.cardIndexToAnimation?.delete(i);
        }
      },
    };

    var rendered_card = (
      <div css={PileViewStyles.wrapper} key={card.id}>
        {props.renderer(card_props as any)}
      </div>
    );

    return rendered_card;
  });
  return (
    <div css={PileViewStyles.container} className={props.className}>
      {rendered_cards}
    </div>
  );
}

export type PileCard = EmptyCard | DeckCard | CardWithID;
export const PileCardRenderer: CardRenderer<PileCard> = (props) => {
  const card = props.card;
  if (card.id.startsWith('empty')) {
    // @ts-ignore
    return <EmptyCardView {...props} />;
  } else if (card.id.startsWith('deck')) {
    // @ts-ignore
    return <CardBackView {...props} />;
  }
  // @ts-ignore
  return <CardView {...props} />;
};
export type PileTributeCard = EmptyCard | CardBack | TributeCardWithID;
export const PileTributeCardRenderer: CardRenderer<PileTributeCard> = (
  props,
) => {
  if (props.card.id.startsWith('empty')) {
    // @ts-ignore
    return <EmptyCardView {...props} />;
  }
  if (props.card.id.startsWith('deck')) {
    // @ts-ignore
    return <CardBackView {...props} />;
  }
  // @ts-ignore
  return <TributeCardView {...props} />;
};

const CardOverlayStyle = css({
  '@media (hover: hover)': {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
  },
});

const PileViewStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  }),
  wrapper: css({
    display: 'inline-block',

    padding: 4,

    borderTopWidth: '2px',
    borderTopStyle: 'solid',
    borderTopColor: 'transparent',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
  }),
};
