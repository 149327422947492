import Game, { InflatedGame } from '@mythos/game/Game';
import { GameDefinitions } from '@mythos/game/GameModel';
import { List, Map } from 'immutable';
import { AnyAction, combineReducers } from 'redux';
import * as _ from 'underscore';
import Session from '../common/utils/Session';
import {
  ADD_CHANNEL_SUBSCRIPTION,
  ADD_CHAT_MESSAGE,
  ChatMessage,
  REMOVE_CHANNEL_SUBSCRIPTION,
  SET_SESSION,
  STORE_GAME,
  STORE_GAME_DEFINITIONS,
  STORE_LOBBY,
  STORE_LOBBY_LIST,
  STORE_USERS,
} from './actions';

import { User } from './User';

type Lobby = any;

function session(
  state: Session | null = null,
  action: { type: typeof SET_SESSION; session: Session | null } | AnyAction,
) {
  switch (action.type) {
    case SET_SESSION:
      return action.session;
    default:
      return state;
  }
}

function lobbyByID(
  state = Map<string, Lobby>(),
  action: { type: typeof STORE_LOBBY; lobby: any } | AnyAction,
) {
  switch (action.type) {
    case STORE_LOBBY:
      const { lobby } = action;
      return state.set(lobby.id, lobby);
    default:
      return state;
  }
}

function lobbyList(
  state = null,
  action: { type: typeof STORE_LOBBY_LIST; lobby_list: any } | AnyAction,
) {
  switch (action.type) {
    case STORE_LOBBY_LIST:
      return action.lobby_list;
    default:
      return state;
  }
}

function gameDefinitions(
  state = null,
  action:
    | {
        type: typeof STORE_GAME_DEFINITIONS;
        game_definitions: GameDefinitions | null;
      }
    | AnyAction,
) {
  switch (action.type) {
    case STORE_GAME_DEFINITIONS:
      return action.game_definitions;
    default:
      return state;
  }
}

function userByID(
  state = Map<string, User>(),
  action: { type: typeof STORE_USERS; users: User[] } | AnyAction,
) {
  switch (action.type) {
    case STORE_USERS:
      return state.withMutations((map) => {
        _.each(action.users, (user: User) => {
          map.set(user.id, user);
        });
      });
    default:
      return state;
  }
}

function gameByID(
  state = Map<string, InflatedGame>(),
  action: { type: typeof STORE_GAME; game: any } | AnyAction,
) {
  switch (action.type) {
    case STORE_GAME: {
      let { game } = action;
      if (!game) {
        return state;
      }
      const existingGame = state.get(game.id);
      if (existingGame && existingGame.sequenceID >= game.sequenceID) {
        return state;
      }

      game = Game.inflateGame(game);

      return state.set(game.id, game);
    }
    default:
      return state;
  }
}

function channelSubscriptions(
  state = List(),
  action:
    | { type: typeof ADD_CHANNEL_SUBSCRIPTION; subscription: any }
    | { type: typeof REMOVE_CHANNEL_SUBSCRIPTION; subscription: any }
    | AnyAction,
) {
  switch (action.type) {
    case ADD_CHANNEL_SUBSCRIPTION:
      return state.push(action.subscription);
    case REMOVE_CHANNEL_SUBSCRIPTION: {
      let index = state.indexOf(action.subscription);
      if (index !== -1) {
        return state.remove(index);
      }
      return state;
    }
    default:
      return state;
  }
}

function chatMessagesByRoom(
  state: Map<string, ChatMessage[]> = Map(),
  action: { type: typeof ADD_CHAT_MESSAGE; message: ChatMessage } | AnyAction,
) {
  if (action.type === ADD_CHAT_MESSAGE) {
    return state.set(
      action.message.room,
      state.get(action.message.room, []).concat(action.message),
    );
  }
  return state;
}

export default combineReducers({
  session,
  lobbyByID,
  lobbyList,
  userByID,
  gameByID,
  channelSubscriptions,
  gameDefinitions,
  chatMessagesByRoom,
});
