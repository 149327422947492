/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/react';
import React from 'react';

import { useMobile } from 'MobileContext';

type Card = any;
export type HoverCardRenderer = (args: { card: Card }) => React.ReactElement;
export type HoverCardListener = (card: Card | null) => void;

// Offset means it is offset from the logview
export type HoverCardPosition =
  | 'bottom-right-offset'
  | 'bottom-right'
  | 'top-right-offset';

class HoverCardStore {
  private hoveredCard_: Card | null = null;
  private mouseX_: number | null = null;
  private mouseY_: number | null = null;
  private listeners_: HoverCardListener[] = [];
  private position_: HoverCardPosition = 'bottom-right';
  private renderer_: HoverCardRenderer | null = null;
  constructor() {}
  // a listener is a function of 1 argument, a Card
  addListener(listener: HoverCardListener): void {
    this.listeners_.push(listener);
    listener(null);
  }
  removeListener(listener: HoverCardListener): void {
    this.listeners_ = this.listeners_.filter((x) => x !== listener);
  }
  getHoveredCard(): Card | null {
    return this.hoveredCard_;
  }
  getRenderer(): HoverCardRenderer | null {
    return this.renderer_;
  }
  getHoverCardComponent(): React.ReactElement {
    return <HoverCard scale={2.5} store={this} />;
  }
  getMouseX(): number | null {
    return this.mouseX_;
  }
  getMouseY(): number | null {
    return this.mouseY_;
  }

  // internal
  setCard(card: Card | null, renderer: HoverCardRenderer | null): void {
    this.hoveredCard_ = card;
    this.renderer_ = renderer;
    if (card === null) {
      this.mouseX_ = null;
      this.mouseY_ = null;
    }
    this.notifyListeners_();
  }
  setMousePosition(x: number, y: number): void {
    this.mouseX_ = x;
    this.mouseY_ = y;
    this.notifyListeners_();
  }
  notifyListeners_(): void {
    this.listeners_.forEach((listener) => {
      listener(this.hoveredCard_);
    });
  }
  setPosition(position: HoverCardPosition): void {
    this.position_ = position;
  }
  getPosition(): HoverCardPosition {
    return this.position_;
  }
}

interface Props {
  store: HoverCardStore;
  scale: number;
}
const HoverCard: React.FC<Props> = ({ store, scale }) => {
  const [, forceUpdate] = React.useState({});
  const isMobile = useMobile();

  const onHoverCardChange_ = React.useCallback(() => {
    forceUpdate({});
  }, []);

  React.useEffect(() => {
    store.addListener(onHoverCardChange_);
    return () => {
      store.removeListener(onHoverCardChange_);
    };
  }, [store, onHoverCardChange_]);

  // card is 83 x 110px @ 1x
  const card = store.getHoveredCard();
  const renderer = store.getRenderer();
  const rendered_card = card && renderer ? renderer({ card: card }) : null;
  const xpos = store.getMouseX();
  const ypos = store.getMouseY();
  const finalScale = scale ?? 1;

  let positionStyle: SerializedStyles | null = null;
  let style: React.CSSProperties;
  if (isMobile || matchMedia('(hover: none)').matches) {
    style = {
      transform: 'translate(-50%, -50%)',
      left: '50%',
      top: '50%',
      zoom: finalScale,
    };
    positionStyle = null;
  } else if (xpos !== null && ypos !== null) {
    style = {
      transform: 'translate(-50%, -50%)',
      left: xpos / finalScale, //xpos; //Math.max(0, xpos + 41.5 * scale);
      top: ypos / finalScale, //ypos; //Math.max(0, ypos + 55 * scale);
      zoom: finalScale,
    };
    positionStyle = null;
  } else {
    positionStyle = HoverCardPositionStyles[store.getPosition()];
    style = {
      transform: `scale(${finalScale})`,
    };
  }

  return (
    <div css={[HoverCardStyles.hoverCard, positionStyle]} style={style}>
      {rendered_card}
    </div>
  );
};

const HoverCardStyles = {
  hoverCard: css({
    position: 'fixed',
    zIndex: 1000,
    pointerEvents: 'none',
  }),
};
const HoverCardPositionStyles: Record<HoverCardPosition, SerializedStyles> = {
  'bottom-right-offset': css({
    bottom: '25%',
    right: 450,
  }),
  'bottom-right': css({
    right: '9%',
    bottom: '25%',
  }),
  'top-right-offset': css({
    top: '10%',
    right: 375,
  }),
};

export default new HoverCardStore();
