import { v4 as uuidv4 } from 'uuid';

import { getChannelListener } from './ChannelListener';

import { InflatedGame } from '@mythos/game/Game';
import { GameDefinitions } from '@mythos/game/GameModel';
import { Dispatch } from 'react';
import Session from '../common/utils/Session';
import {
  lobbyListQuery,
  lobbyQuery,
  makeGraphQLQuery,
  usersQuery,
} from './graphql_queries';

export const SET_SESSION = 'SET_SESSION';
export const STORE_LOBBY = 'SET_LOBBY';
export const STORE_LOBBY_LIST = 'SET_LOBBY_LIST';
export const STORE_GAME_DEFINITIONS = 'STORE_GAME_DEFINITIONS';
export const STORE_USERS = 'STORE_USERS';
export const STORE_GAME = 'STORE_GAME';
export const ADD_CHANNEL_SUBSCRIPTION = 'ADD_CHANNEL_SUBSCRIPTION';
export const REMOVE_CHANNEL_SUBSCRIPTION = 'REMOVE_CHANNEL_SUBSCRIPTION';
export const ADD_CHAT_MESSAGE = 'ADD_CHAT_MESSAGE';

export function setSession(session: Session | null) {
  return {
    type: SET_SESSION,
    session,
  };
}

export function storeLobby(lobby: any) {
  return {
    type: STORE_LOBBY,
    lobby,
  };
}

export function storeLobbyList(lobby_list: any) {
  return {
    type: STORE_LOBBY_LIST,
    lobby_list,
  };
}

export function storeGameDefinitions(game_definitions: GameDefinitions) {
  return {
    type: STORE_GAME_DEFINITIONS,
    game_definitions,
  };
}

export function storeUsers(users: any[]) {
  return {
    type: STORE_USERS,
    users,
  };
}

export function storeGame(game: InflatedGame) {
  return {
    type: STORE_GAME,
    game,
  };
}

export type ChatMessage = {
  senderID: string;
  room: string;
  message: string;
};
export function addChatMessage(message: ChatMessage) {
  return {
    type: ADD_CHAT_MESSAGE,
    message,
  };
}

type ChannelSubscription = {
  id: string;
  topic: string;
  callback: (message: any) => void;
};

export function receivedChannelMessage(message: any) {
  return (dispatch: Dispatch<any>, getState: () => any) => {
    if (message.type === 'chat') {
      return dispatch(
        addChatMessage({
          senderID: message.userID,
          room: message.room,
          message: message.message,
        }),
      );
    }
    const subscriptions = getState().channelSubscriptions;
    subscriptions.forEach((sub: ChannelSubscription) => {
      if (sub.topic === message.topic) {
        sub.callback(message);
      }
    });
  };
}

export function fetchLobby(id: string, sequence_id: number) {
  return (dispatch: Dispatch<any>, getState: () => any) => {
    const state = getState();
    const existing_lobby = state.lobbyByID.get(id);
    sequence_id = sequence_id || 0;
    if (existing_lobby && existing_lobby.sequenceID >= sequence_id) {
      return Promise.resolve();
    }

    return makeGraphQLQuery(lobbyQuery, { id }).then((data) => {
      const { lobby } = data;
      if (lobby) {
        dispatch(storeLobby(lobby));
      }
    });
  };
}

export function fetchLobbyList(sequence_id?: number) {
  return (dispatch: Dispatch<any>, getState: () => any) => {
    return makeGraphQLQuery(lobbyListQuery).then((data) => {
      const lobby_list = data.lobby_list;
      return dispatch(storeLobbyList(lobby_list));
    });
  };
}

export function fetchGameDefinitions() {
  return (dispatch: Dispatch<any>, getState: () => any) => {
    if (getState().gameDefinitions) {
      return;
    }
    return fetch('/api/game_definitions')
      .then((response) => {
        return response.json();
      })
      .then((game_definitions) => {
        dispatch(storeGameDefinitions(game_definitions));
      });
  };
}

export function fetchUsers(user_ids: string[], force = false) {
  return (dispatch: Dispatch<any>, getState: () => any) => {
    let userIDsToFetch = user_ids;
    if (!force) {
      userIDsToFetch = userIDsToFetch.filter(
        (user_id) => !getState().userByID.get(user_id),
      );
    }
    let query = usersQuery(userIDsToFetch);
    return makeGraphQLQuery(query).then((data) => {
      return dispatch(storeUsers(data.users));
    });
  };
}

export function fetchGame(game_id: string, sequence_id?: number | null) {
  return (dispatch: Dispatch<any>, getState: () => any) => {
    const state = getState();
    const existing = state.gameByID.get(game_id);
    sequence_id = sequence_id || 0;
    if (existing && existing.sequenceID >= sequence_id) {
      return Promise.resolve();
    }

    return fetch(`/api/game/${game_id}/state`, { credentials: 'same-origin' })
      .then((response) => {
        return response.json();
      })
      .then((game) => {
        dispatch(storeGame(game));
      });
  };
}

export function addChannelSubscription(subscription: ChannelSubscription) {
  return {
    type: ADD_CHANNEL_SUBSCRIPTION,
    subscription,
  };
}
export function removeChannelSubscription(subscription: ChannelSubscription) {
  return {
    type: REMOVE_CHANNEL_SUBSCRIPTION,
    subscription,
  };
}

export function subscribeToChannelTopic(
  topic: string,
  callback: (message: any) => void,
) {
  return (dispatch: Dispatch<any>) => {
    let id = uuidv4();
    let subscription = { id, topic, callback };

    getChannelListener().subscribeToTopic(topic);

    dispatch(addChannelSubscription(subscription));
    return subscription;
  };
}

export function unsubscribeToChannelTopic(subscription: ChannelSubscription) {
  return (dispatch: Dispatch<any>) => {
    dispatch(removeChannelSubscription(subscription));
    getChannelListener().unsubscribeToTopic(subscription.topic);
  };
}
