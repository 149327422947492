const user_summary_fragment = `
fragment UserSummaryFragment on User {
  id
  name
  profilePictureURL
  stats {
    totalWins
    totalGames
    totalBlameTime
  }
}`;

export const lobbyListQuery = `
{
  lobby_list {
    id
    name
    host {
      ...UserSummaryFragment
    }
    creationDate
    status
    game {
      id
      endTimestamp
    }
    players {
      edges {
        node {
          ...UserSummaryFragment
        }
      }
    }
  }
}
${user_summary_fragment}
`;

export const lobbyQuery = `
query getLobby($id: String!) {
  lobby (id: $id) {
    id
    sequenceID
    name
    options
    modules
    host {
      ...UserSummaryFragment
    }
    players {
      edges {
        node {
          ...UserSummaryFragment
        }
      }
    }
    playerIDToBotType {
      playerID
      botType
    }
    game {
      id
    }
  }
}
${user_summary_fragment}
`;

export function usersQuery(user_ids: string[]) {
  let pieces = user_ids.map((user_id) => {
    return `"${user_id}"`;
  });
  return `
{
  users(ids: [${pieces.join(',')}]) {
    ...UserSummaryFragment
  }
}
${user_summary_fragment}
`;
}

export function makeGraphQLQuery(query: string, params?: {}) {
  let param_string = '';
  if (params) {
    let encoded_params = JSON.stringify(params);
    param_string = `&variables=${encoded_params}`;
  }
  return fetch(`/api/graphql?query=${query}${param_string}`, {
    headers: {
      Accept: 'application/json',
    },
    credentials: 'same-origin',
  })
    .then((response) => response.json())
    .then((result) => {
      if (result.data) {
        return result.data;
      }
      console.warn(
        'error running graphql query',
        result.errors.map((err: any) => err.message),
        result.errors,
        { query },
      );
      return Promise.reject(result.errors);
    });
}
