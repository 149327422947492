import { css } from '@emotion/react';

export const MStyles = {
  noSelect: css({
    userSelect: 'none',
  }),
  touchableMatch: css({
    [`@media (hover: none)`]: {
      touchAction: 'none',
      userSelect: 'none',
    },
  }),
  touchable: css({
    touchAction: 'none',
    userSelect: 'none',
  }),

  frcc: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};

export const MEDIA_QUERY_MOBILE = '@media (max-width: 768px)';
