/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import { MEDIA_QUERY_MOBILE } from 'mobile_ui/MStyles';
import LogoBannerImg from '../assets/logo_banner.svg';

export default function LogoBanner(props: {}) {
  return (
    <div css={LogoBannerStyles.container}>
      <img css={LogoBannerStyles.banner} src={LogoBannerImg} />
    </div>
  );
}

const LogoBannerStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  }),
  banner: css({
    alignSelf: 'center',
    width: 700,

    margin: '20 0 20 0',

    [MEDIA_QUERY_MOBILE]: {
      maxWidth: '100%',
      margin: '5 0 0 0',
    },
  }),
};
