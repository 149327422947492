/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';

import { InflatedGame } from '@mythos/game/Game';
import { AGES_PER_GAME } from '@mythos/game/GameConstants';
import { getPhaseName, PhaseToBackgroundColor } from '@mythos/game/Phases';
import { ageString } from '@mythos/game/Utility';
import GameTimeView from './GameTimeView';
import { PhaseToSymbol, SymbolView } from './Symbols';

export default function GameStateView(props: { game: InflatedGame }) {
  const { game } = props;

  const backgroundColor = PhaseToBackgroundColor[game.phase];

  return (
    <div
      css={GameStateStyles.container}
      style={{
        backgroundColor,
      }}
    >
      <span css={GameStateStyles.sub_container}>
        <span css={GameStateStyles.span}>
          <SymbolView
            symbol={PhaseToSymbol[game.phase]}
            css={GameStateStyles.symbol}
          />
        </span>
        <span css={GameStateStyles.span}>{getPhaseName(game.phase)}</span>
      </span>
      <span css={GameStateStyles.sub_container}>
        <span css={GameStateStyles.span}>
          Turn {game.turn}/{game.turnsPerAge}
        </span>
        <span css={GameStateStyles.span}>
          Age {ageString(game.age)}/{ageString(AGES_PER_GAME)}
        </span>
        <span css={GameStateStyles.span}>
          <GameTimeView
            splitStartTimestamp={
              game.gameEndTimestamp ? undefined : game.gameStartTimestamp!
            }
            extraSplitTime={
              game.gameEndTimestamp
                ? game.gameEndTimestamp - game.gameStartTimestamp!
                : undefined
            }
          />
        </span>
      </span>
    </div>
  );
}

const GameStateStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    margin: 5,
    padding: 5,

    color: 'rgb(215, 215, 215)',

    transition: 'background-color 0.5s',
  }),
  sub_container: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  span: css({
    marginLeft: 10,
    marginRight: 10,
  }),
  symbol: css({
    width: 25,
    height: 25,
    margin: 0,
  }),
} as const;
