import _ from 'underscore';

import { CardSetTypes } from '@mythos/game/CardTypes';
import { CardWithID } from '@mythos/game/Rules';

const VERBOSE = false;

export function countBoardSets(board: CardWithID[]) {
  var counts = _.countBy(board, (card) => {
    return card.type;
  });
  return _.min(
    CardSetTypes.map((type) => {
      return counts[type] || 0;
    }),
  );
}

export interface CounterDelta {
  gold: number;
  favor: number;
  military: number;
  warTokens: number;
  rerollTokens: number;
  age1tokens: number;
  age2tokens: number;
  age3tokens: number;
}
export function makeCounterDelta(): CounterDelta {
  return {
    gold: 0,
    favor: 0,
    military: 0,
    warTokens: 0,
    rerollTokens: 0,
    age1tokens: 0,
    age2tokens: 0,
    age3tokens: 0,
  };
}

export function applyCounterDelta(
  target: CounterDelta,
  delta: Partial<CounterDelta>,
): CounterDelta {
  target.gold += delta.gold || 0;
  target.military += delta.military || 0;
  target.favor += delta.favor || 0;
  target.warTokens += delta.warTokens || 0;
  target.rerollTokens += delta.rerollTokens || 0;
  target.age1tokens += delta.age1tokens || 0;
  target.age2tokens += delta.age2tokens || 0;
  target.age3tokens += delta.age3tokens || 0;
  return target;
}

export function addCounters(
  counters: CounterDelta,
  other: CounterDelta,
): CounterDelta {
  const output = { ...counters };
  return applyCounterDelta(output, other);
}

export function multiplyCounters(
  counters: CounterDelta,
  factor: number,
): CounterDelta {
  const output = { ...counters };
  output.gold *= factor;
  output.military *= factor;
  output.favor *= factor;
  output.warTokens *= factor;
  output.rerollTokens *= factor;
  output.age1tokens *= factor;
  output.age2tokens *= factor;
  output.age3tokens *= factor;
  return output;
}

export function subtractCounterDeltas(
  delta1: CounterDelta,
  delta2: CounterDelta,
): CounterDelta {
  const output = { ...delta1 };
  output.gold -= delta2.gold;
  output.military -= delta2.military;
  output.favor -= delta2.favor;
  output.warTokens -= delta2.warTokens;
  output.rerollTokens -= delta2.rerollTokens;
  output.age1tokens -= delta2.age1tokens;
  output.age2tokens -= delta2.age2tokens;
  output.age3tokens -= delta2.age3tokens;
  return output;
}

export function isZeroCounterDelta(counters: CounterDelta): boolean {
  return (
    counters.gold === 0 &&
    counters.military === 0 &&
    counters.favor === 0 &&
    counters.warTokens === 0 &&
    counters.rerollTokens === 0 &&
    counters.age1tokens === 0 &&
    counters.age2tokens === 0 &&
    counters.age3tokens === 0
  );
}

export function countIf<T>(list: Iterable<T>, iterator: (v: T) => boolean) {
  let count = 0;
  for (const v of list) {
    if (iterator(v)) {
      count++;
    }
  }
  return count;
}

export function sum<T>(list: Iterable<T>, iterator: (v: T) => number) {
  let sum = 0;
  for (const v of list) {
    sum += iterator(v);
  }
  return sum;
}

export function ageString(age: number): string {
  return 'I'.repeat(age);
}

export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export function debug_log(...content: any[]) {
  if (!VERBOSE) {
    return;
  }
  console.log(...content);
}
