/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';
import {
  computeUpgradeInfo,
  endOfGameEffectsFavor,
  totalProductionCounters,
} from '@mythos/game/AIUtilities';
import {
  AbilityOutput,
  getEndOfGameCardAbility,
  upgradeConditionMet,
} from '@mythos/game/CardAbilities';
import { CardType } from '@mythos/game/CardTypes';
import { InflatedGame, InflatedPlayer } from '@mythos/game/Game';
import { computeBaseCounters, makeContextFromGame } from '@mythos/game/Rules';
import { nonNull } from '@mythos/utils/utils';
import invariant from 'invariant';
import nullthrows from 'nullthrows';
import React, { useState } from 'react';
import { CardCountsView } from '../CardCountsView';
import { EffectPill } from '../CardHelpers';
import { CardEffect, UpgradeCondition } from '../CardView';
import PlayerBoardView from '../PlayerBoardView';
import { PlayerGameTimeView } from '../PlayerSummaryView';
import Symbols, {
  BaseResourceToSymbol,
  ResourceToSymbol,
  SymbolView,
} from '../Symbols';
import { UserWithColor } from '../User';
import { useLongPress } from './MButton';
import { MColors } from './MColors';
import { MStyles } from './MStyles';
import { MModal, MSymbolPrefix } from './MUIComponents';

export default function MPlayerSummaryView(props: {
  player: InflatedPlayer;
  game: InflatedGame;
  user: UserWithColor;
  ready: boolean;
  isWinning: boolean;
  isSessionPlayer: boolean;

  onShortPress?: () => void;
  onLongPress?: () => void;
  onLongPressEnd?: () => void;
}) {
  const { player, game, isSessionPlayer, isWinning, ready } = props;

  const longPressProps = useLongPress(props);

  const context = makeContextFromGame(player, game);
  const base_counts = computeBaseCounters(context);
  const production_counts = totalProductionCounters(context);

  const profile_picture = (
    <img
      src={props.user.profilePictureURL}
      css={MPlayerSummaryViewStyles.profileSymbol}
    />
  );

  return (
    <div css={MPlayerSummaryViewStyles.container} {...longPressProps}>
      <div css={MPlayerSummaryViewStyles.playerInfoContainer}>
        <div css={MPlayerSummaryViewStyles.playerInfoRow}>
          {profile_picture}
          <div
            css={MPlayerSummaryViewStyles.name}
            style={{
              color: props.user.color,
            }}
          >
            {props.user.name}
          </div>
        </div>
        <div css={MPlayerSummaryViewStyles.playerInfoRow}>
          <div
            css={[
              MPlayerSummaryViewStyles.readySymbol,
              ready
                ? MPlayerSummaryViewStyles.readySymbolReady
                : MPlayerSummaryViewStyles.readySymbolNotReady,
            ]}
          />
          <PlayerGameTimeView
            css={MPlayerSummaryViewStyles.time}
            game={game}
            player={player}
            isReady={ready}
            condensed={true}
          />
        </div>
      </div>
      <div
        css={MPlayerSummaryViewStyles.favorContainer}
        style={
          (isWinning && {
            backgroundColor: 'rgb(219, 197, 136)',
            borderRadius: 4,
          }) ||
          undefined
        }
      >
        {player.counters.favor}
        <SymbolView
          symbol={Symbols.FAVOR}
          css={MPlayerSummaryViewStyles.favorSymbol}
        />
      </div>
      <MPlayerResourceCounter
        resource="gold"
        count={player.counters.gold}
        baseCount={base_counts.gold}
        productionCount={production_counts.gold}
      />
      <MPlayerResourceCounter
        resource="military"
        count={player.counters.military}
        baseCount={base_counts.military}
        productionCount={production_counts.military}
      />
      <CardCountsView player={player} game={game} />
    </div>
  );
}

function MPlayerResourceCounter(props: {
  resource: 'gold' | 'military';
  count: number;
  baseCount: number;
  productionCount: number;
}) {
  const baseResourceSymbol = nullthrows(BaseResourceToSymbol[props.resource]);
  return (
    <div css={MPlayerSummaryViewStyles.resourceContainer}>
      <div css={MPlayerSummaryViewStyles.resourceCount}>
        {props.count + props.baseCount}
      </div>
      <div css={MPlayerSummaryViewStyles.resourceSymbolContainer}>
        <SymbolView
          symbol={ResourceToSymbol[props.resource]}
          css={MPlayerSummaryViewStyles.resourceSymbol}
        />
        <SymbolView
          symbol={baseResourceSymbol}
          css={MPlayerSummaryViewStyles.baseSymbol}
        />
      </div>
      <div css={MPlayerSummaryViewStyles.resourceCountDetailsContainer}>
        <div css={MPlayerSummaryViewStyles.productionCount}>
          <SymbolView
            symbol={baseResourceSymbol}
            css={MPlayerSummaryViewStyles.productionSymbol}
          />
          {props.baseCount}
        </div>
        <div css={MPlayerSummaryViewStyles.productionCount}>
          <SymbolView
            symbol={Symbols.EFFECT_PRODUCTION}
            css={MPlayerSummaryViewStyles.productionSymbol}
          />
          {props.productionCount}
        </div>
      </div>
    </div>
  );
}

const MPlayerSummaryViewStyles = {
  container: css(
    {
      display: 'grid',
      gridTemplateColumns:
        'minmax(0,auto) min-content min-content min-content min-content',
      gap: 2,

      backgroundColor: 'white',

      boxSizing: 'border-box',
      height: 50,
      maxWidth: '100%',
      width: '100%',
      // paddingLeft: 2,
      paddingRight: 2,
    },
    MStyles.touchable,
  ),

  playerInfoContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',

    flexGrow: 1,
    flexShrink: 1,
  }),
  playerInfoRow: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 2,
    flexShrink: 1,
  }),
  profileSymbol: css({
    height: 25,
    width: 25,
  }),
  readySymbol: css({
    height: 19,
    width: 19,
    marginLeft: 3,
    marginRight: 3,
    borderRadius: 2,
  }),
  readySymbolReady: css({
    backgroundColor: 'green',
  }),
  readySymbolNotReady: css({
    backgroundColor: 'red',
  }),
  name: css({
    fontSize: 16,
    fontWeight: 'bold',
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  favorContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,

    alignSelf: 'center',
    paddingTop: 4,
    paddingBottom: 4,

    minWidth: 44,
    flexGrow: 0,
    flexShrink: 0,

    fontSize: 20,
  }),
  favorSymbol: css({
    width: 18,
  }),

  resourceContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'stretch',

    gap: 2,
    flexShrink: 0,
  }),
  resourceCount: css({
    textAlign: 'end',
    verticalAlign: 'middle',
    fontSize: 18,
    width: 20,
  }),
  resourceSymbolContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
    justifyContent: 'start',
    flexShrink: 0,
  }),
  resourceSymbol: css({
    height: 18,
    width: 18,
  }),
  baseSymbol: css({
    marginLeft: -5,
    height: 9,
    width: 9,
  }),
  resourceCountDetailsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
    gap: 2,
    width: 22,
  }),
  productionSymbol: css({
    height: 10,
    width: 10,
  }),
  productionCount: css({
    fontSize: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    gap: 1,
  }),
  time: css({
    fontSize: 12,
  }),
};

const MPlayerDetailsView = (props: {
  player: InflatedPlayer;
  game: InflatedGame;
  user: UserWithColor;
}) => {
  const { player, game, user } = props;

  const context = makeContextFromGame(player, game);
  const eog_favor = endOfGameEffectsFavor(context);

  return (
    <div css={MPlayerDetailsStyles.container}>
      <div css={MPlayerDetailsStyles.counterInfoSection}>
        <div css={MPlayerDetailsStyles.favorSection}>
          <MSymbolPrefix
            symbol={Symbols.FAVOR}
            symbolCss={MPlayerDetailsStyles.favorSymbol}
          >
            {player.counters.favor}
          </MSymbolPrefix>
          <MSymbolPrefix
            css={MPlayerDetailsStyles.eogFavor}
            symbol={Symbols.EOG}
            symbolCss={MPlayerDetailsStyles.eogSymbol}
          >
            {eog_favor.favor}
          </MSymbolPrefix>
        </div>
        <MPlayerDetailsResourceSection player={player} game={game} />
        <CardCountsView player={player} game={game} />
      </div>
      <MPropheciesView player={player} game={game} />
      <MAvailableUpgradesView player={player} game={game} />
      <PlayerBoardView player={player} game={game} user={user} />
    </div>
  );
};

const MPlayerDetailsResourceSection = (props: {
  player: InflatedPlayer;
  game: InflatedGame;
}) => {
  const { player, game } = props;

  const context = makeContextFromGame(player, game);
  const base_counts = computeBaseCounters(context);
  const production_counts = totalProductionCounters(context);
  return (
    <div css={MPlayerDetailsStyles.resourceSection}>
      <MSymbolPrefix
        symbol={ResourceToSymbol.gold}
        symbolCss={MPlayerDetailsStyles.resourceSymbol}
      >
        {player.counters.gold}
      </MSymbolPrefix>
      <MSymbolPrefix
        symbol={BaseResourceToSymbol.gold!}
        symbolCss={MPlayerDetailsStyles.resourceSymbol}
      >
        {base_counts.gold}
      </MSymbolPrefix>
      <MSymbolPrefix
        symbol={Symbols.EFFECT_PRODUCTION}
        symbolCss={MPlayerDetailsStyles.productionSymbol}
      >
        {production_counts.gold}
      </MSymbolPrefix>
      <MSymbolPrefix
        symbol={ResourceToSymbol.military}
        symbolCss={MPlayerDetailsStyles.resourceSymbol}
      >
        {player.counters.military}
      </MSymbolPrefix>
      <MSymbolPrefix
        symbol={BaseResourceToSymbol.military!}
        symbolCss={MPlayerDetailsStyles.resourceSymbol}
      >
        {base_counts.military}
      </MSymbolPrefix>
      <MSymbolPrefix
        symbol={Symbols.EFFECT_PRODUCTION}
        symbolCss={MPlayerDetailsStyles.productionSymbol}
      >
        {production_counts.military}
      </MSymbolPrefix>
    </div>
  );
};
const MPlayerDetailsTitleView = (props: { user: UserWithColor }) => {
  const { user } = props;

  return (
    <div
      css={MPlayerDetailsStyles.titleContainer}
      style={{
        color: user.color,
      }}
    >
      <img
        src={user.profilePictureURL}
        css={MPlayerDetailsStyles.profileSymbol}
      />
      {user.name}
    </div>
  );
};

const MPlayerDetailsStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',

    backgroundColor: '#555555',
    // backgroundColor: 'white',
  }),
  counterInfoSection: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2,

    padding: 2,
    paddingLeft: 4,
    paddingRight: 4,
    zoom: 1.5,

    backgroundColor: 'white',
  }),
  favorSection: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
    fontSize: 20,
  }),
  favorSymbol: css({
    height: 20,
    width: 20,
  }),
  eogFavor: css({
    fontSize: 16,
  }),
  eogSymbol: css({
    height: 16,
    width: 16,
  }),
  resourceSection: css({
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridTemplateRows: '1fr 1fr',
    gap: 4,
  }),
  resourceCounter: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
  }),
  resourceSymbol: css({
    height: 16,
    width: 16,
  }),
  productionSymbol: css({
    height: 12,
    width: 12,
  }),
  titleContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
  }),
  profileSymbol: css({
    // margin: 2,
    height: 44,
    width: 44,
  }),
};

export function MPlayerSummaryViews(props: {
  game: InflatedGame;
  players: InflatedPlayer[];
  sessionPlayerID?: string;
  userByID: Map<string, UserWithColor>;
  readyByUserID: { [k: string]: boolean };
}) {
  const { game, players, sessionPlayerID, userByID, readyByUserID } = props;

  const [shownPlayerID, setShownPlayerID] = useState<string | null>(null);

  const session_player_index = players.findIndex((player) => {
    return player.userID === sessionPlayerID;
  });
  const sorted_players =
    session_player_index !== -1
      ? [
          ...players.slice(session_player_index),
          ...players.slice(0, session_player_index),
        ]
      : players;

  const max_favor = Math.max(...players.map((player) => player.counters.favor));

  const modalPlayer = game.players.find(
    (player) => player.userID === shownPlayerID,
  );

  return (
    <div css={MPlayerSummaryViewsStyles.container}>
      {sorted_players.map((player) => {
        return (
          <MPlayerSummaryView
            key={player.userID}
            game={game}
            player={player}
            user={userByID.get(player.userID)!}
            ready={readyByUserID[player.userID]}
            isWinning={max_favor === player.counters.favor && max_favor > 0}
            isSessionPlayer={player.userID === sessionPlayerID}
            onShortPress={() => {
              setShownPlayerID(player.userID);
            }}
            onLongPress={() => {
              setShownPlayerID(player.userID);
            }}
            onLongPressEnd={() => {
              setShownPlayerID(null);
            }}
          />
        );
      })}
      {modalPlayer && (
        <MModal
          title={
            <MPlayerDetailsTitleView user={userByID.get(modalPlayer.userID)!} />
          }
          show={modalPlayer !== null}
          onClose={() => {
            setShownPlayerID(null);
          }}
        >
          <MPlayerDetailsView
            player={modalPlayer}
            game={game}
            user={userByID.get(modalPlayer.userID)!}
          />
        </MModal>
      )}
    </div>
  );
}

const MPlayerDetailsSectionWithTitle = (props: {
  title: string;
  children?: React.ReactNode;
}) => {
  const { title, children } = props;
  return (
    <div css={MPlayerDetailsSectionWithTitleStyles.sectionContainer}>
      <div css={MPlayerDetailsSectionWithTitleStyles.sectionTitle}>{title}</div>
      {children}
    </div>
  );
};
const MPlayerDetailsSectionWithTitleStyles = {
  sectionContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 4,

    padding: 4,
    borderRadius: 4,
    margin: 4,

    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  }),
  sectionTitle: css({
    fontSize: 18,
    textAlign: 'center',

    color: MColors.whiteText,
  }),
};

const MPlayerSummaryViewsStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  }),
};

const MPropheciesView = (props: {
  player: InflatedPlayer;
  game: InflatedGame;
}) => {
  const { player, game } = props;

  const prophecies = player.cards
    .map((card) => getEndOfGameCardAbility(card))
    .filter(nonNull);

  const context = makeContextFromGame(player, game);
  const prophecy_outputs = prophecies
    .flatMap((prophecy) => {
      return prophecy.outputs.map((output) => {
        if (
          output.upgradeCondition &&
          !upgradeConditionMet(output.upgradeCondition, context)
        ) {
          return null;
        }

        return {
          output,
          prophecy,
        };
      });
    })
    .filter(nonNull);

  const prophecyOutputByMultiplier = prophecy_outputs.reduce((acc, curr) => {
    invariant(
      curr.output.outputResource === 'favor',
      'Prophecy output must be favor',
    );

    const key = curr.output.multiplier
      ? `${curr.output.multiplier.resourceDivisor}-${curr.output.multiplier.resource}`
      : 'no-multiplier';

    const existing = acc.get(key);
    if (existing) {
      existing.outputRatio += curr.output.outputRatio;
    } else {
      acc.set(key, { ...curr.output });
    }
    return acc;
  }, new Map<string, AbilityOutput>());

  return (
    <MPlayerDetailsSectionWithTitle title="Prophecies">
      <div css={MExpandedPlayerSummaryStyles.sectionContent}>
        {Array.from(prophecyOutputByMultiplier.values()).map((output, i) => {
          return (
            <EffectPill
              key={i}
              output={output}
              css={MExpandedPlayerSummaryStyles.prophecyPill}
            />
          );
        })}
      </div>
    </MPlayerDetailsSectionWithTitle>
  );
};

const MAvailableUpgradesView = (props: {
  player: InflatedPlayer;
  game: InflatedGame;
}) => {
  const { player, game } = props;

  const upgradeInfo = computeUpgradeInfo(player, game);

  const cardIDToUpgradeInfo = upgradeInfo.cardIDToLockedUpgradeInfo;

  const upgradeConditionAbilityPairs = Array.from(
    cardIDToUpgradeInfo.values(),
  ).flatMap((upgradeInfo) => {
    return upgradeInfo.abilityAndConditionPairs;
  });

  return (
    <MPlayerDetailsSectionWithTitle title="Upgrades">
      <div css={MExpandedPlayerSummaryStyles.sectionContent}>
        {upgradeConditionAbilityPairs.map(([condition, ability], i) => {
          return (
            <div key={i} css={MExpandedPlayerSummaryStyles.upgradeContainer}>
              <UpgradeCondition
                css={MExpandedPlayerSummaryStyles.upgradeCondition}
                upgradeCondition={condition}
              />
              <CardEffect
                ability={ability}
                cardType={CardType.Leader}
                upgradeCondition={condition}
              />
            </div>
          );
        })}
      </div>
    </MPlayerDetailsSectionWithTitle>
  );
};

const MExpandedPlayerSummaryStyles = {
  sectionContent: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: 2,
    minHeight: 20,
  }),

  prophecyPill: css({
    zoom: 2.5,
  }),

  upgradeContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    zoom: 2,

    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: 4,
    padding: 2,
    paddingLeft: 3,
  }),
  upgradeCondition: css({
    border: '0.5px solid grey',
    zoom: 1.5,
    backgroundColor: 'white',
    boxSizing: 'border-box',
  }),
};
