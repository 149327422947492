/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import SendButtonImg from '../../assets/chat_send.svg';
import Session from '../../common/utils/Session';
import { ChatMessage, fetchUsers } from '../actions';
import { getChannelListener } from '../ChannelListener';
import { MHoverButton } from './MButton';
import { MModal } from './MUIComponents';
import { User } from '../User';

function MChatButton(props: {
  room: string;
  title: string;

  session: Session | undefined;
  messagesByRoom: Map<string, ChatMessage[]>;
  userByID: Map<string, User>;
  dispatch: React.Dispatch<any>;
}) {
  if (!props.session) {
    return null;
  }

  useEffect(() => {
    const topic = `chat/${props.room}`;
    getChannelListener().subscribeToTopic(topic);
    return () => {
      getChannelListener().unsubscribeToTopic(topic);
    };
  }, [props.room]);

  const [expanded, setExpanded] = useState(false);

  const messages = props.messagesByRoom.get(props.room) || [];

  return (
    <>
      <MHoverButton
        onShortPress={() => setExpanded(true)}
        onLongPress={() => setExpanded(true)}
        onLongPressEnd={() => setExpanded(false)}
      >
        CHAT
      </MHoverButton>

      {expanded && (
        <MModal
          show={expanded}
          onClose={() => setExpanded(false)}
          title={props.title}
        >
          <MChatView
            room={props.room}
            title={props.title}
            messages={messages}
            userByID={props.userByID}
            dispatch={props.dispatch}
          />
        </MModal>
      )}
    </>
  );
}
const session = (state: any) => {
  return {
    session: state.session as Session | undefined,
    messagesByRoom: state.chatMessagesByRoom,
    userByID: state.userByID,
  };
};
export default connect(session)(MChatButton);

function MChatView(props: {
  room: string;
  title: string;
  messages: ChatMessage[];
  userByID: Map<string, User>;
  dispatch: React.Dispatch<any>;
}) {
  return (
    <div css={MChatStyles.container}>
      <MChatBody
        messages={props.messages}
        userByID={props.userByID}
        dispatch={props.dispatch}
      />
      <MChatForm room={props.room} />
    </div>
  );
}

const MChatForm = forwardRef(
  (
    props: {
      room: string;
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      },
    }));

    return (
      <form
        css={MChatStyles.form}
        onSubmit={(e) => {
          e.preventDefault();

          if (!inputRef.current) {
            return;
          }
          let message = inputRef.current.value;
          inputRef.current.value = '';

          message = message.trim();
          if (!message) {
            return;
          }

          getChannelListener().sendChatMessage(props.room, message);
        }}
      >
        <input
          css={MChatStyles.input}
          type="text"
          ref={inputRef}
          placeholder="Aa"
        />
        <button css={MChatStyles.sendButton} type="submit">
          <img css={MChatStyles.sendButtonImage} src={SendButtonImg} />
        </button>
      </form>
    );
  },
);

function MChatBody(props: {
  userByID: Map<string, User>;
  messages: ChatMessage[];
  dispatch: React.Dispatch<any>;
}) {
  const { userByID, messages, dispatch } = props;
  return (
    <div
      ref={(node) => {
        if (node) {
          node.scrollTop = node.scrollHeight;
        }
      }}
      css={MChatStyles.body}
    >
      {messages.map((message, i) => {
        let user = userByID.get(message.senderID);
        if (!user) {
          dispatch(fetchUsers([message.senderID]));
        }
        return (
          <div key={i}>
            {user ? user.name : 'unknown'}: {message.message}
          </div>
        );
      })}
    </div>
  );
}

const MChatStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    flexGrow: 1,
    flexShrink: 1,
  }),
  header: css({}),
  body: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-end',

    flexGrow: 1,

    padding: 10,
  }),
  form: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'stretch',
    gap: 5,
    padding: 5,
  }),
  input: css({
    flexGrow: 1,
    height: 30,
  }),
  sendButton: css({
    flexGrow: 0,
  }),
  sendButtonImage: css({
    width: 30,
    margin: 5,
  }),
};
