import { GameOptionsKey } from './GameOptions';

export type GameModuleState = 'disabled' | 'enabled' | 'devOnly';
export type GameModule = {
  key: string;
  name: string;
  description: string;

  gameOptionsToAdd: GameOptionsKey[];

  replaceCore?: boolean;
  state: GameModuleState;
};

export const GameModules: GameModule[] = [
  {
    key: 'conquest',
    name: 'Conquest',
    description:
      'Introduces Clash tokens, which are gained whenever you Clash with a foe. Spend these to obtain powerful cards, or save them to synergize with cards in later ages.',

    gameOptionsToAdd: ['warTokens'],
    state: 'disabled',
  },
  {
    key: 'beta',
    name: 'Beta',
    description: 'Testing grounds for card updates.',
    gameOptionsToAdd: [],
    state: 'disabled',
  },
  {
    key: 'noupgrade',
    name: 'No Upgrades',
    description: 'Replace card upgrades with on gain effects.',
    gameOptionsToAdd: [],
    state: 'devOnly',
  },
  {
    key: 'legacy',
    name: 'Legacy',
    description: 'An older version of Mythos.',
    gameOptionsToAdd: [],
    replaceCore: true,

    state: 'enabled',
  },
];
