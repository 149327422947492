/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';
import { InflatedGame } from '@mythos/game/Game';
import Phases, { PhaseToBackgroundColor } from '@mythos/game/Phases';
import { LobbyButtonStyles } from 'LobbyButton';
import nullthrows from 'nullthrows';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ActionStore from '../ActionStore';
import { GlobalAreaView } from '../GameView';
import HoverCardStore from '../HoverCardStore';
import { LogView } from '../LogView';
import { UserWithColor } from '../User';
import { MHoverButton } from './MButton';
import MChatButton from './MChatView';
import { MColors } from './MColors';
import MGameStateView from './MGameStateView';
import { MPlayerSummaryViews } from './MPlayerSummaryView';
import MSelfPlayerView from './MSelfPlayerView';
import { MEDIA_QUERY_MOBILE, MStyles } from './MStyles';
import { MModal } from './MUIComponents';

export default function MGameView(props: {
  game: InflatedGame;
  userByID: Map<string, UserWithColor>;
  actionStore?: ActionStore;
  sessionUserID?: string;

  // TODO animation refs
  // cardIDToAnimation?: Map<string, HTMLDivElement>;
  // cardIndexToAnimation?: Map<number, HTMLDivElement>;
  // tributeRowIndexToAnimation?: Map<number, HTMLDivElement>;
}) {
  const { game, userByID, actionStore, sessionUserID } = props;

  const [logViewOpen, setLogViewOpen] = useState(false);

  const selfPlayerView = actionStore && (
    <MSelfPlayerView
      actionStore={actionStore}
      ready={game.readyByUserID[nullthrows(sessionUserID)]}
      phase={game.phase}
    />
  );

  const hoverButtons = (
    <div css={MGameStyles.hoverButtonContainer}>
      <MHoverButton
        onShortPress={() => {
          setLogViewOpen(true);
        }}
        onLongPress={() => {
          setLogViewOpen(true);
        }}
        onLongPressEnd={() => {
          setLogViewOpen(false);
        }}
      >
        LOG
      </MHoverButton>
      <MChatButton room={`game/${game.id}`} title="Game Chat" />
    </div>
  );

  const endOfGameView = game.gameEndTimestamp && (
    <MEndOfGameView game={game} userByID={userByID} />
  );

  return (
    <div css={MGameStyles.container}>
      <MGameStateView game={game} />
      <MPlayerSummaryViews
        sessionPlayerID={sessionUserID}
        game={game}
        players={game.players}
        userByID={userByID}
        readyByUserID={game.readyByUserID}
      />
      <div css={MGameStyles.gameContainer}>
        {endOfGameView || (
          <GlobalAreaView
            actionStore={actionStore}
            game={game}
            userByID={userByID}
            selfPlayerView={null}
          />
        )}
      </div>
      {selfPlayerView}
      {hoverButtons}
      <MModal
        show={logViewOpen}
        onClose={() => setLogViewOpen(false)}
        title="Game Log"
      >
        <LogView game={game} userByID={userByID} />
      </MModal>
      {HoverCardStore.getHoverCardComponent()}
    </div>
  );
}

const MGameStyles = {
  container: css(
    {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: ['100vh', '100dvh'],
      position: 'relative',
    },
    MStyles.touchable,
  ),
  gameContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    flexShrink: 1,
    flexGrow: 1,
    overflowY: 'auto',
  }),
  hoverButtonContainer: css({
    position: 'absolute',
    bottom: '20%',
    right: 0,
    padding: 5,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: 5,
  }),
  logViewContainer: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,

    backgroundColor: 'white',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  }),
  logViewHeader: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    backgroundColor: 'white',
    height: 40,
    flexShrink: 0,
    padding: 5,
  }),
  logViewHeaderText: css({
    display: 'flex',
    alignItems: 'center',
    fontSize: 28,
    fontWeight: 'bold',
  }),
};

const MEndOfGameView = (props: {
  game: InflatedGame;
  userByID: Map<string, UserWithColor>;
}) => {
  const { game, userByID } = props;

  let maxFavor = Math.max(
    ...game.players.map((player) => player.counters.favor),
  );
  let winningPlayers = game.players.filter(
    (player) => maxFavor === player.counters.favor,
  );

  const winningPlayersContent = (
    <div css={MEndOfGameStyles.winningPlayersContent}>
      {winningPlayers.map((player, i) => {
        const user = userByID.get(player.userID)!;
        return (
          <span key={player.userID} css={MEndOfGameStyles.winningPlayerName}>
            {user.name}
            {i !== winningPlayers.length - 1 && ' and '}
          </span>
        );
      })}
      {' won!'}
    </div>
  );

  return (
    <div css={MEndOfGameStyles.container}>
      {winningPlayersContent}
      <Link to="/" css={MEndOfGameStyles.returnToLobbyButton}>
        Return to Lobby
      </Link>
      <div css={MEndOfGameStyles.gameEndText}>More graphs to come!</div>
    </div>
  );
};
const MEndOfGameStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: PhaseToBackgroundColor[Phases.TRIBUTE],
    flexGrow: 1,
    padding: 10,
    gap: 20,
  }),
  winningPlayersContent: css({
    fontSize: 24,
    fontWeight: 'bold',
    color: MColors.whiteText,
  }),
  winningPlayerName: css({
    fontWeight: 'bold',
  }),
  returnToLobbyButton: css(LobbyButtonStyles.button, {
    [MEDIA_QUERY_MOBILE]: {
      padding: 20,
    },
  }),
  gameEndText: css({
    fontSize: 16,
    color: MColors.whiteText,
  }),
};
