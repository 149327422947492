/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import invariant from 'invariant';
import React, { useEffect, useRef } from 'react';
import { match } from 'ts-pattern';
import {
  recursiveArrayIterator,
  RecursiveItemOrArray,
} from '../common/utils/ts_utils';
import { makeStringID } from '../common/utils/utils';
import { generateQuadraticBezierSpline } from './bezier';

export type AnimationElement = HTMLElement | HTMLDivElement;
export type AnimationPoint = [number, number];
type Animation = {
  key: string;

  startPoint: AnimationPoint;
  endPoint: AnimationPoint;
  startScale: number;
  endScale: number;
  startOpacity: number;
  endOpacity: number;
  durationSeconds: number;
  beginPauseSeconds: number;
  finishPauseSeconds: number;
  delaySeconds: number;
  randomSpreadDistance?: number;
  curve?: AnimationCurve;

  target: AnimationTarget;

  creationParams: AddAnimationParams;

  onFinished?: () => void;
  controller?: globalThis.Animation;
};
export type AnimationPosition =
  | {
      type: 'element';
      element: AnimationElement;
      anchorPoint?: AnimationPoint;
      offset?: AnimationPoint;
    }
  | {
      type: 'point';
      point: AnimationPoint;
    };
export function ElementPosition(
  element: AnimationElement,
  options?: {
    anchorPoint?: AnimationPoint;
    offset?: AnimationPoint;
  },
): AnimationPosition {
  return {
    type: 'element',
    element,
    anchorPoint: options?.anchorPoint,
    offset: options?.offset,
  };
}
export function PointPosition(point: AnimationPoint): AnimationPosition {
  return { type: 'point', point };
}

export type AnimationTarget =
  | {
      type: 'node';
      node: React.ReactNode;
      // node's anchor point, default is top-left, [0, 0], [1, 1] is bottom-right
      anchorPoint: AnimationPoint;
    }
  | {
      type: 'element';
      element: AnimationElement;
    }
  | null;
export function NodeTarget(
  node: React.ReactNode,
  options?: {
    anchorPoint?: AnimationPoint;
  },
): AnimationTarget {
  return {
    type: 'node',
    node,
    anchorPoint: options?.anchorPoint || [0.0, 0.0],
  };
}
export function ElementTarget(element: AnimationElement): AnimationTarget {
  return {
    type: 'element',
    element,
  };
}
export type AddAnimationParams = {
  target: AnimationTarget;

  // TODO type this as required for node targets
  startElement?: AnimationPosition;
  endElement?: AnimationPosition;

  startScale?: number;
  endScale?: number;

  startOpacity?: number;
  endOpacity?: number;

  // seconds to animate from start to end
  durationSeconds: number;

  // wait to before mounting node until all referenced animation(s) have reached the finish pause stage
  delayUntilFinished?: RecursiveItemOrArray<AddAnimationParams>;
  // seconds to wait before mounting node
  delaySeconds?: number;
  // seconds to wait before starting the animation after mounting node
  beginPauseSeconds?: number;
  // seconds to wait before unmounting node after animation has completed
  finishPauseSeconds?: number;

  // if true, will not block the animation system from clearing the animation block
  nonBlocking?: boolean;

  curve?: AnimationCurve;

  onFinished?: () => void;
};
export type AnimationCurveType = 'linear' | 'quadratic-bezier';
export type AnimationCurve = {
  type: AnimationCurveType;
  controlPoints: AnimationPoint[];
};
export function CalculateAnimationPosition(
  animationPosition: AnimationPosition | undefined,
): AnimationPoint | undefined {
  if (!animationPosition) {
    return undefined;
  }
  return match(animationPosition)
    .with({ type: 'element' }, (x) => {
      const anchorPoint = x.anchorPoint || [0, 0];
      const offset = x.offset || [0, 0];
      const rect = x.element.getBoundingClientRect();
      return [
        rect.x + rect.width * anchorPoint[0] + offset[0],
        rect.y + rect.height * anchorPoint[1] + offset[1],
      ] as AnimationPoint;
    })
    .with({ type: 'point' }, (x) => x.point)
    .exhaustive();
}
export class TAnimationSystem {
  private _animations: Animation[] = [];
  private _animationsToRetire: Animation[] = [];
  private _listeners: (() => void)[] = [];
  private _timersToStart: [number, () => void][] = [];
  private _playbackRate: number = 1;
  private _timeouts: number[] = [];

  addAnimations(animations: AddAnimationParams[]): void {
    let maxDuration = 0;
    const paramsToAnimations = new Map<AddAnimationParams, Animation>();
    animations.forEach((x) => {
      const animation = this._addAnimationHelper(x, paramsToAnimations);
      paramsToAnimations.set(x, animation);
      let totalDuration =
        animation.durationSeconds +
        animation.beginPauseSeconds +
        animation.finishPauseSeconds +
        animation.delaySeconds;
      totalDuration /= this._playbackRate;

      if (!x.nonBlocking) {
        maxDuration = Math.max(maxDuration, totalDuration);
      }
      this._animations.push(animation);
    });

    this._timersToStart.push([
      maxDuration * 1000,
      () => this._timeoutAnimations(),
    ]);
  }
  // retire timedout animations
  retireAnimations(): void {
    this._animationsToRetire.forEach((x) => {
      x.controller?.finish();
    });
    this._animationsToRetire = [];
  }
  clearAnimations(): void {
    this._clearAnimations();
  }
  hasAnimations(): boolean {
    return this._animations.length > 0;
  }

  addListener(listener: () => void): void {
    this._listeners.push(listener);
  }
  removeListener(listener: () => void): void {
    this._listeners = this._listeners.filter((x) => x !== listener);
  }

  getPlaybackRate(): number {
    return this._playbackRate;
  }

  // Internals

  _getAnimations(): Animation[] {
    return this._animations;
  }

  _attachController(animation: Animation, controller: globalThis.Animation) {
    invariant(animation.controller === undefined, 'controller === undefined');
    animation.controller = controller;
    controller.playbackRate = this._playbackRate;
    controller.onfinish = () => {
      animation.onFinished?.();
    };
  }

  _startTimers(): void {
    this._timersToStart.forEach(([time, cb]) => {
      this._timeouts.push(setTimeout(cb, time) as any);
    });
    this._timersToStart = [];
  }

  private _timeoutAnimations(): void {
    this._animationsToRetire.push(...this._animations);
    this._animations = [];

    this._notifyListeners();
  }

  private _clearAnimations(): void {
    this._timeouts.forEach((x) => clearTimeout(x));
    this._timeouts = [];

    this._animations.forEach((x) => {
      x.controller?.finish();
    });
    this._animations = [];

    this.retireAnimations();

    this._notifyListeners();
  }
  private _addAnimationHelper(
    animationParams: AddAnimationParams,
    paramsToAnimations: Map<AddAnimationParams, Animation>,
  ): Animation {
    const startPoint = CalculateAnimationPosition(
      animationParams.startElement,
    ) || [-1000, -1000]; // FIXME;
    const endPoint =
      CalculateAnimationPosition(animationParams.endElement) || startPoint;

    let delaySeconds = animationParams.delaySeconds || 0;
    if (animationParams.delayUntilFinished) {
      let maxDelaySeconds = 0;
      for (let x of recursiveArrayIterator(
        animationParams.delayUntilFinished,
      )) {
        const referencedAnimation = paramsToAnimations.get(x);
        if (!referencedAnimation) {
          console.error('delayUntilFinished not found');
          continue;
        }
        let totalDuration =
          referencedAnimation.delaySeconds +
          referencedAnimation.durationSeconds +
          referencedAnimation.beginPauseSeconds;
        delaySeconds = Math.max(delaySeconds, totalDuration);
      }

      delaySeconds += maxDelaySeconds;
    }

    const animation: Animation = {
      key: makeStringID(),
      durationSeconds: animationParams.durationSeconds,
      startPoint,
      endPoint,
      beginPauseSeconds: Math.max(0, animationParams.beginPauseSeconds || 0),
      delaySeconds: Math.max(0, delaySeconds),
      startScale: animationParams.startScale ?? 1,
      endScale: animationParams.endScale ?? 1,
      startOpacity:
        animationParams.startOpacity ?? animationParams.endOpacity ?? 1,
      endOpacity:
        animationParams.endOpacity ?? animationParams.startOpacity ?? 1,
      finishPauseSeconds: Math.max(0, animationParams.finishPauseSeconds || 0),
      curve: animationParams.curve,

      target: animationParams.target,

      onFinished: animationParams.onFinished,

      creationParams: animationParams,
    };

    return animation;
  }
  _notifyListeners(): void {
    this._listeners.forEach((x) => x());
  }
}

const AnimationSystem = new TAnimationSystem();

export function AnimationLayer(props: {}) {
  const forceUpdate = React.useReducer((x) => x + 1, 0)[1];
  useEffect(() => {
    const listener = () => {
      forceUpdate();
    };
    AnimationSystem.addListener(listener);
    return () => {
      AnimationSystem.removeListener(listener);
    };
  }, [forceUpdate]);
  const layerRef = useRef<HTMLDivElement>(undefined);

  AnimationSystem.retireAnimations();

  const originOffset: AnimationPoint = match(layerRef.current)
    .with(undefined, () => [0, 0] as AnimationPoint)
    .otherwise((x) => {
      const rect = x.getBoundingClientRect();
      return [-rect.left, -rect.top] as AnimationPoint;
    });

  return (
    <div ref={layerRef as any} css={AnimationStyles.animationLayer}>
      {AnimationSystem._getAnimations().map((anim) => {
        return (
          <AnimationWrapper
            key={anim.key}
            anim={anim}
            originOffset={originOffset}
          />
        );
      })}
    </div>
  );
}

function AnimationWrapper(props: {
  anim: Animation;
  originOffset: AnimationPoint;
}) {
  const { anim, originOffset } = props;

  if (anim.target === null) {
    return null;
  } else if (anim.target.type === 'element') {
    const element = anim.target.element;
    useEffect(() => {
      if (anim.controller) {
        return;
      }
      const startParams = {
        transform: `scale(${anim.startScale})`,
        opacity: anim.startOpacity,
      };
      const endParams = {
        transform: `scale(${anim.endScale})`,
        opacity: anim.endOpacity,
      };
      let keyframes: Keyframe[] = [];
      keyframes.push({
        offset: 0,
        ...startParams,
      });
      keyframes.push({
        offset: anim.beginPauseSeconds,
        ...startParams,
      });
      keyframes.push({
        offset: anim.beginPauseSeconds + anim.durationSeconds,
        ...endParams,
      });
      keyframes.push({
        offset:
          anim.beginPauseSeconds +
          anim.durationSeconds +
          anim.finishPauseSeconds,
        ...endParams,
      });
      const totalDuration = keyframes[keyframes.length - 1]!.offset!;
      keyframes = keyframes.map((x) => {
        return {
          ...x,
          offset: x.offset! / totalDuration,
        };
      });
      const controller = element.animate(keyframes, {
        duration: totalDuration * 1000,
        delay: anim.delaySeconds * 1000,
        playbackRate: AnimationSystem.getPlaybackRate(),
      });
      AnimationSystem._attachController(anim, controller);
      AnimationSystem._startTimers();
    }, []);
    return null;
  }

  const ref = useRef<HTMLDivElement>(undefined);

  let animDuration =
    anim.durationSeconds + anim.beginPauseSeconds + anim.finishPauseSeconds;
  const anchorTransform = `translate(${-Math.round(anim.target.anchorPoint[0] * 100)}%, ${-Math.round(anim.target.anchorPoint[1] * 100)}%)`;
  let keyframes: Keyframe[] = [
    {
      offset: 0,
      visibility: 'hidden',
    },
  ];
  const lastOffset = () => keyframes[keyframes.length - 1]!.offset!;
  keyframes.push({
    offset: lastOffset() + anim.beginPauseSeconds,

    visibility: 'visible',
    top: originOffset[1] + anim.startPoint[1] + 'px',
    left: originOffset[0] + anim.startPoint[0] + 'px',
    transform: `${anchorTransform} scale(${anim.startScale})`,
    opacity: anim.startOpacity,
  });
  const startAnimOffset = lastOffset();
  if (anim.curve) {
    if (anim.curve.type === 'linear') {
      for (let i = 1; i < anim.curve.controlPoints.length - 1; i++) {
        invariant(
          anim.curve.controlPoints.length >= 2,
          'linear curve must have at least 2 control points',
        );
        keyframes.push({
          offset:
            startAnimOffset +
            (i / anim.curve.controlPoints.length) * anim.durationSeconds,
          top: originOffset[1] + anim.curve.controlPoints[i][1] + 'px',
          left: originOffset[0] + anim.curve.controlPoints[i][0] + 'px',
        });
      }
    } else if (anim.curve.type === 'quadratic-bezier') {
      invariant(
        anim.curve.controlPoints.length >= 3,
        'Quadratic Bezier curve must have at least 3 control points',
      );

      let spline = generateQuadraticBezierSpline(anim.curve.controlPoints, 10);

      for (let i = 0; i < spline.length; i++) {
        keyframes.push({
          offset:
            startAnimOffset +
            0.2 * anim.durationSeconds +
            (i / spline.length) * anim.durationSeconds * 0.8,

          top: originOffset[1] + spline[i][1] + 'px',
          left: originOffset[0] + spline[i][0] + 'px',
        });
      }
    } else {
      console.error('unrecognized animation curve type ' + anim.curve.type);
    }
  }
  keyframes.push({
    offset: startAnimOffset + anim.durationSeconds,
    top: originOffset[1] + anim.endPoint[1] + 'px',
    left: originOffset[0] + anim.endPoint[0] + 'px',
    transform: `${anchorTransform} scale(${anim.endScale})`,
    opacity: anim.endOpacity,
    visibility: 'visible',
    easing: 'ease-in-out',
  });
  keyframes.push({
    offset: lastOffset() + anim.finishPauseSeconds,
    top: originOffset[1] + anim.endPoint[1] + 'px',
    left: originOffset[0] + anim.endPoint[0] + 'px',
    transform: `${anchorTransform} scale(${anim.endScale})`,
    opacity: anim.endOpacity,
    visibility: 'visible',
  });

  // normalize keyframe durations
  const totalDuration = lastOffset();
  invariant(totalDuration >= animDuration, 'totalDuration >= animDuration');
  keyframes = keyframes.map((x) => {
    return {
      ...x,
      offset: x.offset! / totalDuration,
    };
  });

  useEffect(() => {
    if (!ref.current) {
      console.error('no ref!');
      return;
    }

    if (anim.controller) {
      return;
    }
    const controller = ref.current.animate(keyframes, {
      duration: totalDuration * 1000,
      delay: anim.delaySeconds * 1000,
      playbackRate: AnimationSystem.getPlaybackRate(),
    });
    AnimationSystem._attachController(anim, controller);

    AnimationSystem._startTimers();
  }, [ref]);
  return (
    <div
      ref={ref as any}
      style={{
        position: 'absolute',
        visibility: 'hidden',
        top: originOffset[1] + anim.startPoint[1],
        left: originOffset[0] + anim.startPoint[0],
        transformOrigin: `${anim.target.anchorPoint[0] * 100}% ${anim.target.anchorPoint[1] * 100}%`,
      }}
    >
      {anim.target.node}
    </div>
  );
}

const AnimationStyles = {
  animationLayer: css({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',

    zIndex: 10,
  }),
};

export default AnimationSystem;
