/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import * as AIUtilities from '@mythos/game/AIUtilities';
import { upgradeConditionMet } from '@mythos/game/CardAbilities';
import {
  CardSubType,
  CardType,
  isCardSubType,
  isCardType,
} from '@mythos/game/CardTypes';
import { InflatedGame, InflatedPlayer } from '@mythos/game/Game';
import { makeContextFromGame } from '@mythos/game/Rules';
import invariant from 'invariant';
import { match } from 'ts-pattern';
import { computeCardEffectRows, MultiCardDefRenderer } from './CardView';
import HoverCardStore from './HoverCardStore';
import Symbols, {
  CardSubtypeToSymbol,
  CardTypeToSymbol,
  SYMBOL_TO_IMAGE,
} from './Symbols';

const CARD_TYPE_TO_COLOR = {
  [CardType.Basic]: 'rgb(139, 87, 43)',
  [CardType.Resource]: 'rgb(68, 120, 19)',
  [CardType.Conflict]: 'rgb(212, 52, 34)',
  [CardType.Prayer]: 'rgb(69, 148, 226)',
  [CardType.Leader]: '',
  [CardType.None]: '',
};
const CARD_TYPE_TO_SYMBOL_FILTER = {
  [CardType.Basic]:
    'invert(14%) sepia(37%) saturate(3619%) hue-rotate(12deg) brightness(96%) contrast(98%)',
  [CardType.Resource]:
    'invert(12%) sepia(58%) saturate(1310%) hue-rotate(56deg) brightness(97%) contrast(103%)',
  [CardType.Conflict]:
    'invert(11%) sepia(42%) saturate(4855%) hue-rotate(349deg) brightness(91%) contrast(115%)',
  [CardType.Prayer]:
    'invert(19%) sepia(41%) saturate(3232%) hue-rotate(198deg) brightness(93%) contrast(97%)',
  [CardType.Leader]: '',
  [CardType.None]: '',
};

const ORDERED_CARD_TYPES = [
  CardType.Resource,
  CardType.Conflict,
  CardType.Prayer,
  CardType.Basic,
];
const ORDERED_CARD_SUBTYPES = [
  CardSubType.Unit,
  CardSubType.Building,
  CardSubType.Event,
];

export const CardCountView = (props: {
  type: CardType | CardSubType | 'upgrade';
  count: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}) => {
  const { type, count } = props;
  const { filter, opacity, symbol, backgroundColor } = match(type)
    .when(
      (x) => isCardType(x),
      (x) => {
        return {
          symbol: CardTypeToSymbol[x],
          opacity: 1,
          filter: CARD_TYPE_TO_SYMBOL_FILTER[x],
          backgroundColor: CARD_TYPE_TO_COLOR[x],
        };
      },
    )
    .when(
      (x) => isCardSubType(x),
      (x) => {
        return {
          symbol: CardSubtypeToSymbol[x],
          opacity: 0.62,
          filter: undefined,
          backgroundColor: 'grey',
        };
      },
    )
    .when(
      (x) => x === 'upgrade',
      () => {
        return {
          symbol: Symbols.UPGRADE,
          opacity: 0.62,
          filter: undefined,
          backgroundColor: 'grey',
        };
      },
    )
    .otherwise((x) => invariant(false, 'unknown card type/subtype: %s', x));
  const container_style: React.CSSProperties = {
    backgroundColor,
  };
  const icon_style: React.CSSProperties = {
    backgroundImage: `url(${SYMBOL_TO_IMAGE[symbol]})`,
    filter,
    opacity,
  };
  return (
    <div
      css={CardCountsViewStyles.cardCount}
      title={props.type as string}
      style={container_style}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <div css={CardCountsViewStyles.cardCountUnderlay} style={icon_style} />
      <div css={CardCountsViewStyles.cardCountText}>{count}</div>
    </div>
  );
};
export const CardCountsView = (props: {
  player: InflatedPlayer;
  game: InflatedGame;
}) => {
  const { player, game } = props;

  const context = makeContextFromGame(player, game);
  const card_counts = AIUtilities.cardCompositionCounts(player);
  const cards_with_upgrades = player.cards.filter((card) => {
    const rows = computeCardEffectRows(card);
    for (const [condition, abilities] of rows.effectRowsByUpgrade) {
      if (!upgradeConditionMet(condition, context)) {
        return true;
      }
    }
    return false;
  });
  const upgrade_count = cards_with_upgrades.length;

  const cards = ORDERED_CARD_TYPES.map((type) => {
    return (
      <CardCountView key={type} type={type} count={card_counts[type] || 0} />
    );
  });
  const subtype_cards = ORDERED_CARD_SUBTYPES.map((subtype) => {
    return (
      <CardCountView
        key={subtype}
        type={subtype}
        count={card_counts[subtype] || 0}
      />
    );
  });
  return (
    <div css={CardCountsViewStyles.cardCountsContainer}>
      <div css={CardCountsViewStyles.cardCountsRow}>{cards}</div>
      <div css={CardCountsViewStyles.cardCountsRow}>
        {subtype_cards}
        <CardCountView
          type="upgrade"
          count={upgrade_count}
          onMouseEnter={() => {
            HoverCardStore.setCard(cards_with_upgrades, MultiCardDefRenderer);
            HoverCardStore.setPosition('top-right-offset');
          }}
          onMouseLeave={() => {
            HoverCardStore.setCard(null, null);
          }}
        />
      </div>
    </div>
  );
};

const CardCountsViewStyles = {
  cardCount: css({
    position: 'relative',
    boxSizing: 'border-box',
    display: 'inline-flex',
    borderRadius: 5,
    color: 'white',
    height: 22,
    width: 17,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
  }),
  cardCountUnderlay: css({
    position: 'absolute',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '90%',
    width: '90%',
  }),
  cardCountText: css({
    position: 'absolute',
  }),
  cardCountsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    flexShrink: 0,
    gap: 2,
  }),
  cardCountsRow: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
  }),
};
