/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { MEDIA_QUERY_MOBILE } from 'mobile_ui/MStyles';
import { useMobile } from 'MobileContext';
import { match, P } from 'ts-pattern';
import Session from '../common/utils/Session';
import {
  fetchLobbyList,
  subscribeToChannelTopic,
  unsubscribeToChannelTopic,
} from './actions';
import LobbyButton from './LobbyButton';
import LobbyMutator from './LobbyMutator';

type Lobby = {
  id: string;
  name: string;
  creationDate: number;
  status: string;
  game?: {
    id: string;
    endTimestamp?: number;
  };
  host: { id: string; name: string; profilePictureURL: string };
  players: {
    edges: any[];
    nodes: any[];
  };
};

const LobbyListEntry: React.FC<{
  lobby: Lobby;
  sessionUserID?: string;
  onJoin: (lobby: Lobby) => void;
  onSpectate: (lobby: Lobby) => void;
}> = (props) => {
  const { lobby, sessionUserID, onJoin, onSpectate } = props;
  const game = lobby.game;

  let connect_section: React.ReactNode;
  if (game) {
    const contains_session_user = lobby.players.edges.some((edge: any) => {
      return edge.node.id === sessionUserID;
    });
    let button_text = contains_session_user ? 'Resume Game' : 'Spectate';
    if (game.endTimestamp) {
      button_text = 'View Results';
    }
    connect_section = (
      <LobbyButton secondary={true} onClick={() => onSpectate(lobby)}>
        {button_text}
      </LobbyButton>
    );
  } else {
    connect_section = (
      <LobbyButton onClick={() => onJoin(lobby)}>Join Lobby</LobbyButton>
    );
  }
  const Cell = (props: { className?: string; children: React.ReactNode }) => (
    <td className={props.className} css={LobbyListStyles.tableCell}>
      {props.children}
    </td>
  );
  return (
    <tr>
      <Cell>{lobby.name}</Cell>
      <Cell css={LobbyListStyles.tableCellHost}>
        <img src={lobby.host.profilePictureURL} css={LobbyListStyles.profile} />
        <span css={LobbyListStyles.hostName}>{lobby.host.name}</span>
      </Cell>
      <Cell css={LobbyListStyles.tableCellSmall}>
        {lobby.players.edges.length}
      </Cell>
      <Cell css={LobbyListStyles.tableCellSmall}>
        {moment(lobby.creationDate).fromNow()}
      </Cell>
      <Cell css={LobbyListStyles.tableCellSmall}>
        <span css={LobbyListStyles.tableSubCell}>{lobby.status}</span>
      </Cell>
      <Cell>{connect_section}</Cell>
    </tr>
  );
};

function LobbyListView(props: {
  session: Session;
  lobbies: Lobby[];
  onJoinLobby: (lobby: Lobby) => void;
  onSpectateLobby: (lobby: Lobby) => void;
  onCreateLobby: () => void;
}) {
  const { session, lobbies, onJoinLobby, onSpectateLobby, onCreateLobby } =
    props;

  const user = session && session.getUser();
  const lobbyViews = lobbies.map((lobby) => {
    return (
      <LobbyListEntry
        key={lobby.id}
        lobby={lobby}
        sessionUserID={user && user.id}
        onJoin={onJoinLobby}
        onSpectate={onSpectateLobby}
      />
    );
  });
  const HeaderCell = (props: {
    className?: string;
    children: React.ReactNode;
  }) => (
    <td
      className={props.className}
      css={[LobbyListStyles.tableCell, LobbyListStyles.tableCellHeader]}
    >
      {props.children}
    </td>
  );

  return (
    <div css={LobbyListStyles.container}>
      <div css={LobbyListStyles.header}>
        <LobbyButton css={LobbyListStyles.createButton} onClick={onCreateLobby}>
          Create Lobby
        </LobbyButton>
      </div>
      <div css={LobbyListStyles.tableContainer}>
        <table css={LobbyListStyles.table}>
          <thead>
            <tr css={LobbyListStyles.tableHeader}>
              <HeaderCell>Lobby</HeaderCell>
              <HeaderCell>Host</HeaderCell>
              <HeaderCell css={LobbyListStyles.tableCellSmall}>
                Players
              </HeaderCell>
              <HeaderCell css={LobbyListStyles.tableCellSmall}>
                Created
              </HeaderCell>
              <HeaderCell css={LobbyListStyles.tableCellSmall}>
                Status
              </HeaderCell>
              <HeaderCell>Play</HeaderCell>
            </tr>
          </thead>
          <tbody>{lobbyViews}</tbody>
        </table>
      </div>
    </div>
  );
}

const LobbyListStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  header: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',

    fontSize: 16,
    margin: 20,
    marginBottom: 5,
    padding: 5,
  }),
  createButton: css({
    margin: 0,
    padding: '10px 80px 10px 80px',
    fontSize: 16,
  }),
  tableContainer: css({
    margin: 20,
    marginTop: 0,
  }),
  table: css({
    width: '100%',
    backgroundColor: 'rgb(120, 120, 120)',
  }),
  tableHeader: css({
    backgroundColor: 'rgb(95, 95, 95)',
    color: 'rgb(231, 231, 231)',
    borderBottom: '1px solid rgb(85, 85, 85)',
  }),
  tableCell: css({
    textAlign: 'center',
    paddingLeft: 20,
    paddingRight: 20,

    borderLeft: '1px solid rgb(110, 110, 110)',
    borderRight: '1px solid rgb(110, 110, 110)',
    borderBottom: '1px solid rgb(90, 90, 90)',
  }),
  tableCellHeader: css({
    padding: 10,
    borderColor: 'rgb(85, 85, 85)',
  }),
  tableCellSmall: css({
    paddingLeft: 5,
    paddingRight: 5,
  }),
  tableSubCell: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  }),
  tableCellHost: css({
    textAlign: 'left',
    paddingLeft: 5,
  }),
  hostName: css({
    textAlign: 'center',
  }),
  profile: css({
    height: 28,
    width: 28,
    margin: '5px 10px 5px 0px',
    verticalAlign: 'middle',
  }),
};

function MLobbyListView(props: {
  lobbies: Lobby[];
  session: Session;
  onVisitLobby: (lobby: Lobby) => void;
  onJoinLobby: (lobby: Lobby) => void;
  onSpectateLobby: (lobby: Lobby) => void;
  onCreateLobby: () => void;
}) {
  const {
    session,
    lobbies,
    onVisitLobby,
    onJoinLobby,
    onSpectateLobby,
    onCreateLobby,
  } = props;

  const lobbyViews = lobbies.map((lobby) => {
    return (
      <MLobbyListEntry
        key={lobby.id}
        lobby={lobby}
        sessionUserID={session.getUser()?.id}
        onVisit={onVisitLobby}
        onSpectate={onSpectateLobby}
        onJoin={onJoinLobby}
      />
    );
  });

  return (
    <div css={MLobbyListStyles.container}>
      <LobbyButton css={MLobbyListStyles.createButton} onClick={onCreateLobby}>
        Create Lobby
      </LobbyButton>
      {lobbyViews}
    </div>
  );
}
function MLobbyListEntry(props: {
  lobby: Lobby;
  sessionUserID?: string;
  onVisit: (lobby: Lobby) => void;
  onSpectate: (lobby: Lobby) => void;
  onJoin: (lobby: Lobby) => void;
}) {
  const { lobby, sessionUserID, onVisit, onSpectate, onJoin } = props;

  const sessionUserIsPlayer = lobby.players.edges.some(
    (edge) => edge.node.id === sessionUserID,
  );

  const button = match(lobby)
    .with({ game: { endTimestamp: P.number } }, () => (
      <LobbyButton
        css={MLobbyListStyles.joinButton}
        onClick={() => onSpectate(lobby)}
        secondary={true}
      >
        Results
      </LobbyButton>
    ))
    .with({ game: { endTimestamp: P.nullish } }, () => {
      return (
        <LobbyButton
          css={MLobbyListStyles.joinButton}
          secondary={!sessionUserIsPlayer}
          onClick={() => onJoin(lobby)}
        >
          {sessionUserIsPlayer ? 'Resume' : 'Watch'}
        </LobbyButton>
      );
    })
    .with({ game: P.nullish }, () => (
      <LobbyButton
        css={MLobbyListStyles.joinButton}
        onClick={() => onJoin(lobby)}
      >
        Join
      </LobbyButton>
    ))
    .exhaustive();

  return (
    <div css={MLobbyListStyles.entry} onClick={() => onVisit(lobby)}>
      <div css={MLobbyListStyles.row}>
        <div css={MLobbyListStyles.entryNameColumn}>
          {lobby.name}
          <div css={MLobbyListStyles.entryDate}>
            {moment(lobby.creationDate).fromNow()}
          </div>
        </div>

        <div css={MLobbyListStyles.statusColumn}>{lobby.status}</div>

        {button}
      </div>
      <div css={MLobbyListStyles.playerRow}>
        <MLobbyPlayer player={lobby.host} />
        {lobby.players.edges.map((edge) => {
          if (edge.node.id === lobby.host.id) {
            return null;
          }
          return (
            <div key={edge.node.id} css={MLobbyListStyles.entryPlayer}>
              <MLobbyPlayer
                player={edge.node}
                isSessionUser={edge.node.id === sessionUserID}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
function MLobbyPlayer(props: { player: any; isSessionUser?: boolean }) {
  const { player, isSessionUser } = props;
  return (
    <div css={MLobbyListStyles.entryPlayer}>
      <img
        src={player.profilePictureURL}
        css={MLobbyListStyles.entryHostProfile}
      />
      {isSessionUser ? '⭐' : ''}
      {player.name}
    </div>
  );
}
const MLobbyListStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',

    padding: 10,
    gap: 8,
  }),

  createButton: css({
    [MEDIA_QUERY_MOBILE]: {
      margin: 0,
      padding: 10,
      fontSize: 16,
    },
  }),

  entry: css({
    display: 'flex',
    flexDirection: 'column',

    backgroundColor: 'rgb(120, 120, 120)',
    borderRadius: 4,

    padding: 8,

    gap: 8,
  }),
  entryNameColumn: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 2,
  }),
  entryDate: css({
    fontSize: 12,
    color: 'rgb(150, 150, 150)',
  }),
  statusColumn: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    // alignSelf: 'center',

    fontSize: 14,
    paddingTop: 4,
  }),
  row: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 4,
  }),

  playerRow: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8,
  }),

  entryPlayer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    fontSize: 14,
    minWidth: 100,
  }),
  entryHostProfile: css({
    height: 16,
  }),

  joinButton: css({
    [MEDIA_QUERY_MOBILE]: {
      margin: 0,
      paddingTop: 12,
      paddingBottom: 12,
      minWidth: 100,
    },
  }),
};

function LobbyListPage(props: {
  dispatch: any;
  lobbyList: Lobby[];
  session: Session;
}) {
  const { dispatch, lobbyList, session } = props;

  const isMobile = useMobile();
  const navigate = useNavigate();
  const [requestInProgress, setRequestInProgress] = React.useState(false);

  useEffect(() => {
    dispatch(fetchLobbyList());

    const subscription = dispatch(
      subscribeToChannelTopic(`lobby_list`, () => {
        dispatch(fetchLobbyList());
      }),
    );
    return () => {
      dispatch(unsubscribeToChannelTopic(subscription));
    };
  }, []);
  const onLobbyJoin = useCallback(
    async (lobby: Lobby) => {
      var mutator = new LobbyMutator(session, lobby.id);
      await mutator.joinGame();
      navigate('/lobby/' + lobby.id);
    },
    [navigate, session],
  );
  const onLobbyVisit = useCallback(
    (lobby: Lobby) => {
      navigate('/lobby/' + lobby.id);
    },
    [navigate],
  );
  const onLobbySpectate = useCallback(
    (lobby: Lobby) => {
      if (!lobby.game) {
        return;
      }
      navigate('/game/' + lobby.game.id);
    },
    [navigate],
  );
  const onCreateLobby = useCallback(async () => {
    if (requestInProgress) {
      return;
    }
    setRequestInProgress(true);

    const res = await fetch('/api/lobby/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ host_id: session!.getUser().id }),
    });
    if (!res.ok) {
      alert('error creating lobby ' + res.text);
      setRequestInProgress(false);
      return;
    }

    const lobby = await res.json();
    navigate('/lobby/' + lobby.id);
  }, [navigate, requestInProgress, setRequestInProgress]);

  if (!lobbyList) {
    return null;
  }

  if (isMobile) {
    return (
      <MLobbyListView
        lobbies={lobbyList}
        session={session}
        onVisitLobby={onLobbyVisit}
        onJoinLobby={onLobbyJoin}
        onSpectateLobby={onLobbySpectate}
        onCreateLobby={onCreateLobby}
      />
    );
  }

  return (
    <LobbyListView
      lobbies={lobbyList}
      session={session}
      onJoinLobby={onLobbyJoin}
      onSpectateLobby={onLobbySpectate}
      onCreateLobby={onCreateLobby}
    />
  );
}

function select(state: any) {
  return {
    lobbyList: state.lobbyList as Lobby[],
    session: state.session as Session,
  };
}

export default connect(select)(LobbyListPage);
