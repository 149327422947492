import { CardType } from '@mythos/game/CardTypes';

export const CardTypeToFrameColor: Record<CardType, string> = {
  [CardType.Basic]: 'rgb(114, 75, 40)',
  [CardType.Resource]: 'rgb(60, 121, 60)',
  [CardType.Conflict]: 'rgb(202, 88, 62)',
  [CardType.Prayer]: 'rgb(73, 127, 177)',
  [CardType.Leader]: 'rgb(199, 199, 199)',
  [CardType.None]: 'rgb(110, 110, 110)',
};

export const CardTypeToEffectBGStyle = {
  [CardType.Basic]: {
    backgroundColor: 'rgba(230, 213, 191, 0.8)',
  },
  [CardType.Resource]: {
    backgroundColor: 'rgba(202, 227, 190, 0.8)',
  },
  [CardType.Conflict]: {
    backgroundColor: 'rgba(239, 200, 191, 0.8)',
  },
  [CardType.Prayer]: {
    backgroundColor: 'rgba(192, 219, 232, 0.8)',
  },
  [CardType.Leader]: {
    backgroundColor: 'rgba(199, 199, 199, 0.8)',
  },
  [CardType.None]: {
    backgroundColor: 'rgba(180, 180, 180, 0.8)',
  },
};
