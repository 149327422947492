/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import React, { useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import LobbyButton from './LobbyButton';

import DiscordLogo from '../assets/discord-logo-blue.svg';
import Session from '../common/utils/Session';

function LoginPage(props: { dispatch: any; redirect?: string | null }) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [requestInProgress, setRequestInProgress] = React.useState(false);

  const onGuestLoginSubmit = useCallback(
    async (event: React.BaseSyntheticEvent) => {
      event.preventDefault();
      if (requestInProgress) {
        return;
      }
      setRequestInProgress(true);
      var username = inputRef.current?.value.trim();
      if (!username) {
        return;
      }
      const res = await fetch('/auth/guest', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: 'abc123',
          redirect: props.redirect || undefined,
        }),
      });
      if (!res.ok) {
        const text = await res.text();
        setRequestInProgress(false);
        throw new Error('error logging in: ' + text);
      }
      const body = await res.json();
      window.location.replace(body.redirect);
    },
    [requestInProgress, setRequestInProgress, inputRef, props.redirect],
  );

  const discordAuthUrl = props.redirect
    ? `/auth/discord?redirect=${encodeURIComponent(props.redirect)}`
    : '/auth/discord';

  return (
    <div css={LoginPageStyles.container}>
      <span css={LoginPageStyles.sectionHeader}>Log in to save your stats</span>
      <span css={LoginPageStyles.oauthSection}>
        <a
          css={LoginPageStyles.discordButton}
          role="button"
          href={discordAuthUrl}
        >
          Login with <img css={LoginPageStyles.discordLogo} src={DiscordLogo} />
        </a>
      </span>
      <span
        css={[
          LoginPageStyles.sectionHeader,
          LoginPageStyles.sectionHeaderSmall,
        ]}
      >
        Guest Login
      </span>
      <div css={LoginPageStyles.guestSection}>
        <form
          css={LoginPageStyles.loginUsernameForm}
          onSubmit={onGuestLoginSubmit}
        >
          <span css={LoginPageStyles.loginUsernameFormText}> Name: </span>
          <input
            css={LoginPageStyles.loginUsernameInput}
            type="text"
            ref={inputRef}
            maxLength={12}
          />
          <LobbyButton onClick={onGuestLoginSubmit}>
            Log in as guest
          </LobbyButton>
        </form>
      </div>
    </div>
  );
}

const LoginPageStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 820,
    margin: 'auto',
    backgroundColor: 'rgb(120, 120, 120)',
  }),
  sectionHeader: css({
    padding: 14,
    textAlign: 'center',
    color: 'rgb(231, 231, 231)',
    backgroundColor: 'rgb(95, 95, 95)',
    borderBottom: '1px solid rgb(85, 85, 85)',
  }),
  sectionHeaderSmall: css({
    padding: 10,
  }),
  oauthSection: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 20,
  }),
  discordButton: css({
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    backgroundColor: 'rgb(211, 211, 211)',
    borderRadius: 5,
    textDecoration: 'none',
    color: '#5865F2',
  }),
  discordLogo: css({
    height: 18,
    width: 'auto',
    verticalAlign: 'middle',
    marginBottom: 0,
    marginLeft: 1,
  }),
  guestSection: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
  }),
  loginUsernameForm: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  loginUsernameInput: css({
    maxWidth: 200,
  }),
  loginUsernameFormText: css({
    display: 'flex',
    alignItems: 'center',
    paddingRight: 5,
  }),
};

function select(state: { session: Session | undefined }) {
  let session = state.session;
  return { session };
}

export default connect(select)(LoginPage);
