/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import React, { forwardRef, useCallback, useState } from 'react';

import { Dropdown } from '@restart/ui';
import { UserDropdownMenuProps } from '@restart/ui/DropdownMenu';
import { useMobile } from 'MobileContext';
import { NavLink } from 'react-router-dom';
import DiscordMark from '../assets/discord-mark-white.svg';
import Logo from '../assets/logo.svg';
import Session, { SessionUser } from '../common/utils/Session';
import ChatOverlay from './ChatOverlay';
import { Modal } from './Modal';
import { UserPreferenceMenu } from './UserPreferences';
import { MEDIA_QUERY_MOBILE } from './mobile_ui/MStyles';

const PagesDefinitions = [
  { url: '/', label: 'Lobby' },
  { url: '/rules', label: 'Game Rules' },
  { url: '/all-cards', label: 'View Cards' },
  // { url: '/all-cards#tributes', label: 'View Tributes' },
  // { url: '/game-history-list', label: 'Game History' },
];
const MobilePagesDefinitions = [
  { url: '/', label: 'Lobby' },
  { url: '/rules', label: 'Rules' },
  { url: '/all-cards', label: '  Cards' },
];

export default function PageHeaderView(props: { session?: Session }) {
  const { session } = props;

  const isMobile = useMobile();
  const definitions = isMobile ? MobilePagesDefinitions : PagesDefinitions;

  let links = definitions.map((definition, i) => {
    return (
      <div key={i}>
        <NavLink css={PageHeaderViewStyles.link} to={definition.url}>
          {definition.label}
        </NavLink>
      </div>
    );
  });
  const user = session ? session.getUser() : null;
  const user_section: React.ReactNode = user && (
    <div>
      <Dropdown placement="bottom-end">
        <Dropdown.Toggle>
          {(props) => (
            <button css={PageHeaderViewStyles.userSection} {...props}>
              <img
                src={user.profilePictureURL}
                css={PageHeaderViewStyles.profileSymbol}
              />
              <span css={PageHeaderViewStyles.userName}>{user.name}</span>
            </button>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {(menuProps, meta) => (
            <UserMenu user={user} {...menuProps} show={meta.show} />
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
  return (
    <div css={PageHeaderViewStyles.headerContainer}>
      <div css={PageHeaderViewStyles.headerLeft}>
        <img css={PageHeaderViewStyles.mythosSymbol} src={Logo} />
        {links}
      </div>
      <a
        css={PageHeaderViewStyles.discordLogoContainer}
        href="https://discord.gg/PpNCuXZruV"
        target="_blank"
      >
        <img css={PageHeaderViewStyles.discordLogo} src={DiscordMark}></img>
      </a>
      {user_section}
      <ChatOverlay />
    </div>
  );
}

const BG_COLOR = 'rgb(80, 77, 83)';
const PageHeaderViewStyles = {
  headerContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: BG_COLOR,
    position: 'relative',

    '@media print': {
      display: 'none',
    },
  }),
  headerLeft: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }),
  mythosSymbol: css({
    height: 30,
    width: 30,
    margin: 2,
    marginLeft: 4,
    border: '1px solid rgb(172, 130, 45)',
    borderRadius: '50%',

    [MEDIA_QUERY_MOBILE]: {
      display: 'none',
    },
  }),
  link: css({
    display: 'inline-flex',
    padding: 10,
    textDecoration: 'none',
    color: 'rgb(220, 200, 130)',
    textAlign: 'center',
    '&:hover': {
      color: 'rgb(240, 220, 150)',
    },
    '&.active': {
      color: 'rgb(255, 255, 255)',
    },
    '&.active:hover': {
      color: 'rgb(255, 255, 255)',
    },
  }),
  discordLogoContainer: css({
    backgroundColor: '#5865F2',
    borderRadius: 5,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
  }),
  discordLogo: css({
    display: 'inline',
    height: 16,
    width: 'auto',
    objectFit: 'contain',
    padding: 5,
  }),
  userSection: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'rgb(220, 220, 220)',
    padding: '0px 10px 0px 10px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(100, 100, 100)',
    },
  }),
  profileSymbol: css({
    height: 35,
    width: 35,
    marginRight: 10,
  }),
  userName: css({
    textAlign: 'center',
  }),
};

const UserMenu = forwardRef<
  HTMLDivElement,
  {
    user: SessionUser;
    show: boolean;
  } & UserDropdownMenuProps
>((props, ref) => {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const onLogoutSubmit = useCallback(
    (event: React.BaseSyntheticEvent) => {
      event.preventDefault();
      if (requestInProgress) {
        return;
      }
      setRequestInProgress(true);
      fetch('/auth/logout').then((response) => {
        setRequestInProgress(false);
        location.reload();
      });
    },
    [requestInProgress, setRequestInProgress],
  );
  const onSettingsClick = useCallback((event: React.BaseSyntheticEvent) => {
    event.preventDefault();
    setShowModal(true);
  }, []);

  return (
    <div
      css={[
        UserMenuStyles.userMenu,
        props.show ? UserMenuStyles.show : undefined,
      ]}
      style={props.style}
      aria-labelledby={props['aria-labelledby']}
      ref={ref}
    >
      <a
        css={[PageHeaderViewStyles.link, UserMenuStyles.menuButton]}
        onClick={onSettingsClick}
      >
        Settings
      </a>
      <a
        css={[PageHeaderViewStyles.link, UserMenuStyles.menuButton]}
        onClick={onLogoutSubmit}
      >
        Log out
      </a>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <UserPreferenceMenu user={props.user} />
      </Modal>
    </div>
  );
});

const UserMenuStyles = {
  userMenu: css({
    position: 'absolute',
    visibility: 'hidden',
    opacity: 0,

    backgroundColor: BG_COLOR,
    padding: 10,

    display: 'flex',
    flexDirection: 'column',
  }),
  show: css({
    visibility: 'visible',
    opacity: 1,
  }),
  menuButton: css({
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
  }),
};
